import { FC } from "react";

import { $wsUrl } from "@/services/api";
import {
  BonusUserPlatform,
  TerminalAccountSymbols,
  TerminalPriceItemsContainer,
  TerminalSymbols,
  TradingAccount,
} from "@/services/openapi";

import { TerminalAccountSummaryContextProvider } from "../account-summary/context";
import { TerminalChartContextProvider } from "../chart/context";
import { TerminalLayoutContextProvider } from "../layout/context";
import { TerminalAccountContextProvider } from "./account.context";
import { TerminalCurrentSymbolContextProvider } from "./current-symbol-context";
import { TerminalExtendedOrdersContextProvider } from "./extended-orders.context";
import { TerminalOrdersContextProvider } from "./orders.context";
import { TerminalSocketContextProvider } from "./socket.context";
import { TerminalSymbolsContextProvider } from "./symbols.context";

interface Props {
  symbols: TerminalSymbols;
  lastPrices: TerminalPriceItemsContainer;
  favorites: TerminalAccountSymbols;
  account: TradingAccount;
  token: string;
  bonuses: BonusUserPlatform[];
  children: React.ReactNode;
  symbol: string;
  isMobile: boolean;
  isMobileAppMode: boolean;
  isMobileSymbolPage: boolean;
  isPositionsPage: boolean;
  changeSymbol: (symbol: string) => void;
}

export const TerminalProvider: FC<Props> = ({
  children,
  account,
  token,
  bonuses,
  favorites,
  lastPrices,
  symbols,
  changeSymbol,
  symbol,
  isMobileAppMode,
  isMobile,
  isMobileSymbolPage,
  isPositionsPage,
}) => {
  const socketUrl = `${$wsUrl()}/v1.0/ws/private/${token}`;

  return (
    <TerminalLayoutContextProvider
      isMobile={isMobile}
      accountType={account.type!}
      isMobileAppMode={isMobileAppMode}
      isMobileSymbolPage={isMobileSymbolPage}
      isPositionsPage={isPositionsPage}
    >
      <TerminalSocketContextProvider socketUrl={socketUrl} accountId={account.id!}>
        <TerminalAccountContextProvider account={account} bonuses={bonuses}>
          <TerminalSymbolsContextProvider
            accountId={account.id!}
            lastPrices={lastPrices}
            symbols={symbols}
            favorites={favorites}
          >
            <TerminalCurrentSymbolContextProvider symbol={symbol} changeSymbol={changeSymbol}>
              <TerminalOrdersContextProvider account={account}>
                <TerminalExtendedOrdersContextProvider
                  currencyDecimalScale={account.digits!}
                  accountCurrency={account.currency!}
                  leverage={account.leverage!}
                >
                  <TerminalAccountSummaryContextProvider account={account}>
                    <TerminalChartContextProvider accountId={account.id!}>{children}</TerminalChartContextProvider>
                  </TerminalAccountSummaryContextProvider>
                </TerminalExtendedOrdersContextProvider>
              </TerminalOrdersContextProvider>
            </TerminalCurrentSymbolContextProvider>
          </TerminalSymbolsContextProvider>
        </TerminalAccountContextProvider>
      </TerminalSocketContextProvider>
    </TerminalLayoutContextProvider>
  );
};
