import { styled } from "@/styles";

import { mixinsCommon } from "./common";

const iconBase = styled("div", {
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  top: "1px",
  bottom: "1px",
});

export const buttonIcon = styled("button", {
  display: "inline-flex",
  alignItems: "center",
  cursor: "pointer",
  background: "transparent",
  border: "none",
  height: "100%",
  color: "$textMain",
  padding: "0 15px",

  // "&:hover": {
  //   background: "transparent",
  //   outline: "none",
  //   color: "green",
  // },
  //
  // "&:active": {
  //   background: "transparent",
  //   outline: "none",
  //   color: "green",
  // },

  variants: {
    disabled: {
      true: mixinsCommon.disabled,
    },
  },
});

export const iconStart = styled(iconBase, {
  insetInlineStart: "0",
  paddingInlineStart: "15px",

  variants: {
    isIcon: {
      true: {
        paddingInlineEnd: "15px",
      },
      false: {
        paddingInlineEnd: "0",
      },
    },
    disabled: {
      true: mixinsCommon.disabled,
    },
  },
});

export const iconEnd = styled(iconBase, {
  margin: "auto",
  insetInlineEnd: "0",

  variants: {
    isIcon: {
      true: {
        paddingInlineEnd: "15px",
      },
      false: {
        paddingInlineEnd: "0",
      },
    },
    disabled: {
      true: mixinsCommon.disabled,
    },
  },
});
