import { FC } from "react";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { BonusBanner } from "@/entities/bonuses/banner";
import { getShowBonusBanner } from "@/entities/bonuses/helpers";
import { useTranslation } from "@/hooks/translator.hook";
import { useBonusesQuery } from "@/state/server/bonuses";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { CompleteVerificationBanner } from "../cabinet/components/banners/CompleteVerificationBanner";
import { CabinetPage } from "../cabinet/components/cabinet-page";
import { CabinetTitle } from "../cabinet/components/cabinet-title";
import { AccountsCardsContainer } from "./accounts/cards.container";
import { CongratulationsDialog } from "./components/dialogs/congratulations/congratulations-dialog";
// import { EmailConfirmDialog } from "./components/dialogs/email-confirm/email-confirm-dialog";
import { VerificationDialog } from "./components/dialogs/verification/verification-dialog";

export const DashboardPage: FC = () => {
  const { t } = useTranslation();
  const profile = useProfileData();
  const { data } = useBonusesQuery();

  return (
    <CabinetPage
      title={
        <CabinetTitle title={t("cabinet.navigation.accounts")}>
          <CabinetTitle.DepositButton onClick={() => track(amplitudeEvents.deposit.fromMyAccounts)} />
        </CabinetTitle>
      }
      banner={
        data ? (
          <>
            {data.items![0] && getShowBonusBanner(data.items![0]) ? (
              <BonusBanner bonus={data.items![0]} />
            ) : (
              !profile.options!.isKycCompleted && <CompleteVerificationBanner />
            )}
          </>
        ) : null
      }
    >
      {data && <AccountsCardsContainer bonus={data.items![0] ? data.items![0] : null} />}
      {/* dialogs */}
      <CongratulationsDialog />
      <VerificationDialog />
      {/* <EmailConfirmDialog /> */}
    </CabinetPage>
  );
};
