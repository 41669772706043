import Big from "big.js";
import { produce } from "immer";
import React, { createContext, FC, useContext, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

import { logError } from "@/app/libs/sentry";
import { TerminalDealType, TradingAccount } from "@/services/openapi";
import { accountsQueryKeys } from "@/state/server/accounts";

import { useExtendedOrdersContext } from "../contexts/extended-orders.context";
import { useSymbolsContext } from "../contexts/symbols.context";

const calculateSymbolMargin = (orders: MarginInfoOrder[]) => {
  let buyVolume = new Big(0);
  let sellVolume = new Big(0);
  let volumeTotal = new Big(0);

  let buyMargin = new Big(0);
  let sellMargin = new Big(0);
  let marginTotal = new Big(0);

  orders.forEach(({ type, volume, margin }) => {
    if (type === TerminalDealType.Buy) {
      buyVolume = buyVolume.plus(volume);
      buyMargin = buyMargin.plus(margin);
    } else {
      sellVolume = sellVolume.plus(volume);
      sellMargin = sellMargin.plus(margin);
    }
    marginTotal = marginTotal.plus(margin);
    volumeTotal = volumeTotal.plus(volume);
  });

  const largerLeg = buyVolume.gt(sellVolume) ? TerminalDealType.Buy : TerminalDealType.Sell;
  const hedgedVolume = largerLeg === TerminalDealType.Buy ? sellVolume : buyVolume;

  const nonHedgedVolume = buyVolume.minus(sellVolume).abs();

  const hedgedWeightedAverageMargin = marginTotal.div(volumeTotal);

  const nonHedgedWeightedAverageMargin =
    largerLeg === TerminalDealType.Buy ? buyMargin.div(buyVolume) : sellMargin.div(sellVolume);

  const hedgedMargin = hedgedWeightedAverageMargin.mul(hedgedVolume);
  const nonHedgedMargin = nonHedgedWeightedAverageMargin.div(nonHedgedVolume);

  return hedgedMargin.plus(nonHedgedMargin).toNumber();
};

type MarginInfoOrder = {
  volume: number;
  type: TerminalDealType;
  margin: number;
};

type SummaryDataType = {
  pnl: number;
  swaps: number;
  margin: number;
  marginLevel: number;
};

type ContextProps = {
  equity: number;
  marginFree: number;
  balance: number;
  margin: number;
  credit: number;
  marginLevel: number;
  leverage: number;
  pnl: number;
  swaps: number;
  currency: string;
  currencyDecimalScale: number;
};

const Context = createContext<ContextProps | undefined>(undefined);

const Provider: FC<{ children: React.ReactNode; account: TradingAccount }> = ({ children, account }) => {
  const queryClient = useQueryClient();

  const { equity, currency, marginFree, balance, leverage, credit, id, digits } = account;

  const { extendedOpenOrders } = useExtendedOrdersContext();
  const { symbols } = useSymbolsContext();

  const [summaryData, setSummaryData] = useState<SummaryDataType>({
    pnl: 0,
    margin: 0,
    marginLevel: 0,
    swaps: 0,
  });

  useEffect(() => {
    try {
      let profitAndLossBig = new Big(0);
      let swapBig = new Big(0);
      let marginBig = new Big(0);

      const list: {
        [key: string]: MarginInfoOrder[];
      } = {};

      extendedOpenOrders.forEach(order => {
        const { pnl, swap, type, volume, symbol, margin } = order;

        profitAndLossBig = profitAndLossBig.plus(pnl);

        swapBig = swapBig.plus(swap);

        list[symbol] = [...(list[symbol] || []), { type, volume, margin }];
      });

      for (const symbol in list) {
        marginBig = marginBig.plus(calculateSymbolMargin(list[symbol]));
      }

      const pnl = profitAndLossBig.plus(swapBig).toNumber();
      const equityBig = new Big(balance!).plus(credit!).plus(pnl);

      const marginFree = equityBig.minus(marginBig).toNumber();

      setSummaryData({
        pnl,
        margin: marginBig.toNumber(),
        marginLevel: marginBig.toNumber() ? new Big(equityBig).div(marginBig).mul(100).toNumber() : 0,
        swaps: swapBig.toNumber(),
      });
      queryClient.setQueryData<TradingAccount>(accountsQueryKeys.account(id!), oldData => {
        return produce(oldData, draft => {
          draft!.equity = equityBig.toNumber();
          draft!.marginFree = marginFree;
        })!;
      });
    } catch (error) {
      logError(error);
    }
  }, [extendedOpenOrders, symbols, balance, leverage, credit]);

  const value: ContextProps = useMemo(
    () => ({
      balance: balance!,
      margin: summaryData.margin,
      marginLevel: summaryData.marginLevel,
      pnl: summaryData.pnl,
      swaps: summaryData.swaps,
      currency: currency!,
      credit: credit!,
      equity: equity!,
      leverage: leverage!,
      marginFree: marginFree!,
      currencyDecimalScale: digits!,
    }),
    [balance, summaryData, currency, credit, equity, leverage, marginFree, digits],
  );
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useTerminalAccountSummary = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useTerminalAccountSummary must be used within a TerminalAccountSummaryContextProvider");
  }

  return context;
};

export { Provider as TerminalAccountSummaryContextProvider, useTerminalAccountSummary };
