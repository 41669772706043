import { FC, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Dialog, DialogTrigger } from "@/components/dialog";
import { NewDialogContent } from "@/components/new-dialog";
import { IconPlus } from "@/domains/icons";
import { useTranslation } from "@/hooks/translator.hook";
import { TradingAccountType } from "@/services/openapi";
import { Button } from "@/shared/ui";
import { useServersQuery } from "@/state/server/accounts";

import { CreateAccountContainer } from "./create-account.container";

type Props = {
  type: TradingAccountType;
  secondary?: boolean;
};

export const CreateAccountButton: FC<Props> = ({ type, secondary }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [open, setOpen] = useState(searchParams.get("create-new-acc") === "true");

  const { data: servers } = useServersQuery();

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  if (!servers) {
    return null;
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {secondary ? (
          <Button variant="tertiary" size="sm" startSection={<IconPlus />} data-test="create-account-btn-trigger">
            {t("accounts.create.button")}
          </Button>
        ) : (
          <Button size="sm" startSection={<IconPlus />}>
            {t("accounts.create.button")}
          </Button>
        )}
      </DialogTrigger>
      <NewDialogContent title={t("accounts.open-new")}>
        <CreateAccountContainer type={type} servers={servers} onClose={onClose} />
      </NewDialogContent>
    </Dialog>
  );
};
