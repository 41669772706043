import { Box } from "@/components/box";
import { styled } from "@/styles";

export const CabinetInputWrapper = styled(Box, {
  "@bp2": {
    maxWidth: "70%",
  },

  "@bp3": {
    maxWidth: "80%",
  },

  "@bp4": {
    maxWidth: "40%",
  },
});
