import dayjs from "dayjs";

import { TableCell, TableRow } from "@/components/table";
import { Text } from "@/components/text";
import { useTranslation } from "@/hooks/translator.hook";
import { Notification } from "@/services/openapi";

export const NotificationTableRow = ({ notification }: { notification: Notification }) => {
  const { t } = useTranslation();

  const { date, description } = notification;

  const dateFormatted = dayjs(date).calendar(undefined, {
    sameDay: `[${t("common.today")}], DD MMMM HH:mm`,
    lastDay: `[${t("common.yesterday")}], DD MMMM HH:mm`,
    lastWeek: "dddd, DD MMMM HH:mm",
    sameElse: "dddd, DD MMMM HH:mm",
  });

  return (
    <>
      <TableRow>
        <TableCell>
          <Text as="h3" weight="bold" size={{ "@initial": "3", "@bp2": "4" }} css={{ mb: "16px" }}>
            {dateFormatted}
          </Text>
          <Text
            as="p"
            family="roboto"
            size={{ "@initial": "1", "@bp2": "2" }}
            lineHeight="3"
            css={{ whiteSpace: "pre-wrap" }}
          >
            {description}
          </Text>
        </TableCell>
      </TableRow>
    </>
  );
};
