import { FC, useState } from "react";

import { Box } from "@/components/box";
import { Grid } from "@/components/grid";
import { Stack } from "@/components/stack";
import { Text } from "@/components/text";
import { CompleteProfileInfo } from "@/features/deposit/components/info/complete-profile-info";
import { PaymentMethodItem } from "@/features/deposit/components/payment-method/payment-method-item";
import { useTranslation } from "@/hooks/translator.hook";
import { PaymentMethod } from "@/services/openapi";
import { useDepositWidgetsQuery } from "@/state/server/payment";

type Props = {
  choosePaymentMethod: (method: PaymentMethod) => void;
  items: {
    available: PaymentMethod[] | undefined;
    unavailable: PaymentMethod[] | undefined;
  };
};

export const PaymentMethodGrid: FC<Props> = ({ choosePaymentMethod, items }) => {
  const { t } = useTranslation();
  const [showPaymentComponent, setShowPaymentComponent] = useState<boolean>(true);
  const { data: widgetData } = useDepositWidgetsQuery();

  const onMethodClick = (method: PaymentMethod) => {
    choosePaymentMethod(method);
    setShowPaymentComponent(false);
  };

  const isAnyPaymentMethodAvailable = items.available && items.available.length > 0;

  if (!showPaymentComponent) return null;

  return (
    <Box css={{ p: "0 25px", "@bp3": { p: "0" } }}>
      <Stack>
        {widgetData && <CompleteProfileInfo {...widgetData} />}
        {isAnyPaymentMethodAvailable && (
          <Text family="roboto" lineHeight="3" css={{ mb: "16px" }}>
            {t("deposit.payments.title")}
          </Text>
        )}
        <Grid
          css={{
            gridTemplateColumns: "1fr",
            "@bp2": {
              gridTemplateColumns: "1fr 1fr",
            },
            gap: "16px",
          }}
        >
          {items.available?.map(item => {
            return (
              <PaymentMethodItem item={item} key={`${item.id} ${item.reccuringId}`} onMethodClick={onMethodClick} />
            );
          })}
        </Grid>
      </Stack>
      {items.unavailable && items.unavailable.length > 0 && (
        <Stack css={{ mt: "32px" }}>
          {isAnyPaymentMethodAvailable && (
            <Text family="roboto" lineHeight="3" css={{ mb: "16px" }}>
              {t("deposit.payments.title-2")}
            </Text>
          )}

          <Grid
            css={{
              gridTemplateColumns: "1fr",
              "@bp2": {
                gridTemplateColumns: "1fr 1fr",
              },
              gap: "16px",
            }}
          >
            {items.unavailable?.map(item => {
              return (
                <PaymentMethodItem item={item} key={`${item.id} ${item.reccuringId}`} onMethodClick={onMethodClick} />
              );
            })}
          </Grid>
        </Stack>
      )}
    </Box>
  );
};
