import { FC } from "react";

import { Dialog, DialogContent, DialogTrigger } from "@/components/dialog";
import { ChangeEmailIcon } from "@/components/icons";
import { UnstyledButton } from "@/components/unstyled-button.tsx";

import { ChangeEmailDialogContent } from "./change-email-dialog-content";
import { ChangeVerifiedEmailDialogContent } from "./change-verified-email-dialog-content";

type Props = {
  isEmailConfirmed: boolean;
  email: string;
};

export const ChangeEmailButton: FC<Props> = ({ isEmailConfirmed, email }) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <UnstyledButton
          css={{ color: "$textSecondary", "&:hover": { color: "$textMain", elementTransition: "color" } }}
        >
          <ChangeEmailIcon />
        </UnstyledButton>
      </DialogTrigger>
      <DialogContent>
        {isEmailConfirmed ? <ChangeVerifiedEmailDialogContent email={email} /> : <ChangeEmailDialogContent />}
      </DialogContent>
    </Dialog>
  );
};
