import { Flex } from "@/components/flex";
import { styled } from "@/styles";

import { iconEnd, iconStart } from "../mixins/input";

const Text = styled("span", { fontFamily: "$roboto", fontSize: "14px", fontWeight: "$normal", lineHeight: "20px" });

export const Button = styled("button", {
  position: "relative",
  padding: "16px",
  paddingInlineStart: "52px",
  textAlign: "start",
  maxHeight: "63px",

  borderRadius: "16px",
  border: "1px solid $bgBorder",
  background: "$bgPrimary",

  color: "$textMain",
  fontFamily: "$roboto",
  fontSize: "16px",
  fontWeight: "$normal",
  lineHeight: "$3" /* 24px */,

  "&:hover": {
    border: "1px solid $textSecondary",
  },
});
export const IconStart = styled(iconStart, {});
export const IconEnd = styled(iconEnd, {});

export const CountryValue = styled(Text, {
  width: "40px",
  color: "$textSecondary",
});

export const CountryName = styled(Text, {
  color: "$textMain",

  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",

  variants: {
    selected: {
      true: {
        paddingInlineEnd: "36px",
      },
    },
  },
});

export const ItemContent = styled(Flex, {
  flexShrink: 0,
  alignItems: "center",
  gap: "12px",
  marginInlineEnd: "4px",
});

export const Icon = styled("div", {
  position: "absolute",
  insetInlineEnd: "16px",
});
