import dayjs from "dayjs";
import { useMemo, useState } from "react";

import { IconTimeRound } from "@/domains/icons";
import { useCurrentSymbolContext } from "@/features/terminal/contexts/current-symbol-context";
import { useInterval } from "@/hooks/interval.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { Alert } from "@/shared/ui";

const TradingUnavailableAlert = () => {
  const { Trans } = useTranslation();
  const { isTradingAvailable, currentSymbol } = useCurrentSymbolContext();

  const [currentDate, setCurrentDate] = useState(() => dayjs());

  const { date, isDate, isTime, isToday, time, timeLeftMinutes, timezone } = useMemo(() => {
    const openDate = dayjs(isTradingAvailable.remainingTime);
    const timeLeftHours = openDate.diff(currentDate, "hour");
    const timeLeftMinutes = dayjs(openDate.diff(currentDate)).format("mm");
    const date = openDate.format("MMM D");
    const time = openDate.format("HH:mm");
    const timezone = openDate.format("Z");

    const isDate = timeLeftHours > 24;
    const isToday = timeLeftHours >= 1 && timeLeftHours <= 24;
    const isTime = timeLeftHours === 0;

    return { date, time, timezone, timeLeftMinutes, isDate, isToday, isTime };
  }, [currentDate, isTradingAvailable.remainingTime]);

  useInterval(() => {
    setCurrentDate(dayjs());
  }, 1000);

  return (
    <Alert
      icon={<IconTimeRound />}
      text={
        <>
          {isDate && (
            <Trans
              i18nKey="terminal.trading-unavailable.reopen-message.date"
              values={{ currentSymbol, date, time, timezone }}
              components={{
                strong: <Alert.Strong />,
              }}
            />
          )}
          {isToday && (
            <Trans
              i18nKey="terminal.trading-unavailable.reopen-message.today-at"
              values={{ currentSymbol, time, timezone }}
              components={{
                strong: <Alert.Strong />,
              }}
            />
          )}
          {isTime && (
            <Trans
              i18nKey="terminal.trading-unavailable.reopen-message.time"
              values={{ currentSymbol, time: timeLeftMinutes }}
              components={{
                strong: <Alert.Strong />,
              }}
            />
          )}
        </>
      }
    />
  );
};

export { TradingUnavailableAlert };
