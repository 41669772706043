import { type FC } from "react";
import { Trans } from "react-i18next";

import { PnlFormat } from "@/app/components";
import { getNumberColorClassname } from "@/app/ui/colors";
import { Dialog, DialogTrigger } from "@/components/dialog";
import { TerminalPopover } from "@/features/terminal/components/popover";
import { TerminalBackDialogContent } from "@/features/terminal/components/terminal-back-dialog-content";
import { terminalLocators } from "@/features/terminal/locators";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalCloseOrderType } from "@/services/openapi";
import { Button } from "@/shared/ui";
import { useCloseTerminalOrdersMutation } from "@/state/server/terminal";

import { CloseAllOpenOrdersDataList } from "../../open-orders/close-all/data-list";

type Props = {
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
  pnl: number;
  swaps: number;
  ordersCount: number;
};

const CloseAllOpenOrdersButton: FC<Props> = ({
  accountId,
  currency,
  currencyDecimalScale,
  pnl,
  swaps,
  ordersCount,
}) => {
  const { t } = useTranslation();

  const [opened, { onOpenChange, open }] = useDisclosure();

  const { mutate, isLoading } = useCloseTerminalOrdersMutation();

  const closeOrders = () => {
    mutate(
      { tradingAccountId: accountId, terminalCloseOrderRequest: { type: TerminalCloseOrderType.Market } },
      {
        onSuccess: () => {
          open();
        },
      },
    );
  };

  return (
    <Dialog open={opened} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button
          className="w-full"
          variant="tertiary"
          size="sm"
          data-test={terminalLocators.tradingTables.open.closeAll}
        >
          <Trans
            i18nKey="terminal.orders.close-all-with-pnl"
            values={{ currency }}
            components={{
              value: <PnlFormat value={pnl} decimalScale={currencyDecimalScale} />,
              pnlText: (
                <span className={getNumberColorClassname(pnl) ? getNumberColorClassname(pnl) : "text-accent-text"} />
              ),
            }}
          />
        </Button>
      </DialogTrigger>
      <TerminalBackDialogContent>
        <h2 className="mb-6 font-gilroy text-[24px] font-semibold leading-[1.2]">
          {t("terminal.orders.close-all-open-message")}
        </h2>
        <CloseAllOpenOrdersDataList
          currency={currency}
          currencyDecimalScale={currencyDecimalScale}
          ordersCount={ordersCount}
          pnl={pnl}
          swaps={swaps}
        />
        <TerminalPopover.Buttons isMobile>
          <Button pending={isLoading} onClick={closeOrders} className="w-full">
            {t("terminal.orders.close-all")}
          </Button>
        </TerminalPopover.Buttons>
      </TerminalBackDialogContent>
    </Dialog>
  );
};

export { CloseAllOpenOrdersButton };
