import { FC } from "react";

import { SubmitButton } from "@/app/form";
import { DialogButtons, DialogClose } from "@/components/dialog";
import { TextField } from "@/components/form/fields";
import { HookForm } from "@/components/form/hook-form";
import { Stack } from "@/components/stack";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { Button } from "@/shared/ui";

enum AccountNameFields {
  VALUE = "value",
}

export interface IAccountNameFormValues {
  [AccountNameFields.VALUE]: string;
}

type Props = {
  onSubmit: (values: IAccountNameFormValues) => void;
  accountName: string;
};

export const AccountNameForm: FC<Props> = ({ onSubmit, accountName }) => {
  const { t } = useTranslation();
  const form = useHookForm<IAccountNameFormValues>({
    defaultValues: {
      [AccountNameFields.VALUE]: accountName || "",
    },
  });

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <Stack>
        <TextField
          name={AccountNameFields.VALUE}
          registerOptions={{ required: t("form-errors.required-error")! }}
          data-test="dialog-name-field"
        />
        <DialogButtons>
          <SubmitButton data-test="dialog-name-submit">{t("button.set")}</SubmitButton>
          <DialogClose asChild>
            <Button variant="tertiary" data-test="dialog-name-cancel">
              {t("button.cancel")}
            </Button>
          </DialogClose>
        </DialogButtons>
      </Stack>
    </HookForm>
  );
};
