import React, { forwardRef, useEffect, useId } from "react";
import { FieldError } from "react-hook-form";

import { Box } from "@/components/box";
import { Flex } from "@/components/flex";
import { CheckIcon } from "@/components/icons";
import { Text } from "@/components/text";
import { CSS, styled } from "@/styles";

import { Error } from "../styles";

const Wrapper = styled(Flex, {
  alignItems: "flex-start",
});

const IconWrapper = styled("div", {
  position: "absolute",
  display: "none",
});

const StyledCheckbox = styled("input", {
  all: "unset",
  backgroundColor: "$white",
  flexShrink: "0",
  width: "24px",
  height: "24px",
  borderRadius: "$common",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid",
  borderColor: "$gray",
  transition: "border-color 0.1s, background 0.1s",
  willChange: "border-color, background",

  "&:checked": {
    borderColor: "$primary !important",
    background: "$primary !important",

    [`+ ${IconWrapper}`]: {
      display: "block",
    },
  },

  variants: {
    isError: {
      true: {
        borderColor: "$negativeDefault",
      },
    },
  },
});

const InputWrapper = styled("label", {
  position: "relative",
  display: "grid",
  placeItems: "center",

  [`&:hover ${StyledCheckbox}`]: {
    borderColor: "$primary",
    background: "$lightGray",
  },
});

type CheckboxInternalProps = {
  error?: FieldError;
  showError?: boolean;
};

export type CheckboxExternalProps = React.ComponentProps<typeof StyledCheckbox> & {
  label: React.ReactNode;
  css?: CSS;
};

type Props = CheckboxExternalProps & CheckboxInternalProps;

export const Checkbox = forwardRef<React.ElementRef<typeof StyledCheckbox>, Props>(
  ({ css, label, showError, error, ...rootProps }, ref) => {
    const id = useId();

    return (
      <Wrapper css={css}>
        <InputWrapper htmlFor={id}>
          <StyledCheckbox id={id} {...rootProps} ref={ref} type="checkbox" isError={showError} />
          <IconWrapper>
            <CheckIcon />
          </IconWrapper>
        </InputWrapper>
        <Box css={{ paddingInlineStart: "15px" }}>
          <Text as="label" family="roboto" htmlFor={id} lineHeight="3" css={{ userSelect: "none" }}>
            {label}
          </Text>
          {showError && <Error errorMessage={error!.message} />}
        </Box>
      </Wrapper>
    );
  },
);
Checkbox.displayName = "Checkbox";
