import { FC } from "react";

import image from "@/assets/images/DialogImage2.svg";
import { Button } from "@/components/button";
import { Flex } from "@/components/flex";
import { LimitsIcon, LiveTradingIcon, StarIcon } from "@/components/icons";
import { InfoDialog, InfoDialogContent } from "@/components/info-dialog";
import { Stack } from "@/components/stack";
import { Text } from "@/components/text";
import { useTranslation } from "@/hooks/translator.hook";
import { styled } from "@/styles";

const Li = styled("li", {
  display: "flex",
  gap: "16px",

  "@bp2": {
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

type Props = {
  onDepositFunds: () => void;
  onUploadDocuments: () => void;
};

export const DeclarationDialogContent: FC<Props> = ({ onDepositFunds, onUploadDocuments }) => {
  const { t } = useTranslation();
  return (
    <InfoDialog
      imageSrc={image}
      title={t("onboarding.declaration.dialog-title")}
      description={t("onboarding.declaration.dialog-description")}
    >
      <InfoDialogContent>
        <Text weight="bold" size="4" align="center" css={{ mb: "32px" }}>
          {t("onboarding.declaration.dialog-content-text")}
        </Text>
        <Stack
          as="ul"
          css={{
            flexDirection: "column",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            "@bp2": {
              px: "32px",
              flexDirection: "row",
              justifyContent: "space-between",
            },
          }}
        >
          <Li>
            <LiveTradingIcon />
            <Text family="roboto">{t("onboarding.declaration.live-trading")}</Text>
          </Li>
          <Li>
            <LimitsIcon />
            <Text family="roboto">{t("onboarding.declaration.increased-limits")}</Text>
          </Li>
          <Li>
            <StarIcon />
            <Text family="roboto">{t("onboarding.declaration.full-access")}</Text>
          </Li>
        </Stack>
      </InfoDialogContent>
      <Flex css={{ justifyContent: "center" }}>
        <Button onClick={onUploadDocuments}>{t("button.upload-documents")}</Button>
      </Flex>
      <Flex css={{ justifyContent: "center" }}>
        <Button variant="text" onClick={onDepositFunds} size="small">
          {t("button.deposit-funds")}
        </Button>
      </Flex>
    </InfoDialog>
  );
};
