import { WidgetState } from "@livechat/widget-react";
import { atom } from "nanostores";

const $liveChatState = atom<WidgetState["visibility"]>("minimized");

const toggleLiveChat = () => {
  if ($liveChatState.get() === "maximized") $liveChatState.set("hidden");
  else $liveChatState.set("maximized");
};

const setLiveChatVisibility = (variant: WidgetState["visibility"]) => $liveChatState.set(variant);

export { $liveChatState, toggleLiveChat, setLiveChatVisibility };
