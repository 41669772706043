import { ComponentPropsWithoutRef, type ElementRef, type FC, forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { Badge } from "@/components/badge";
import { TradingAccountType } from "@/services/openapi";

type Props = ComponentPropsWithoutRef<typeof Badge> & {
  type: TradingAccountType;
};

const AccountBadge: FC<Props> = forwardRef<ElementRef<typeof Badge>, Props>(({ type, ...props }, ref) => {
  const { t } = useTranslation();

  const isReal = type === TradingAccountType.Real;

  return (
    <Badge color={isReal ? "blue" : "orange"} {...props} ref={ref}>
      {isReal ? t("common.real") : t("common.demo")}
    </Badge>
  );
});

export { AccountBadge };
