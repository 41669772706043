import { FC } from "react";

import { Button } from "@/components/button";
import { DialogDescription } from "@/components/dialog";
import { DialogDescriptionText } from "@/components/dialog/styles";
import { Flex } from "@/components/flex";
import { TextInput } from "@/components/form";
import { Stack } from "@/components/stack";
import { AccountDialogContent } from "@/features/dashboard/components/dialogs/styles";
import { useTranslation } from "@/hooks/translator.hook";
import { useConfirmUserProfileMutation } from "@/state/server/profile/profile.mutations";

type Props = {
  email: string;
};

export const ChangeVerifiedEmailDialogContent: FC<Props> = ({ email }) => {
  const { t } = useTranslation();
  const { isLoading, mutate } = useConfirmUserProfileMutation();

  return (
    <AccountDialogContent title={t("profile-settings.change-email.verified-title")}>
      <Stack>
        <DialogDescription asChild>
          <DialogDescriptionText align="left">
            {t("profile-settings.change-email.verified-text-1")}
          </DialogDescriptionText>
        </DialogDescription>
        <DialogDescription asChild>
          <DialogDescriptionText align="left">
            {t("profile-settings.change-email.verified-text-2")}
          </DialogDescriptionText>
        </DialogDescription>
        <TextInput value={email} disabled />
      </Stack>
      <Flex css={{ justifyContent: "center", mt: "20px" }}>
        <Button disabled={isLoading} onClick={() => mutate(undefined)}>
          {t("button.send-email")}
        </Button>
      </Flex>
    </AccountDialogContent>
  );
};
