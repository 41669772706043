import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, DialogContent } from "@/components/dialog";
import { InfoDialog } from "@/components/info-dialog";
import { onExitDialog } from "@/features/onboarding/components/exit-dialog/store";
import { useTerminalAutoRoute } from "@/hooks/terminal-auto-route.hook";
import { Button } from "@/shared/ui";

import img from "./assets/pending.png";

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

export const ExitOnboardingDialog: FC<Props> = ({ open, onOpenChange }) => {
  const { t } = useTranslation();
  const { open: openTerminal } = useTerminalAutoRoute();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <InfoDialog
          imageSrc={img}
          title={t("onboarding.dialog.title")}
          description={t("onboarding.dialog.description")}
        >
          <div className="grid grow content-end gap-3">
            <Button variant="tertiary" className="w-full" onClick={openTerminal}>
              {t("onboarding.dialog.explore-button")}
            </Button>
            <Button variant="secondary" className="w-full" onClick={() => onExitDialog(false)}>
              {t("onboarding.dialog.continue-button")}
            </Button>
          </div>
        </InfoDialog>
      </DialogContent>
    </Dialog>
  );
};
