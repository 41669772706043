import { FC, memo, ReactNode } from "react";

import { useAddChartSymbol } from "@/features/terminal/hooks/add-chart-symbol.hook";

import { WatchlistStyledRow } from "./styles";

type Props = {
  isChartFavorite: boolean;
  symbol: string;
  accountId: string;
  children: ReactNode;
  chartFavoritesCount: number;
  setSymbol: (symbol: string) => void;
  onClose?: () => void;
};

const _AddChartSymbolRow: FC<Props> = ({
  isChartFavorite,
  symbol,
  accountId,
  children,
  onClose,
  chartFavoritesCount,
  setSymbol,
}) => {
  const { addChartSymbol } = useAddChartSymbol({
    setSymbol,
    accountId,
    callback: () => {
      onClose && onClose();
    },
  });

  return (
    <WatchlistStyledRow hoverable onClick={() => addChartSymbol(symbol)}>
      {children}
    </WatchlistStyledRow>
  );
};

export const AddChartSymbolRow = memo(_AddChartSymbolRow);
