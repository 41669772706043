import { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { setWebviewSentry } from "@/app/libs/sentry";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { TradingAccountType } from "@/services/openapi";

import { useTerminalNavigate } from "../helpers";

enum TerminalTableState {
  NONE = "closePositions",
  OPEN = "openPositions",
  PENDING = "pendingPositions",
  CLOSED_POSITIONS = "closedPositions",
}

type ContextProps = {
  demoDialogOpen: boolean;
  closeDemoDialog: () => void;
  onDeposit: () => void;
  table: TerminalTableState;
  changeTable: (table: TerminalTableState) => void;
  watchlistOpen: boolean;
  isMobile: boolean;
  isMobileAppMode: boolean;
  openWatchlist: () => void;
  closeWatchlist: () => void;
  watchlistOnChange: (value: boolean) => void;
  closeTable: () => void;
  toggleTable: () => void;
  isChartExpanded: boolean;
  setIsChartExpanded: SetState<boolean>;
  isMobileSymbolPage: boolean;
  isPositionsPage: boolean;
  // insights
  insightsDialogOpen: boolean;
  openInsightsDialog: () => void;
  closeInsightsDialog: () => void;
};

const Context = createContext<ContextProps | undefined>(undefined);

// for webview
const openPositionsEventName = "open-positions";
const pendingPositionsEventName = "pending-positions";
const closedPositionsEventName = "closed-positions";
const closePositionsEventName = "close-positions";

window.openPositionsEvent = new CustomEvent(openPositionsEventName);
window.pendingPositionsEvent = new CustomEvent(pendingPositionsEventName);
window.closedPositionsEvent = new CustomEvent(closedPositionsEventName);
window.closePositionsEvent = new CustomEvent(closePositionsEventName);

const Provider = memo(
  ({
    children,
    accountType,
    isMobileAppMode,
    isMobile,
    isMobileSymbolPage,
    isPositionsPage,
  }: {
    children: React.ReactNode;
    accountType: TradingAccountType;
    isMobileAppMode: boolean;
    isMobile: boolean;
    isMobileSymbolPage: boolean;
    isPositionsPage: boolean;
  }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const tab = searchParams.get("tab");

    const [demoDialogOpen, { close: closeDemoDialog, open: openDemoDialog }] = useDisclosure();
    const [insightsDialogOpen, { close: closeInsightsDialog, open: openInsightsDialog }] = useDisclosure();

    const tablePositions: ContextProps["table"] = useMemo(() => {
      if (tab === TerminalTableState.CLOSED_POSITIONS) {
        return TerminalTableState.CLOSED_POSITIONS;
      }
      if (tab === TerminalTableState.PENDING) {
        return TerminalTableState.PENDING;
      }
      return TerminalTableState.OPEN;
    }, [tab]);

    const [table, setTable] = useState<ContextProps["table"]>(TerminalTableState.NONE);
    const [watchlistOpen, { open, close: closeWatchlist, onOpenChange }] = useDisclosure(false);

    const openWatchlist: ContextProps["openWatchlist"] = useCallback(() => {
      open();
      if (window["WEBVIEW_CHANNEL"]) {
        window["WEBVIEW_CHANNEL"].postMessage("watchlist");
      }
    }, [open]);

    const watchlistOnChange: ContextProps["watchlistOnChange"] = useCallback(
      flag => {
        if (flag) {
          if (window["WEBVIEW_CHANNEL"]) {
            window["WEBVIEW_CHANNEL"].postMessage("watchlist");
          }
        }
        onOpenChange(flag);
      },
      [onOpenChange],
    );

    const { isDesktop } = useScreenSize();

    const [_isChartExpanded, setIsChartExpanded] = useState<ContextProps["isChartExpanded"]>(false);
    const isChartExpanded = _isChartExpanded || isDesktop;

    const navigate = useTerminalNavigate(isMobileAppMode);

    const changeTable: ContextProps["changeTable"] = useCallback(
      table => {
        setTable(table);

        if (isPositionsPage) {
          setSearchParams(prevParams => {
            prevParams.set("tab", table);
            return prevParams;
          });
        }

        if (window["WEBVIEW_CHANNEL"]) {
          if (table === TerminalTableState.OPEN) {
            window["WEBVIEW_CHANNEL"].postMessage(TerminalTableState.OPEN);
            return;
          }
          if (table === TerminalTableState.PENDING) {
            window["WEBVIEW_CHANNEL"].postMessage(TerminalTableState.PENDING);
            return;
          }
          if (table === TerminalTableState.CLOSED_POSITIONS) {
            window["WEBVIEW_CHANNEL"].postMessage(TerminalTableState.CLOSED_POSITIONS);
            return;
          }
          window["WEBVIEW_CHANNEL"].postMessage(TerminalTableState.NONE);
        }
      },
      [isPositionsPage, setSearchParams],
    );

    useEffect(() => {
      const handleOpen = () => {
        changeTable(TerminalTableState.OPEN);
      };
      const handlePending = () => {
        changeTable(TerminalTableState.PENDING);
      };
      const handleClosed = () => {
        changeTable(TerminalTableState.CLOSED_POSITIONS);
      };
      const handleClose = () => {
        changeTable(TerminalTableState.NONE);
      };

      // document.dispatchEvent(event);
      document.addEventListener(openPositionsEventName, handleOpen);
      document.addEventListener(pendingPositionsEventName, handlePending);
      document.addEventListener(closedPositionsEventName, handleClosed);
      document.addEventListener(closePositionsEventName, handleClose);

      return () => {
        document.removeEventListener(openPositionsEventName, handleOpen);
        document.removeEventListener(pendingPositionsEventName, handlePending);
        document.removeEventListener(closedPositionsEventName, handleClosed);
        document.removeEventListener(closePositionsEventName, handleClose);
      };
    }, []);

    const toggleTable = useCallback(() => {
      if (table === TerminalTableState.NONE) {
        changeTable(TerminalTableState.OPEN);
      } else {
        changeTable(TerminalTableState.NONE);
      }
    }, [table, changeTable]);

    const closeTable = useCallback(() => {
      changeTable(TerminalTableState.NONE);
    }, [changeTable]);

    useEffect(() => {
      setWebviewSentry(isMobileAppMode);
    }, [isMobileAppMode]);

    const onDeposit: ContextProps["onDeposit"] = useCallback(() => {
      const isDemoAccount = accountType === TradingAccountType.Demo;

      if (isDemoAccount) {
        openDemoDialog();
        return;
      }

      navigate(cabinetRoutes.deposit);
    }, [accountType, openDemoDialog, navigate]);

    const value: ContextProps = useMemo(
      () => ({
        closeInsightsDialog,
        insightsDialogOpen,
        openInsightsDialog,
        closeDemoDialog,
        demoDialogOpen,
        watchlistOpen,
        onDeposit,
        isMobileAppMode,
        table: isPositionsPage ? tablePositions : table,
        isMobile,
        toggleTable,
        watchlistOnChange,
        closeTable,
        changeTable,
        openWatchlist,
        closeWatchlist,
        setIsChartExpanded,
        isChartExpanded,
        isMobileSymbolPage,
        isPositionsPage,
      }),
      [
        closeInsightsDialog,
        insightsDialogOpen,
        openInsightsDialog,
        onDeposit,
        demoDialogOpen,
        closeDemoDialog,
        closeTable,
        isMobile,
        isMobileAppMode,
        table,
        toggleTable,
        watchlistOpen,
        openWatchlist,
        closeWatchlist,
        watchlistOnChange,
        changeTable,
        setIsChartExpanded,
        isChartExpanded,
        tablePositions,
        isPositionsPage,
        isMobileSymbolPage,
      ],
    );
    return <Context.Provider value={value}>{children}</Context.Provider>;
  },
);

Provider.displayName = "TerminalLayoutContextProvider";

const useLayoutContext = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useLayoutContext must be used within a TerminalLayoutContextProvider");
  }

  return context;
};

export { Provider as TerminalLayoutContextProvider, TerminalTableState, useLayoutContext };
