import { ComponentPropsWithoutRef, FC } from "react";
import { Drawer } from "vaul";

import { useScreenSize } from "@/hooks/screen-size.hook";
import { overlayStyles } from "@/shared/ui";

import { Text } from "../text";

type BottomSheetContentProps = ComponentPropsWithoutRef<typeof Drawer.Content> & {
  title?: string;
  description?: string;
  image?: {
    src: string;
    alt?: string;
  };
};

const BottomSheetContent: FC<BottomSheetContentProps> = (
  { children, title, description, image, ...props },
  forwardedRef,
) => {
  const { isLargeDesktop } = useScreenSize();

  return (
    <Drawer.Portal>
      <Drawer.Overlay className={overlayStyles({ hasAnimation: false })} />
      <Drawer.Content
        className="fixed bottom-0 end-0 start-0 z-50 flex flex-col overflow-hidden rounded-t-[24px] bg-bg py-2"
        onOpenAutoFocus={e => !isLargeDesktop && e.preventDefault()}
        onCloseAutoFocus={e => !isLargeDesktop && e.preventDefault()}
        {...props}
      >
        <div className="mx-auto mb-[25px] h-1 w-10 rounded-full bg-bg-deep" />

        {image && (
          <div className="pointer-events-none mx-auto mb-[25px]">
            <img alt={image.alt} src={image.src} />
          </div>
        )}

        <div className="px-[24px]">
          {title && (
            <BottomSheetTitle asChild>
              <Text size={5} weight="bold" align="center">
                {title}
              </Text>
            </BottomSheetTitle>
          )}
          {description && (
            <BottomSheetDescription asChild>
              <Text family="roboto" align="center" css={{ mt: "16px" }}>
                {description}
              </Text>
            </BottomSheetDescription>
          )}
        </div>
        <div className="mt-[30px]">{children}</div>
      </Drawer.Content>
    </Drawer.Portal>
  );
};

const BottomSheet = Drawer.Root;
const BottomSheetTrigger = Drawer.Trigger;
const BottomSheetDescription = Drawer.Description;
const BottomSheetTitle = Drawer.Title;

export { BottomSheet, BottomSheetContent, BottomSheetDescription, BottomSheetTitle, BottomSheetTrigger };
