import { css, styled } from "@/styles";

const watchlistCellStyles = css({
  fontFamily: "$roboto",
  fontWeight: "$normal",
  lineHeight: "$1",
  whiteSpace: "nowrap",

  variants: {
    align: {
      start: {
        textAlign: "start",
      },
      center: {
        textAlign: "center",
      },
      end: {
        textAlign: "end",
      },
    },
    wide: { true: { width: "100%" } },
  },
  defaultVariants: {
    align: "start",
  },
});

export const WatchlistStyledRow = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
  borderBottom: "1px solid $colors$bgBorder",
  variants: {
    hoverable: {
      true: {
        cursor: "pointer",
        "&:hover": {
          elementTransition: "backgroundColor",
          backgroundColor: "$bgBack",
        },
      },
    },
  },
});

export const WatchlistStyledHead = styled("div", watchlistCellStyles, {
  fontSize: "14px",
  p: "4px 8px",

  color: "$textSecondary",
});

export const WatchlistStyledCell = styled("div", watchlistCellStyles, {
  p: "16px 4px",
  fontSize: "12px",

  color: "$textMain",

  "&:first-child": {
    paddingInlineStart: "8px",
  },
  "&:last-child": {
    paddingInlineEnd: "8px",
  },
});
