import Big from "big.js";
import dayjs from "dayjs";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { NumberFormat } from "@/app/components";
import { setLiveChatVisibility, toggleLiveChat } from "@/app/libs/liveChat";
import { logError } from "@/app/libs/sentry";
import { Box } from "@/components/box";
import { Button } from "@/components/button";
import { Flex } from "@/components/flex";
import { SelectNewField } from "@/components/form/fields";
import { AccountSelect, getAccountOptions } from "@/components/form/fields/account-select/account-select";
import { HookForm } from "@/components/form/hook-form";
import { SubmitButton } from "@/components/form/submit-button";
import { Stack } from "@/components/stack";
import { IconArrowLeft, IconInfo } from "@/domains/icons";
import { Dialog as UiKitDialog, IconGlassCheckSmall, IconGlassCrossSmall } from "@/domains/ui-kit";
import { BonusTooltip } from "@/entities/bonuses/tooltip";
import { FieldAmount } from "@/features/withdrawal/components/form/FieldAmount/FieldAmount";
import { useClientId } from "@/hooks/client-id.hook";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { AvailabilityStatus, TradingAccountSorting, TradingAccountType, WithdrawField } from "@/services/openapi";
import { getProfile } from "@/services/profile";
import { Button as NewButton } from "@/shared/ui";
import { useAllAccountsQuery } from "@/state/server/accounts";
import { useMaximumWithdrawAmountQuery, useWithdrawalMutation } from "@/state/server/payment";

import { AdditionalField } from "./additional-field";
import { ButtonNewUI, ButtonNewUIFlat, WithdrawalFormFields } from "./withdrawal.page.form.constants";
import * as Styled from "./withdrawal.page.form.styled";
import { DialogData, Props, WithdrawalFormValues } from "./withdrawal.page.form.types";

export const WithdrawalPageForm: FC<Props> = ({ paymentOptions, paymentMethod, onMethodChange = () => {}, bonus }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const clientId = useClientId();
  const accountIdFromParams = searchParams.get("accountId");
  const { mutateAsync: withdrawal } = useWithdrawalMutation();
  const { data: accounts } = useAllAccountsQuery({
    type: TradingAccountType.Real,
    sorting: TradingAccountSorting.Newest,
    status: AvailabilityStatus.Active,
  });
  const bonusData = bonus && bonus[0];

  const uniqueId = useRef(uuidv4());

  const selectedOption = useMemo(
    () =>
      paymentOptions?.find(item => {
        return item.value === `${paymentMethod.id}${paymentMethod.reccuringId}`;
      }),
    [paymentMethod.id, paymentMethod.reccuringId, paymentOptions],
  );

  const form = useHookForm<WithdrawalFormValues>({
    defaultValues: { accountId: accountIdFromParams || undefined, paymentMethodId: `${selectedOption?.value}` },
    shouldUnregister: false,
  });
  const { watch, setError, clearErrors } = form;
  const [accountId, paymentMethodId, amountValue] = watch([
    WithdrawalFormFields.ACCOUNT_ID,
    WithdrawalFormFields.PAYMENT_METHOD_ID,
    WithdrawalFormFields.AMOUNT,
  ]);

  const [additionalFieldsValues, setAdditionalFieldsValues] = useState({});
  const [dialogData, setDialogData] = useState<DialogData | null>();
  const [step, setStep] = useState(1);

  const { id: paymentId, recurringId } = useMemo(
    () => paymentOptions?.find(item => item.value === paymentMethodId)?.data,
    [paymentOptions, paymentMethodId],
  );

  const { data: withdrawLimit } = useMaximumWithdrawAmountQuery(
    {
      accountId,
      paymentMethodId: paymentId,
      recurringId,
    },
    {
      enabled: !!accountId && !!paymentId,
    },
  );
  const currentLimitBeforeKyc = withdrawLimit?.extendedInfo?.withdrawCurrentLimitNoKyc;

  useEffect(() => {
    watch(values => {
      paymentMethod?.fields?.forEach(field => {
        const id = field.id;
        if (id) {
          setAdditionalFieldsValues(oldValues => ({
            ...oldValues,
            [id]: values[field.id as keyof typeof values],
          }));
        }
      });
    });
  }, [watch, paymentMethod.fields]);

  useEffect(() => {
    const method = paymentOptions?.find(method => method.value === paymentMethodId);
    onMethodChange(method?.data.id);
  }, [paymentMethodId]);

  const accountsOptions = useMemo(() => getAccountOptions(accounts!), [accounts]);

  const fieldsArray = useMemo(() => {
    const fieldsArr: Array<WithdrawField> = [];

    return Object.entries<string>(additionalFieldsValues).reduce(
      (acc, [key, value]) => [...acc, ...[{ id: key, value }]],
      fieldsArr,
    );
  }, [additionalFieldsValues]);

  const submitWithdrawal = async (values: WithdrawalFormValues) => {
    if (step === 1 && paymentMethod?.fields?.length) {
      return setStep(2);
    }

    try {
      clearErrors();
      const paymentId = paymentOptions?.find(item => item.value === values.paymentMethodId)?.data.id;
      const recurringId = paymentOptions?.find(item => item.value === values.paymentMethodId)?.data.recurringId || "";
      const { id: userID } = await getProfile();

      return withdrawal(
        {
          paymentMethodId: paymentId,
          accountId: values.accountId,
          recurringId,
          deviceType: "desktop",
          amount: values.amount.toString(),
          withdrawRequestPayload: {
            fields: fieldsArray,
          },
          /* id: uniqueId.current, */
          /* cardId: paymentId */
        },
        {
          onSuccess: data => {
            setLiveChatVisibility("minimized");
            if (data.error) {
              setDialogData({
                type: "error",
                errorMessage: t(data?.error),
              });
              setError(WithdrawalFormFields.AMOUNT, { type: "custom", message: data.error });
              return;
            }
            window.dataLayer?.push({
              event: "gtm_events",
              custom_timestamp: dayjs().valueOf(),
              userID,
              user_id: userID,
              client_id: clientId,
              ec: "attempt_withdraw",
              ea: "no_error",
              el: "no_error",
              ga4_event_name: "attempt_withdraw",
            });
            uniqueId.current = uuidv4();
            setDialogData({ type: "success" });
          },
          onError: ({ code, errorMessage }) => {
            setLiveChatVisibility("minimized");
            setDialogData({
              type: "error",
              errorMessage,
            });
            window.dataLayer?.push({
              event: "gtm_events",
              custom_timestamp: dayjs().valueOf(),
              userID,
              user_id: userID,
              client_id: clientId,
              ec: "attempt_withdraw",
              ea: "error",
              el: code,
              ga4_event_name: "attempt_withdraw",
            });
          },
        },
      );
    } catch (error) {
      logError("submitWithdrawal error");
    }
  };

  const handleBack = () => setStep(1);

  const currentAccount = useMemo(() => accounts?.find(({ id }) => id === accountId), [accounts, accountId]);
  const bonusBalance = useMemo(() => {
    if (!currentAccount?.availableToWithdraw) return 0;

    const credit = new Big(currentAccount?.credit ?? 0);
    const amount = new Big(amountValue ?? 0);
    const availableToWithdraw = new Big(currentAccount.availableToWithdraw);

    const result = credit.minus(amount.div(availableToWithdraw).times(credit)).toNumber();

    if (result < 0) return 0;
    else return result;
  }, [amountValue, currentAccount?.availableToWithdraw, currentAccount?.credit]);

  const needVerifyToWithdraw =
    currentLimitBeforeKyc != undefined && amountValue != null && new Big(amountValue).gt(currentLimitBeforeKyc);

  if (!accounts && !currentAccount) return null;

  return (
    <>
      <HookForm
        form={form}
        onSubmit={submitWithdrawal}
        css={{
          "@bp4": {
            display: "flex",
            gap: "32px",
          },
        }}
      >
        <Stack gap={5} css={{ maxWidth: "460px", width: "100%" }}>
          {step === 1 ? (
            <>
              {withdrawLimit?.description && (
                <Styled.InfoWrapper>
                  <Styled.InfoIcon as={IconInfo} />
                  <Styled.InfoTitle>{t(withdrawLimit.description)}</Styled.InfoTitle>
                </Styled.InfoWrapper>
              )}

              <Box css={{ width: "100%" }}>
                <Styled.Title>{t("withdrawal.form.payments.title")}</Styled.Title>
                <Styled.FieldWrapper>
                  <SelectNewField
                    name={WithdrawalFormFields.PAYMENT_METHOD_ID}
                    options={paymentOptions}
                    rules={{ required: t("form-errors.required-error")! }}
                  />
                </Styled.FieldWrapper>
              </Box>
              <Box css={{ width: "100%" }}>
                <Styled.Title>{t("withdrawal.form.account.title")}</Styled.Title>
                <Styled.FieldWrapper>
                  <AccountSelect
                    name={WithdrawalFormFields.ACCOUNT_ID}
                    options={accountsOptions}
                    resetFieldOnOptionsChanged={false}
                    rules={{ required: t("form-errors.required-error")! }}
                  />
                </Styled.FieldWrapper>
              </Box>
              <Box css={{ width: "100%" }}>
                <Styled.Title>{t("withdrawal.form.amount.title")}</Styled.Title>
                <Flex
                  css={{
                    flexDirection: "column-reverse",
                    gap: "15px",
                    "@bp1": {
                      flexDirection: "row",
                    },
                  }}
                >
                  <Styled.FieldWrapper>
                    <FieldAmount
                      currentLimit={currentLimitBeforeKyc}
                      withdrawLimit={withdrawLimit}
                      paymentMethod={paymentMethod}
                      paymentId={paymentId}
                      accountsOptions={accountsOptions}
                      account={currentAccount!}
                    />
                  </Styled.FieldWrapper>
                </Flex>
              </Box>
            </>
          ) : (
            <>
              <Styled.Title css={{ display: "flex", alignItems: "center", mb: 0, gap: "8px" }} onClick={handleBack}>
                <IconArrowLeft /> {t("withdrawal.form.back-button")}
              </Styled.Title>

              {paymentMethod?.fields?.map((field, fieldIndex) => <AdditionalField key={fieldIndex} {...field} />)}
            </>
          )}

          {needVerifyToWithdraw ? (
            <div>
              <NewButton size="sm" asChild>
                <Link to={onboardingRoutes.onboarding}>{t("button.go-to-verify")}</Link>
              </NewButton>
            </div>
          ) : (
            <SubmitButton size="small" css={ButtonNewUI}>
              {step === 1 && paymentMethod?.fields?.length ? t("button.next") : t("button.withdrawal-request")}
            </SubmitButton>
          )}
        </Stack>

        {!!currentAccount?.credit && bonusData && (
          <>
            <div className="my-6 h-px w-full bg-gray xl:my-0 xl:h-auto xl:w-px xl:grow" />

            <div className="w-full">
              <div className="mb-3 flex items-start justify-between font-roboto">
                <div className="flex items-center gap-2">
                  {t("withdrawal.bonus.text")}
                  <BonusTooltip
                    bonus={bonus[0]}
                    actionText={t("withdrawal.bonus.button")}
                    content={t("withdrawal.bonus.description")}
                  />
                </div>

                <div>
                  {!amountValue ? (
                    <NumberFormat
                      className="mb-1 block text-end"
                      value={currentAccount.credit}
                      currency={currentAccount.currency!}
                      decimalScale={currentAccount?.digits}
                    />
                  ) : (
                    <div className="grid gap-1">
                      <div className="text-end text-text-placeholder line-through">
                        <NumberFormat
                          value={currentAccount.credit}
                          currency={currentAccount.currency!}
                          decimalScale={currentAccount?.digits}
                        />
                      </div>
                      <div className="text-end">
                        <NumberFormat
                          value={new Big(bonusBalance).toFixed(2, 0)}
                          currency={currentAccount.currency!}
                          decimalScale={currentAccount?.digits}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </HookForm>

      {dialogData?.type.length && (
        <UiKitDialog open onOpenChange={dialogOpenState => !dialogOpenState && setDialogData(null)}>
          <UiKitDialog.Content>
            {dialogData.type === "success" ? (
              <div>
                <Styled.DialogContentWrapper>
                  <IconGlassCheckSmall width={72} height={72} />
                  <Styled.DialogTitle>{t("withdrawal.dialog.success-title")}</Styled.DialogTitle>
                  <Styled.DialogDescription>{t("withdrawal.dialog.success-description")}</Styled.DialogDescription>
                </Styled.DialogContentWrapper>
                <Styled.ButtonsWrapper>
                  <Button
                    size="small"
                    css={ButtonNewUI}
                    onClick={() => {
                      setDialogData(null);
                      navigate(cabinetRoutes.transactionHistory, { replace: true });
                    }}
                  >
                    {t("button.open-dashboard")}
                  </Button>
                </Styled.ButtonsWrapper>
              </div>
            ) : (
              <div>
                <Styled.DialogContentWrapper>
                  <IconGlassCrossSmall width={72} height={72} />
                  <Styled.DialogTitle>{t("withdrawal.dialog.error-title")}</Styled.DialogTitle>
                  <Styled.DialogDescription>{t("withdrawal.dialog.error-description")}</Styled.DialogDescription>
                </Styled.DialogContentWrapper>

                <Styled.ButtonsWrapper>
                  <Button size="small" css={ButtonNewUI} onClick={() => setDialogData(null)}>
                    {t("button.try-again")}
                  </Button>
                  <Button
                    size="small"
                    css={ButtonNewUIFlat}
                    onClick={() => {
                      setDialogData(null);
                      toggleLiveChat();
                    }}
                  >
                    {t("button.contact-support")}
                  </Button>
                </Styled.ButtonsWrapper>
              </div>
            )}
          </UiKitDialog.Content>
        </UiKitDialog>
      )}
    </>
  );
};
