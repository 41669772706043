import { produce } from "immer";
import { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useImmer } from "use-immer";

import { logError } from "@/app/libs/sentry";
import { TerminalAccountSymbols, TerminalPriceItemsContainer, TerminalSymbols } from "@/services/openapi";
import { terminalQueryKeys, useUpdateFavoriteSymbolsGroupMutation } from "@/state/server/terminal";

import { terminalCommands } from "../helpers/socket.commands";
import {
  MergedTerminalSymbol,
  normalizeSymbolsList,
  StaticSymbolGroup,
  SymbolGroupType,
  SymbolsListType,
} from "../helpers/symbols";
import { useTerminalSocket } from "../hooks/socket.hook";

type ContextProps = {
  symbols: MergedTerminalSymbol[];
  symbolsList: SymbolsListType;
  symbolGroups: SymbolGroupType[];
  group: SymbolGroupType;
  changeGroup: (group: SymbolGroupType) => void;
  favorites: TerminalAccountSymbols;
};

const Context = createContext<ContextProps | undefined>(undefined);

type Props = {
  symbols: TerminalSymbols;
  lastPrices: TerminalPriceItemsContainer;
  favorites: TerminalAccountSymbols;
  accountId: string;
  children: React.ReactNode;
};

const Provider = memo(
  ({ children, accountId, symbols: symbolsProps, favorites, lastPrices: lastPricesProps }: Props) => {
    const queryClient = useQueryClient();

    const { mutate } = useUpdateFavoriteSymbolsGroupMutation();

    const [list, setList] = useImmer<SymbolsListType>(() => {
      const normalizedSymbols = normalizeSymbolsList(symbolsProps.symbols!);
      const { charts, excluded } = favorites;

      lastPricesProps.items!.forEach(({ ask, bid, symbol }) => {
        normalizedSymbols[symbol!].ask = ask;
        normalizedSymbols[symbol!].bid = bid;
      });

      for (const symbol in normalizedSymbols) {
        normalizedSymbols[symbol].isFavorite = favorites.favorites!.includes(symbol);
        normalizedSymbols[symbol].isChartFavorite = charts!.includes(symbol);
        normalizedSymbols[symbol].isExcluded = excluded!.includes(symbol);
      }
      return normalizedSymbols;
    });

    const symbolGroups = useMemo(
      () => [
        StaticSymbolGroup.ALL,
        StaticSymbolGroup.POPULAR,
        StaticSymbolGroup.RECENTS,
        StaticSymbolGroup.FAVORITES,
        ...symbolsProps.groups!,
      ],
      [symbolsProps],
    );

    const [group, setGroup] = useState<SymbolGroupType>(() => favorites.group || symbolGroups[1]);

    const changeGroup = useCallback(
      (group: string) => {
        setGroup(group);
        mutate({ group, tradingAccountId: accountId });
        queryClient.setQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(accountId), oldData => {
          return produce(oldData!, draft => {
            draft.group = group;
          })!;
        });
      },
      [accountId, mutate, setGroup, queryClient],
    );

    const { sendJsonMessage, lastJsonMessage: socketMessage } = useTerminalSocket();

    useEffect(() => {
      sendJsonMessage(terminalCommands.marketWatch);
    }, []);

    useEffect(() => {
      try {
        const { charts, excluded } = favorites;
        setList(draftList => {
          for (const symbol in draftList) {
            draftList[symbol].isFavorite = favorites.favorites!.includes(symbol);
            draftList[symbol].isChartFavorite = charts!.includes(symbol);
            draftList[symbol].isExcluded = excluded!.includes(symbol);
          }
        });
      } catch (error) {
        logError(error);
      }
    }, [favorites]);

    useEffect(() => {
      try {
        if (!socketMessage || !socketMessage.dm) return;

        setList(draftList => {
          socketMessage.dm!.s!.forEach(({ s, a, b }) => {
            draftList[s!].ask = a;
            draftList[s!].bid = b;
            draftList[s!].eventTime = socketMessage.dm!.t!;
          });
        });
      } catch (error) {
        logError(error);
      }
    }, [socketMessage]);

    useEffect(() => {
      try {
        if (!socketMessage || !socketMessage.dlp) return;

        setList(draftList => {
          socketMessage.dlp!.p!.forEach(({ s, p }) => {
            draftList[s!].priceLast24H = p;
            draftList[s!].eventTime = socketMessage.dlp!.t!;
          });
        });
      } catch (error) {
        logError(error);
      }
    }, [socketMessage]);

    const symbols = useMemo(() => Object.values(list), [list]);

    const value: ContextProps = useMemo(
      () => ({
        symbols,
        symbolGroups,
        group,
        changeGroup,
        symbolsList: list,
        favorites,
      }),
      [symbols, symbolGroups, group, changeGroup, list, favorites],
    );

    return <Context.Provider value={value}>{children}</Context.Provider>;
  },
);

Provider.displayName = "TerminalSymbolsContextProvider";

const useSymbolsContext = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useSymbolsContext must be used within a TerminalSymbolsContextProvider");
  }

  return context;
};

export { Provider as TerminalSymbolsContextProvider, useSymbolsContext };
