import { cva } from "class-variance-authority";
import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, DialogTrigger } from "@/components/dialog";
import { IconPlus } from "@/domains/icons";
import { TerminalDialogContent } from "@/features/terminal/components/terminal-dialog-content";

import { useCurrentSymbolContext } from "../contexts/current-symbol-context";
import { useSymbolsContext } from "../contexts/symbols.context";
import { useLayoutContext } from "../layout/context";
import { WatchlistPopover } from "../watchlist/popover";
import { WatchlistContainer } from "../watchlist/watchlist.container";
import { ChartSymbolButtonContainer } from "./button/container";

const onSymbolsScroll = (node: HTMLDivElement | null) => {
  if (node) {
    const scrollWidth = node.scrollWidth;
    node.scrollTo({ left: -scrollWidth });
  }
};

const addButtonStyles = cva(
  "grid h-12 place-items-center rounded-[16px] border border-control-border bg-control-bg px-3 outline-none transition-colors hover:bg-control-bg-active data-[state=open]:bg-control-bg-active lg:h-14 lg:rounded-[12px] lg:px-2",
)();

type Props = {
  favoriteSymbols: string[];
  accountId: string;
  setSymbol: ReturnType<typeof useCurrentSymbolContext>["setSymbol"];
  symbolsList: ReturnType<typeof useSymbolsContext>["symbolsList"];
  symbolInfo: ReturnType<typeof useCurrentSymbolContext>["symbolInfo"];
  watchlistOpen: ReturnType<typeof useLayoutContext>["watchlistOpen"];
  isMobile: ReturnType<typeof useLayoutContext>["isMobile"];
  watchlistOnChange: ReturnType<typeof useLayoutContext>["watchlistOnChange"];
  closeWatchlist: ReturnType<typeof useLayoutContext>["closeWatchlist"];
};

const ChartSymbols: FC<Props> = ({
  favoriteSymbols,
  accountId,
  setSymbol,
  symbolsList,
  watchlistOpen,
  isMobile,
  watchlistOnChange,
  closeWatchlist,
  symbolInfo,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-2 overflow-auto lg:flex-row-reverse">
      <div className="flex flex-row-reverse gap-2 overflow-auto scrollbar-hide" ref={onSymbolsScroll}>
        {favoriteSymbols.map(symbol => {
          const isActive = symbol === symbolInfo.symbol;

          if (isActive) {
            return (
              <ChartSymbolButtonContainer
                key={symbol}
                symbolInfo={symbolInfo}
                chartFavoritesCount={favoriteSymbols.length}
                accountId={accountId}
                setSymbol={setSymbol}
                isActive
              />
            );
          }

          return (
            <ChartSymbolButtonContainer
              key={symbol}
              symbolInfo={symbolsList[symbol]}
              chartFavoritesCount={favoriteSymbols.length}
              accountId={accountId}
              setSymbol={setSymbol}
            />
          );
        })}
      </div>
      {isMobile ? (
        <>
          <Dialog open={watchlistOpen} onOpenChange={watchlistOnChange}>
            <DialogTrigger asChild>
              <button className={addButtonStyles}>
                <IconPlus />
              </button>
            </DialogTrigger>
            <TerminalDialogContent title={t("terminal.navigation.watchlist")}>
              <WatchlistContainer onClose={closeWatchlist} />
            </TerminalDialogContent>
          </Dialog>
        </>
      ) : (
        <WatchlistPopover open={watchlistOpen} onOpenChange={watchlistOnChange} onClose={closeWatchlist}>
          <button className={addButtonStyles}>
            <IconPlus />
          </button>
        </WatchlistPopover>
      )}
    </div>
  );
};

export { ChartSymbols };
