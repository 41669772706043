import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat, PnlFormat, PositiveNumberFormat } from "@/app/components";
import { getNumberColorClassname } from "@/app/ui/colors";
import { Badge } from "@/components/badge/badge";
import { getBonusMainPercent, getShowBonusCondition } from "@/entities/bonuses/helpers";
import { BonusTooltip } from "@/entities/bonuses/tooltip";
import { TradeButton } from "@/features/dashboard/components/trade-button/trade-button";
import { BonusUserPlatform, TradingAccount, TradingAccountType } from "@/services/openapi";

import { AccountBadge } from "../badge";
import { AccountCard } from "../card";
import { formatAccountPlatform } from "../helpers";

type Props = {
  account: TradingAccount;
  bonus: BonusUserPlatform | null;
};

const RealAccountCard: FC<Props> = ({ account, bonus }) => {
  const { uiPlatform, credit, currency, marginFree, availableToWithdraw, equity, digits, pnL, serverAccountId } =
    account;

  const { t } = useTranslation();

  const showBonus = useMemo(
    () =>
      getShowBonusCondition({
        allowedTradingServerAccounts: bonus?.allowedTradingServerAccounts! || [],
        serverAccountId: serverAccountId!,
      }),
    [bonus?.allowedTradingServerAccounts, serverAccountId],
  );

  return (
    <AccountCard>
      <AccountCard.Header account={account} />
      <AccountCard.Badges>
        <AccountBadge size="small" type={TradingAccountType.Real} data-test="account-badge-real" />
        <Badge size="small">{formatAccountPlatform(uiPlatform!.platform!)}</Badge>
      </AccountCard.Badges>
      <AccountCard.DataList>
        <AccountCard.DataListItem
          label={t("accounts.list.equity")}
          value={<PositiveNumberFormat value={equity} decimalScale={digits} currency={currency!} />}
        />
        <AccountCard.DataListItem
          label={t("accounts.list.free-margin")}
          value={<PositiveNumberFormat value={marginFree} decimalScale={digits} currency={currency!} />}
        />
        <AccountCard.DataListItem
          label={t("accounts.list.pnl")}
          valueClassName={getNumberColorClassname(pnL!)}
          value={<PnlFormat value={pnL} decimalScale={digits} currency={currency!} />}
        />
        {showBonus && bonus && (
          <AccountCard.DataListItem
            label={
              <div className="flex items-center gap-2">
                {t("accounts.list.bonus.name")}
                <BonusTooltip
                  bonus={bonus}
                  actionText={t("accounts.list.bonus.button")}
                  content={t("accounts.list.bonus.description", { percent: getBonusMainPercent(bonus) })}
                />
              </div>
            }
            value={<NumberFormat value={credit} decimalScale={digits} currency={currency!} />}
          />
        )}
        <AccountCard.DataListItem
          label={t("accounts.list.available-withdraw")}
          value={<NumberFormat value={availableToWithdraw} decimalScale={digits} currency={currency!} />}
        />
      </AccountCard.DataList>
      <AccountCard.Footer>
        <TradeButton account={account} />
      </AccountCard.Footer>
    </AccountCard>
  );
};

export { RealAccountCard };
