import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, Link } from "react-router-dom";

import { Dialog } from "@/components/dialog";
import { NewDialogContent } from "@/components/new-dialog";
import { CreateAccountContainer } from "@/features/dashboard/components/create-account/create-account.container";
import { DialogName, useDialog } from "@/hooks/dialog.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { AvailabilityStatus, TradingAccountType } from "@/services/openapi";
import { AlertDialog, Button } from "@/shared/ui";
import { useServersQuery } from "@/state/server/accounts";

export const NoAccountsDialog = () => {
  const { t } = useTranslation();
  const { open: openMainDialog, onClose: onCloseMainDialog } = useDialog(DialogName.NO_ACCOUNTS);
  const [open, setOpen] = useState(false);

  const { data: servers } = useServersQuery();

  const onClose = () => setOpen(false);

  const openNewAccountDialog = () => {
    onCloseMainDialog();
    setOpen(true);
  };

  return (
    <>
      <AlertDialog open={openMainDialog} onOpenChange={onCloseMainDialog}>
        <AlertDialog.Content
          noCross
          title={t("accounts.dialogs.no-accounts.title")}
          description={t("accounts.dialogs.no-accounts.description")}
        >
          <AlertDialog.Buttons>
            <Button onClick={openNewAccountDialog}>{t("accounts.dialogs.no-accounts.button-1")}</Button>
            <AlertDialog.Cancel asChild>
              <Button variant="tertiary" asChild>
                <Link
                  to={{
                    pathname: cabinetRoutes.dashboard,
                    search: createSearchParams({ tab: AvailabilityStatus.Archived }).toString(),
                  }}
                >
                  {t("accounts.dialogs.no-accounts.button-2")}
                </Link>
              </Button>
            </AlertDialog.Cancel>
          </AlertDialog.Buttons>
        </AlertDialog.Content>
      </AlertDialog>

      {servers && (
        <Dialog open={open} onOpenChange={setOpen}>
          <NewDialogContent title={t("accounts.open-new")}>
            <CreateAccountContainer type={TradingAccountType.Real} servers={servers} onClose={onClose} />
          </NewDialogContent>
        </Dialog>
      )}
    </>
  );
};
