import { FC } from "react";

import { useScreenSize } from "@/hooks/screen-size.hook";
import { useMaximumWithdrawAmountQuery } from "@/state/server/payment";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { Props } from "./types";
import { DepositVerificationBottomSheet } from "./verification-bottomsheet";
import { DepositVerificationDialog } from "./verification-dialog";

export const fromDepositState = "fromDeposit";

export const VerificationContainer: FC<Props> = ({
  open,
  onOpenChange,
  submitDeposit,
  accountId,
  recurringId,
  paymentMethodId,
  depositLimit,
  isSurveyCompleted,
}) => {
  const { isMobile } = useScreenSize();
  const { featuresInfo } = useProfileData();
  const isSurveyMandatory = featuresInfo?.mandatorySurvey;

  const { data: withdrawLimit } = useMaximumWithdrawAmountQuery(
    {
      accountId,
      paymentMethodId: paymentMethodId,
      recurringId,
    },
    {
      enabled: !!accountId && !!paymentMethodId && !!open,
    },
  );

  if (!featuresInfo && !withdrawLimit) return null;

  if (isMobile)
    return (
      <DepositVerificationBottomSheet
        isSurveyMandatory={isSurveyMandatory!}
        submitDeposit={submitDeposit}
        open={open}
        onOpenChange={onOpenChange}
        depositLimit={depositLimit}
        withdrawLimit={withdrawLimit?.extendedInfo?.withdrawCurrentLimitNoKyc!}
        isSurveyCompleted={isSurveyCompleted}
      />
    );

  return (
    <DepositVerificationDialog
      isSurveyMandatory={isSurveyMandatory!}
      submitDeposit={submitDeposit}
      open={open}
      onOpenChange={onOpenChange}
      depositLimit={depositLimit}
      withdrawLimit={withdrawLimit?.extendedInfo?.withdrawCurrentLimitNoKyc!}
      isSurveyCompleted={isSurveyCompleted}
    />
  );
};
