import { Link } from "react-router-dom";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { useTranslation } from "@/hooks/translator.hook";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { Banner } from "@/shared/ui";

import img from "./assets/verify-promo.png";

export const CompleteVerificationBanner = () => {
  const { t } = useTranslation();

  return (
    <Banner
      text={t("banners.verify.text")}
      bonusImg={img}
      actionButton={
        <Banner.ActionButton onClick={() => track(amplitudeEvents.verify.bannerClick)} asChild>
          <Link to={onboardingRoutes.onboarding}>{t("banners.verify.button")}</Link>
        </Banner.ActionButton>
      }
    />
  );
};
