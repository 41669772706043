import { type FC } from "react";

import { Dialog, DialogTrigger } from "@/components/dialog";
import { TooltipQuestionIcon } from "@/components/icons";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { BonusUserPlatform } from "@/services/openapi";
import { Tooltip } from "@/shared/ui";

import { BonusDialog } from "./dialog/bonus-dialog";

type Props = {
  content: string;
  actionText: string;
  bonus: BonusUserPlatform;
};

const BonusTooltip: FC<Props> = ({ bonus, actionText, content }) => {
  const [opened, { onOpenChange, close: closeTooltip }] = useDisclosure();

  return (
    <Dialog>
      <Tooltip
        open={opened}
        onOpenChange={onOpenChange}
        action={
          <DialogTrigger asChild>
            <Tooltip.Action onClick={closeTooltip}>{actionText}</Tooltip.Action>
          </DialogTrigger>
        }
        content={content}
      >
        <TooltipQuestionIcon />
      </Tooltip>
      <BonusDialog bonus={bonus} />
    </Dialog>
  );
};

export { BonusTooltip };
