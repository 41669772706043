import { ComponentProps, FC, useCallback } from "react";

import { getInputNumberValue } from "@/features/terminal/helpers/formatting";
import { SymbolIcon } from "@/features/terminal/symbols/icon";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalDealType } from "@/services/openapi";
import { toast } from "@/shared/ui";
import { useModifyTerminalOrderMutation } from "@/state/server/terminal";

import { ModifyTakeProfitForm } from "./take-profit.form";

type Props = {
  accountId: string;
  orderId: number;
  priceDecimalScale: number;
  takeProfit: number | undefined;
  stopLoss: number | undefined;
  ask: number;
  bid: number;
  orderType: TerminalDealType;
  onClose: () => void;
  symbol: string;
  volume: number;
  currencyDecimalScale: number;
  baseCurrency: string;
  quoteCurrency: string;
  contractSize: number;
  currency: string;
  openPrice: number;
  currentPrice: number;
  isMobile?: boolean;
};

export const ModifyOpenOrderTakeProfitContainer: FC<Props> = ({
  accountId,
  orderId,
  onClose,
  priceDecimalScale,
  takeProfit,
  ask,
  bid,
  orderType,
  symbol,
  volume,
  stopLoss,
  baseCurrency,
  contractSize,
  currency,
  openPrice,
  quoteCurrency,
  isMobile,
  currentPrice,
  currencyDecimalScale,
}) => {
  const { t } = useTranslation();
  const { mutateAsync: modifyOrder } = useModifyTerminalOrderMutation();

  const handleSubmit: ComponentProps<typeof ModifyTakeProfitForm>["onSubmit"] = useCallback(
    ({ takeProfit }) => {
      return modifyOrder(
        {
          tradingAccountId: accountId,
          id: orderId,
          terminalModifyOrderRequest: { takeProfit: getInputNumberValue(takeProfit), stopLoss },
        },
        {
          onSuccess: () => {
            onClose();
            toast({
              icon: <SymbolIcon size="md" symbol={symbol} />,
              title: t("terminal.messages.market-order-changed", { symbol })!,
              description: <>#{orderId}</>,
            });
          },
        },
      );
    },
    [modifyOrder, accountId, orderId, onClose, t, symbol, stopLoss],
  );

  return (
    <ModifyTakeProfitForm
      onSubmit={handleSubmit}
      priceDecimalScale={priceDecimalScale}
      takeProfit={takeProfit}
      ask={ask}
      bid={bid}
      orderType={orderType}
      baseCurrency={baseCurrency}
      contractSize={contractSize}
      currency={currency}
      openPrice={openPrice}
      quoteCurrency={quoteCurrency}
      volume={volume}
      isMobile={isMobile}
      symbol={symbol}
      currentPrice={currentPrice}
      currencyDecimalScale={currencyDecimalScale}
    />
  );
};
