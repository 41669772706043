import { FC } from "react";

import { DialogDescription, DialogTitle } from "@/components/dialog";
import { DialogDescriptionText, DialogTitleText } from "@/components/dialog/styles";
import { Flex } from "@/components/flex";
import { Text } from "@/components/text";
import { useTranslation } from "@/hooks/translator.hook";
import { FundsTx } from "@/services/openapi";
import { styled } from "@/styles";

import { TransactionStatusBadge } from "./transaction-status-badge";
import { getTransactionAmount } from "./transactions-table/transaction-table-row";

const Li = styled("li", {
  display: "flex",
  gap: "20px",
  alignItems: "center",
  justifyContent: "space-between",
  py: "8px",

  borderBottom: "1px solid $colors$bgBorder",
});

type Props = {
  transaction: FundsTx;
};

export const TransactionDialogContent: FC<Props> = ({ transaction: { status, details, description, amounts } }) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle asChild>
        <DialogTitleText css={{ mb: "10px", mt: "30px", "@bp2": { my: "10px" } }}>
          {t("transactions.dialog.title")}
        </DialogTitleText>
      </DialogTitle>
      <DialogDescription asChild>
        <DialogDescriptionText>{description}</DialogDescriptionText>
      </DialogDescription>
      <Flex css={{ justifyContent: "center", my: "25px" }}>
        <TransactionStatusBadge status={status!} />
      </Flex>
      <ul>
        <Li>
          <Text family="roboto" lineHeight="3" color="dimmed">
            {t("transactions.table.amount")}
          </Text>
          <Text family="roboto" lineHeight="3">
            {getTransactionAmount(amounts!, false)}
          </Text>
        </Li>
        {details &&
          details.map(({ description, title, amount }, i) => (
            <Li key={i}>
              <Text family="roboto" lineHeight="3" color="dimmed">
                {title}
              </Text>
              {amount ? (
                <Text family="roboto" lineHeight="3">
                  {getTransactionAmount({ first: amount }, false)}
                </Text>
              ) : (
                <Text family="roboto" lineHeight="3">
                  {description}
                </Text>
              )}
            </Li>
          ))}
      </ul>
    </>
  );
};
