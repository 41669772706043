import * as DialogPrimitive from "@radix-ui/react-dialog";
import { ComponentProps, ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

import { useScreenSize } from "@/hooks/screen-size.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { CSS, styled } from "@/styles";
import { css, darkThemeSelector, keyframes } from "@/styles";

import { CloseIcon } from "../icons";
import { Text } from "../text";
import { UnstyledButton } from "../unstyled-button.tsx";

// TODO: УДАЛИТЬ НАХУЙ ЭТО ГОВНО!!!

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

type TitleProps = ComponentProps<typeof Text> & { as?: any };
export const DialogTitleText = forwardRef<ElementRef<typeof Text>, TitleProps>(
  ({ children, as = "h2", ...restProps }, ref) => {
    return (
      <Text as={as} weight="bold" size="6" align="center" lineHeight="4" {...restProps} ref={ref}>
        {children}
      </Text>
    );
  },
);
DialogTitleText.displayName = "DialogTitleText";

type DescriptionProps = ComponentProps<typeof Text> & { as?: any };
export const DialogDescriptionText = forwardRef<ElementRef<typeof Text>, DescriptionProps>(
  ({ children, as = "p", ...restProps }, ref) => {
    return (
      <Text as={as} family="roboto" color="dimmed" align="center" {...restProps} ref={ref}>
        {children}
      </Text>
    );
  },
);
DialogDescriptionText.displayName = "DialogDescriptionText";

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  position: "fixed",
  zIndex: 50,
  inset: 0,

  display: "grid",
  placeItems: "center",
  overflowY: "auto",
  overflowX: "hidden",

  backgroundColor: "rgba(0, 0, 0, .4)",
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,

  [darkThemeSelector]: {
    backgroundColor: "rgba(0, 0, 0, .15)",
  },
});

const StyledContent = styled(DialogPrimitive.Content, {
  position: "relative",
  zIndex: 50,
  width: "100%",
  height: "100%",

  backgroundColor: "$bgPrimary",
  boxShadow: "0px 10px 40px -10px rgba(21, 38, 149, 0.15)",

  p: 16,
  pt: 0,

  "@bp2": {
    p: 56,
    height: "auto",
    borderRadius: 24,

    mt: "5vh",
    mb: "30px",

    maxWidth: 512,
  },

  "&:focus": {
    outline: "none",
  },
});

const Close = () => {
  const { t } = useTranslation();

  return (
    <DialogPrimitive.Close asChild>
      <button aria-label={t("button.close")!}>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9498 7.55022C17.3403 7.94074 17.3403 8.57391 16.9498 8.96443L13.4142 12.5L16.9497 16.0355C17.3403 16.426 17.3403 17.0592 16.9497 17.4497C16.5592 17.8402 15.926 17.8402 15.5355 17.4497L12 13.9142L8.4645 17.4497C8.07398 17.8402 7.44081 17.8402 7.05029 17.4497C6.65976 17.0592 6.65976 16.426 7.05029 16.0355L10.5858 12.5L7.05024 8.96443C6.65971 8.57391 6.65971 7.94074 7.05024 7.55022C7.44076 7.15969 8.07393 7.15969 8.46445 7.55022L12 11.0858L15.5356 7.55022C15.9261 7.15969 16.5593 7.15969 16.9498 7.55022Z"
            fill="#212121"
          />
        </svg>
      </button>
    </DialogPrimitive.Close>
  );
};

type Props = ComponentPropsWithoutRef<typeof StyledContent> & {
  title: string;
  description?: string;
  css?: CSS;
  image?: {
    src: string;
    alt?: string;
  };
};

const NewDialogContent = forwardRef<ElementRef<typeof StyledContent>, Props>(
  ({ children, title, description, image, ...props }, forwardedRef) => {
    return (
      <DialogPrimitive.Portal>
        <StyledOverlay>
          <StyledContent {...props} ref={forwardedRef}>
            {image && (
              <div className="mx-auto mb-[25px] flex justify-center">
                <img alt={image.alt} src={image.src} />
              </div>
            )}

            <div className="mb-[16px] md:hidden">
              <div className="flex items-center justify-between py-[16px]">
                <DialogPrimitive.Title asChild>
                  <Text as="h2" size="5" weight="bold" css={{ lineHeight: 1.2 }}>
                    {title}
                  </Text>
                </DialogPrimitive.Title>
                <Close />
              </div>
              {description && (
                <DialogPrimitive.Description asChild>
                  <Text family="roboto" css={{ lineHeight: 1.5, marginTop: 8 }}>
                    {description}
                  </Text>
                </DialogPrimitive.Description>
              )}
            </div>

            <div className="mb-[32px] hidden md:block">
              <DialogPrimitive.Title asChild>
                <Text as="h2" align="center" weight="bold" size="6" css={{ lineHeight: 1.2 }}>
                  {title}
                </Text>
              </DialogPrimitive.Title>
              {description && (
                <DialogPrimitive.Description asChild>
                  <Text family="roboto" align="center" css={{ lineHeight: 1.5, marginTop: 16 }}>
                    {description}
                  </Text>
                </DialogPrimitive.Description>
              )}
              <div className="absolute end-[24px] top-[24px]" data-test="dialog-close">
                <Close />
              </div>
            </div>
            {children}
          </StyledContent>
        </StyledOverlay>
      </DialogPrimitive.Portal>
    );
  },
);
NewDialogContent.displayName = "NewDialogContent";

export { NewDialogContent };
