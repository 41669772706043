import { css, darkThemeSelector, keyframes, styled } from "@/styles";

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

export const overlayStyles = css({
  backgroundColor: "rgba(0, 0, 0, 0.4)",
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,

  [darkThemeSelector]: {
    backgroundColor: "rgba(0, 0, 0, 0.15)",
  },
});

export const panelStyles = css({
  backgroundColor: "$lite",
  boxShadow: "0px 10px 40px -20px rgba(0, 0, 0, 0.1)",

  "@bp2": {
    borderRadius: "24px",
  },
});

export const Overlay = styled("div", overlayStyles, {
  position: "fixed",
  inset: "0",

  display: "grid",
  placeItems: "center",
  overflowY: "auto",
  overflowX: "hidden",

  /* just for hotfix feature tour */
  zIndex: "100000",
});

export const Content = styled("div", panelStyles, {
  position: "relative",
  width: "100%",
  height: "100%",

  p: "24px 16px",

  "@bp2": {
    p: "60px",
    mt: "5vh",
    mb: "30px",
    height: "auto",
  },

  variants: {
    size: {
      xs: {
        "@bp2": {
          maxWidth: "368px",
        },
      },
      small: {
        "@bp2": {
          maxWidth: "475px",
        },
      },
      middle: {
        "@bp2": {
          maxWidth: "526px",
        },
      },
    },
    noSidePadding: {
      true: {
        p: "24px 0",

        "@bp2": {
          p: "60px 0",
        },
      },
    },
  },

  "&:focus": {
    outline: "none",
  },

  defaultVariants: {
    size: "middle",
  },
});

export const ButtonIcon = styled("button", {
  all: "unset",
  cursor: "pointer",

  position: "absolute",
  top: 12,
  insetInlineEnd: 17,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 35,
  width: 35,
  color: "$textMain",
  borderRadius: "$round",

  "&:hover": { backgroundColor: "$bgBorder" },
  "&:focus": { boxShadow: "0 0 0 2px $colors$bgBorder" },
  "&:active": { transform: "scale(0.9)" },
  "&:disabled": {
    opacity: "0.5",
    cursor: "default",
    pointerEvents: "none",
  },
});
