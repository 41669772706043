import { FC, ReactNode } from "react";

import { DialogDescription, DialogTitle } from "@/components/dialog";
import { styled } from "@/styles";

import { DialogDescriptionText, DialogTitleText } from "../dialog/styles";

type Props = {
  children: ReactNode;
  title: ReactNode;
  imageSrc?: string;
  description?: ReactNode;
};

export const InfoDialog: FC<Props> = ({ children, description, title, imageSrc }) => {
  return (
    <div className="flex h-full flex-col">
      {imageSrc && (
        <div className="flex justify-center">
          <img className="h-[158px] w-[158px]" src={imageSrc} alt="" loading="lazy" />
        </div>
      )}
      <DialogTitle asChild>
        <DialogTitleText css={{ mb: "16px", mt: "16px", "@bp3": { mt: 0 } }}>{title}</DialogTitleText>
      </DialogTitle>
      {description && (
        <DialogDescription asChild>
          <DialogDescriptionText>{description}</DialogDescriptionText>
        </DialogDescription>
      )}

      <div className="mt-6 flex grow flex-col">{children}</div>
    </div>
  );
};

export const InfoDialogContent = styled("div", {
  p: "40px 32px",
  mb: "25px",
  border: "1px solid",
  borderColor: "$bgBorder",
  borderRadius: "16px",
  variants: {
    dashed: {
      true: {
        border: "dashed $primary",
      },
    },
  },
});
