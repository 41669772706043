import { FC } from "react";

import { SubmitButton } from "@/app/form";
import { DialogButtons, DialogClose } from "@/components/dialog";
import { NumberField } from "@/components/form/fields";
import { HookForm } from "@/components/form/hook-form";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { Button } from "@/shared/ui";

enum SetBalanceFields {
  VALUE = "value",
}

export interface ISetBalanceFormValues {
  [SetBalanceFields.VALUE]: number;
}

type Props = {
  onSubmit: (values: ISetBalanceFormValues) => void;
  currency: string;
};

export const SetBalanceForm: FC<Props> = ({ onSubmit, currency }) => {
  const { t } = useTranslation();
  const form = useHookForm<ISetBalanceFormValues>();

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <NumberField
        name={SetBalanceFields.VALUE}
        placeholder={t("fields.amount.placeholder")!}
        suffix={` ${currency}`}
        rules={{
          required: t("form-errors.required-error")!,
          validate: (value: number) => value !== 0 || t("form-errors.greater-than", { value: 0 })!,
        }}
        data-test="set-balance-field"
      />
      <DialogButtons>
        <SubmitButton data-test="set-balance-submit">{t("button.update")}</SubmitButton>
        <DialogClose asChild>
          <Button variant="tertiary" data-test="set-balance-cancel">
            {t("button.cancel")}
          </Button>
        </DialogClose>
      </DialogButtons>
    </HookForm>
  );
};
