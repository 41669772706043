import { styled } from "@/styles";

const StyledSvg = styled("svg", {
  color: "$textSecondary",
  variants: {
    filled: {
      true: {
        color: "$bgAdditional",
      },
    },
  },
});

export const FavoriteIcon = ({ filled }: { filled?: boolean }) =>
  filled ? (
    <StyledSvg
      filled={filled}
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5817 13.7974L4.42552 8.8775L0.851044 5.39324L5.79085 4.67545L8 0.199219L10.2092 4.67545L15.149 5.39324L11.5745 8.8775L12.4183 13.7974L8 11.4745L3.5817 13.7974Z"
        fill="currentColor"
      />
    </StyledSvg>
  ) : (
    <StyledSvg
      filled={filled}
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.24577 12.8833L4.91833 8.96202L4.96276 8.70294L4.77453 8.51946L1.92552 5.74236L5.86275 5.17025L6.12289 5.13245L6.23922 4.89673L8 1.32899L9.76079 4.89673L9.87712 5.13245L10.1373 5.17025L14.0745 5.74236L11.2255 8.51946L11.0372 8.70294L11.0817 8.96202L11.7542 12.8833L8.23268 11.0319L8 10.9096L7.76733 11.0319L4.24577 12.8833Z"
        stroke="currentColor"
      />
    </StyledSvg>
  );
