import { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { logError } from "@/app/libs/sentry";
import { useInterval } from "@/hooks/interval.hook";

import { terminalCommands } from "../helpers/socket.commands";
import { isSymbolAvailable, MergedTerminalSymbol } from "../helpers/symbols";
import { useTerminalSocket } from "../hooks/socket.hook";
import { useSymbolsContext } from "./symbols.context";

type ContextProps = {
  symbolInfo: MergedTerminalSymbol;
  currentSymbol: string;
  setSymbol: (symbol: string) => void;
  isTradingAvailable: { available: boolean; remainingTime?: Date };
};

const Context = createContext<ContextProps | undefined>(undefined);

const Provider = memo(
  ({
    children,
    symbol,
    changeSymbol,
  }: {
    children: React.ReactNode;
    symbol: string;
    changeSymbol: (symbol: string) => void;
  }) => {
    const { symbolsList } = useSymbolsContext();
    const [symbolInfo, setSymbolInfo] = useState<MergedTerminalSymbol>(() => symbolsList[symbol]!);
    const [isTradingAvailable, setIsTradingAvailable] = useState<{ available: boolean; remainingTime?: Date }>(() =>
      isSymbolAvailable(symbolInfo.sessions!),
    );

    const { sendJsonMessage, lastJsonMessage: socketMessage } = useTerminalSocket();

    const updateSymbol: ContextProps["setSymbol"] = useCallback(
      symbol => {
        const symbolInfo = symbolsList[symbol]!;
        changeSymbol(symbol);
        setSymbolInfo(symbolInfo);
        setIsTradingAvailable(isSymbolAvailable(symbolInfo.sessions!));
        sendJsonMessage(terminalCommands.tickSubscribe(symbol));
      },
      [changeSymbol, symbolsList, sendJsonMessage],
    );

    useInterval(() => {
      setIsTradingAvailable(isSymbolAvailable(symbolInfo.sessions!));
    }, 1000);

    useEffect(() => {
      sendJsonMessage(terminalCommands.tickSubscribe(symbol));
    }, []);

    useEffect(() => {
      if (!socketMessage || !socketMessage.dt) return;
      try {
        const { dt } = socketMessage;
        if (symbolInfo.symbol === dt.s) {
          setSymbolInfo(prev => ({
            ...prev,
            ask: dt.a,
            bid: dt.b,
            eventTime: dt.t,
          }));
        }
      } catch (error) {
        logError(error);
      }
    }, [socketMessage]);

    useEffect(() => {
      try {
        if (!socketMessage || !socketMessage.dlp) return;

        const { p } = socketMessage.dlp.p!.find(({ s }) => s === symbolInfo.symbol)!;
        setSymbolInfo(prev => ({
          ...prev,
          priceLast24H: p!,
          eventTime: socketMessage.dlp!.t!,
        }));
      } catch (error) {
        logError(error);
      }
    }, [socketMessage]);

    const value: ContextProps = useMemo(
      () => ({
        currentSymbol: symbol,
        symbolInfo,
        setSymbol: updateSymbol,
        isTradingAvailable,
      }),
      [symbolInfo, symbol, isTradingAvailable, updateSymbol],
    );

    return <Context.Provider value={value}>{children}</Context.Provider>;
  },
);

Provider.displayName = "TerminalCurrentSymbolContextProvider";

const useCurrentSymbolContext = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useCurrentSymbolContext must be used within a TerminalCurrentSymbolContextProvider");
  }

  return context;
};

export { Provider as TerminalCurrentSymbolContextProvider, useCurrentSymbolContext };
