import { Slot, Slottable } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from "react";

import { cn } from "@/shared/styles";

import { Loader } from "../loader";

const buttonStyles = cva(
  "inline-flex items-center justify-center gap-1 whitespace-nowrap rounded-[32px] font-gilroy font-bold transition-colors focus-visible:outline-none disabled:pointer-events-none",
  {
    variants: {
      variant: {
        primary: "bg-positive-bg text-accent-over hover:bg-positive-bg-active disabled:bg-contrast-tertiary",
        secondary: "bg-accent-bg text-accent-over hover:bg-accent-bg-hover disabled:bg-contrast-tertiary",
        tertiary:
          "border border-control-border bg-accent-over text-contrast-primary hover:text-contrast-secondary disabled:text-contrast-tertiary",
        flat: "bg-transparent text-contrast-primary hover:text-contrast-secondary disabled:text-contrast-tertiary",
        danger: "bg-negative-bg text-accent-over hover:bg-negative-bg-active disabled:bg-contrast-tertiary",
      },
      size: {
        sm: "h-10 px-4 py-2 text-[14px]",
        md: "h-14 px-6 py-4 text-[16px]",
        lg: "h-16 px-10 py-5 text-[18px]",
      },
      pending: {
        true: "",
      },
    },
    compoundVariants: [
      {
        variant: "primary",
        pending: true,
        className: "disabled:bg-positive-bg-active",
      },
      {
        variant: "secondary",
        pending: true,
        className: "disabled:bg-accent-bg",
      },
      {
        variant: "tertiary",
        pending: true,
        className: "!text-contrast-primary",
      },
      {
        variant: "flat",
        pending: true,
        className: "!text-contrast-primary disabled:text-contrast-primary",
      },
      {
        variant: "danger",
        pending: true,
        className: "!text-accent-over disabled:bg-negative-bg-active",
      },
    ],
  },
);

type ButtonProps = ComponentPropsWithoutRef<"button"> &
  VariantProps<typeof buttonStyles> & {
    asChild?: boolean;
    startSection?: ReactNode;
    pending?: boolean;
  };

const Button = forwardRef<ElementRef<"button">, ButtonProps>(
  (
    {
      className,
      variant = "primary",
      size = "md",
      asChild = false,
      pending,
      disabled: disabledProp,
      type = "button",
      startSection,
      children,
      ...props
    },
    ref,
  ) => {
    const disabled = disabledProp || pending;
    const Comp = asChild && !pending ? Slot : "button";
    return (
      <Comp
        className={cn(buttonStyles({ variant, size, className, pending }))}
        disabled={disabled}
        type={type}
        ref={ref}
        {...props}
      >
        {!pending && startSection}
        {!pending && <Slottable>{children}</Slottable>}
        {pending && <Loader className="px-4" />}
      </Comp>
    );
  },
);

export { Button, buttonStyles };
