import { Portal } from "@radix-ui/react-portal";
import { type FC } from "react";

import { Button } from "@/components/button";
import { Text } from "@/components/text";
import { CookieType } from "@/constants/enums";
import { useTranslation } from "@/hooks/translator.hook";
import { darkThemeSelector, styled } from "@/styles";

const TransparentLayer = styled("div", {
  position: "fixed",
  inset: "0",
  background: "rgba(0, 0, 0, 0.4)",
  display: "block",
  zIndex: 50,

  "@bp2": {
    display: "none",
  },
});

const Container = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: "24px 24px",
  background: "$white",
  borderRadius: "24px 24px 0 0",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 20px 4px",
  position: "fixed",
  bottom: "0",
  insetInlineStart: "0",
  width: "100%",
  maxWidth: "700px",
  zIndex: 50,

  "@bp2": {
    flexDirection: "row",
    padding: "24px 32px",
    bottom: "5px",
    insetInlineStart: "50%",
    transform: "translateX(-50%)",
    width: "98%",
    borderRadius: "24px",
  },

  [darkThemeSelector]: {
    background: "$bgBackDark",
  },
});

const TextWrapper = styled("div", {
  flex: "1",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "start",
  width: "100%",

  "@bp2": {
    marginTop: "16px",
    width: "auto",
  },
});

const ButtonsWrapper = styled("div", {
  marginTop: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  width: "100%",

  "@bp2": {
    width: "auto",
  },

  button: {
    minWidth: "205px",
    paddingInlineStart: "24px",
    paddingInlineEnd: "24px",
    width: "100%",

    "@bp2": {
      width: "auto",
    },
  },
});

interface CookiePopupProps {
  onCookieAccept: (cookieType: CookieType) => void;
}

export const CookiePopup: FC<CookiePopupProps> = ({ onCookieAccept }) => {
  const { t } = useTranslation();
  const paragraph = { __html: t("cookie-parapgraph") };

  return (
    <Portal>
      <TransparentLayer />
      <Container className="cookie-popup">
        <TextWrapper>
          <Text size="4" weight="bold" lineHeight="4">
            {t("cookie-title")}
          </Text>
          <Text
            size="2"
            lineHeight="4"
            css={{
              lineHeight: "150%",
              letterSpacing: "0.01em",
              marginTop: "16px",
              marginInlineEnd: "16px",

              a: {
                color: "$primary",
              },
            }}
            dangerouslySetInnerHTML={paragraph}
          ></Text>
        </TextWrapper>
        <ButtonsWrapper>
          <Button size="small" onClick={() => onCookieAccept(CookieType.ACCEPT_ALL)}>
            {t("cookie-btn-accept")}
          </Button>
          <Button size="small" variant="secondary" onClick={() => onCookieAccept(CookieType.ACCEPT_ESSENTIALS)}>
            {t("cookie-btn-essential")}
          </Button>
        </ButtonsWrapper>
      </Container>
    </Portal>
  );
};
