import * as Popover from "@radix-ui/react-popover";
import { type FC, useMemo } from "react";

import { PnlFormat, PositiveNumberFormat } from "@/app/components";
import { getNumberColorClassname } from "@/app/ui/colors";
import { AccountBadge } from "@/components/badge/account-badge";
import { Dialog } from "@/components/dialog";
import { BonusDialog } from "@/entities/bonuses/dialog/bonus-dialog";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { AvailabilityStatus, TradingAccountSorting, TradingAccountType } from "@/services/openapi";
import { cn } from "@/shared/styles";
import { popoverContentStyles } from "@/shared/ui";
import { useAllAccountsQuery } from "@/state/server/accounts";

import { useTerminalAccountSummary } from "../account-summary/context";
import { useTerminalAccountContext } from "../contexts/account.context";
import { useOrdersContext } from "../contexts/orders.context";
import { useLayoutContext } from "../layout/context";
import { AccountsSelectPopoverContent } from "./popover-content";

type Props = {
  centered?: boolean;
};

const AccountSelect: FC<Props> = ({ centered }) => {
  const { account, setAccount, bonus, showBonus } = useTerminalAccountContext();

  const { pnl, currency, currencyDecimalScale } = useTerminalAccountSummary();

  const { openOrders } = useOrdersContext();

  const [opened, { onOpenChange, close: closePopover }] = useDisclosure();

  const { isMobile } = useLayoutContext();

  const { data } = useAllAccountsQuery({
    isTerminalEnabledOnly: true,
    status: AvailabilityStatus.Active,
    sorting: TradingAccountSorting.Newest,
  });

  const accounts = useMemo(
    () =>
      (data || [])
        .filter(({ id }) => id !== account.id)
        .sort(a => {
          if (account.type === TradingAccountType.Demo) {
            return a.type === TradingAccountType.Demo ? -1 : 1;
          } else {
            return a.type === TradingAccountType.Real ? -1 : 1;
          }
        }),
    [data, account.id, account.type],
  );

  const hasOrders = openOrders.length > 0;

  return (
    <Dialog>
      <Popover.Root modal open={opened} onOpenChange={onOpenChange}>
        <Popover.Trigger asChild>
          <button
            className={cn(
              "inline-flex gap-3 rounded-[12px] px-2 py-2 outline-none transition-colors hover:bg-card-bg-hover data-[state=open]:bg-card-bg-active lg:py-1.5",
              hasOrders && "lg:-my-0.5",
              centered && "items-center",
            )}
          >
            <AccountBadge accountType={account.type!} />
            <div className="flex flex-col gap-1 lg:gap-0.5">
              <div className="flex items-center gap-1">
                <div className="line-clamp-1 text-start font-gilroy text-[18px] font-semibold leading-[1.2] text-contrast-primary lg:line-clamp-none lg:whitespace-nowrap">
                  <PositiveNumberFormat
                    currency={account.currency!}
                    value={account.equity}
                    decimalScale={account.digits}
                  />
                </div>
                {/* FIXME: */}
                <svg
                  className="shrink-0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.46967 5.42084C3.17678 5.71373 3.17678 6.18861 3.46967 6.4815L7.46967 10.4815C7.76256 10.7744 8.23744 10.7744 8.53033 10.4815L12.5303 6.4815C12.8232 6.18861 12.8232 5.71374 12.5303 5.42084C12.2374 5.12795 11.7626 5.12795 11.4697 5.42084L8 8.89051L4.53033 5.42084C4.23744 5.12795 3.76256 5.12795 3.46967 5.42084Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              {hasOrders && (
                <div className={cn("flex font-roboto text-[14px] leading-normal", getNumberColorClassname(pnl))}>
                  <PnlFormat value={pnl} decimalScale={currencyDecimalScale} currency={currency} />
                </div>
              )}
            </div>
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            align={isMobile ? "center" : "end"}
            collisionPadding={15}
            className={cn(
              popoverContentStyles(),
              "max-h-[--radix-popover-content-available-height] w-[min(360px,_90vw)] overflow-y-auto rounded-[16px] bg-surface-elevation-1 shadow outline-none scrollbar-hide",
            )}
            sideOffset={8}
          >
            <AccountsSelectPopoverContent
              onClose={closePopover}
              accounts={accounts}
              currentAccount={account}
              setAccount={setAccount}
              showBonus={showBonus}
              bonus={bonus}
            />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      {bonus && <BonusDialog bonus={bonus} />}
    </Dialog>
  );
};

AccountSelect.displayName = "TerminalAccountSelect";

export { AccountSelect };
