import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import {
  DayOfWeek,
  TerminalAccountSymbols,
  TerminalSymbol,
  TerminalSymbolSession,
  TradingServerSymbolType,
} from "@/services/openapi";
import { TerminalPrice } from "@/services/openapi/models/terminal-price";

import { getDecimalScaleFromTickSize } from "./formatting";
import { calculatePipSize } from "./formulas";

dayjs.extend(duration);

export const getInstrumentType = (type: TradingServerSymbolType): "forex" | "cfd" => {
  if (type === TradingServerSymbolType.Forex || type === TradingServerSymbolType.ForexNoLeverage) {
    return "forex";
  }

  return "cfd";
};

export const normalizeSymbolsList = (list: TerminalSymbol[]) => {
  const initObject: SymbolsListType = {};
  const newList = JSON.parse(JSON.stringify(list)) as TerminalSymbol[];
  newList.forEach(
    item =>
      (initObject[item.symbol!] = {
        ...item,
        volumeDecimalScale: getDecimalScaleFromTickSize(item.volumeStep!),
        pipSize: calculatePipSize({ priceDecimalScale: item.digits! }),
        priceDecimalScale: item.digits!,
      }),
  );
  return initObject;
};

export type SymbolsListType = Record<string, MergedTerminalSymbol>;

export type MergedTerminalSymbol = TerminalSymbol &
  TerminalPrice & {
    pipSize: number;
    priceDecimalScale: number;
    volumeDecimalScale: number;
    isFavorite?: boolean;
    isChartFavorite?: boolean;
    eventTime?: number;
    isExcluded?: boolean;
  };

export enum StaticSymbolGroup {
  FAVORITES = "favorites",
  RECENTS = "recents",
  POPULAR = "popular",
  ALL = "all",
}

export type SymbolGroupType = string | StaticSymbolGroup;

const filterExcluded = (symbol: MergedTerminalSymbol): boolean => {
  return !symbol.isExcluded;
};

const filterFavorites = (symbol: MergedTerminalSymbol): boolean => {
  return filterExcluded(symbol) && !!symbol.isFavorite;
};

const filterGroup =
  (group: SymbolGroupType) =>
  (symbol: MergedTerminalSymbol): boolean => {
    return filterExcluded(symbol) && symbol.group === group;
  };

const filterRecents = (symbol: MergedTerminalSymbol, recent: string[]): boolean => {
  return filterExcluded(symbol) && recent.includes(symbol.symbol!);
};

const filterPopular = (symbol: MergedTerminalSymbol, popular: string[]): boolean => {
  return filterExcluded(symbol) && popular.includes(symbol.symbol!);
};

export const getSymbolsFilteringFunction = ({
  group,
  popular,
  recent,
  hasSearch,
}: {
  group: SymbolGroupType;
  popular: string[];
  recent: string[];
  hasSearch: boolean;
}): ((symbol: MergedTerminalSymbol) => boolean) => {
  if (hasSearch) {
    return () => true;
  }
  if (group === StaticSymbolGroup.FAVORITES) {
    return filterFavorites;
  }
  if (group === StaticSymbolGroup.ALL) {
    return symbol => filterExcluded(symbol);
  }
  if (group === StaticSymbolGroup.RECENTS) {
    return symbol => filterRecents(symbol, recent);
  }
  if (group === StaticSymbolGroup.POPULAR) {
    return symbol => filterPopular(symbol, popular);
  }
  return filterGroup(group);
};

const createSymbolsCompareFn = (
  { symbol: symbolFirst }: MergedTerminalSymbol,
  { symbol: symbolSecond }: MergedTerminalSymbol,
  sortArray: string[],
): number => {
  return sortArray.indexOf(symbolFirst!) > sortArray.indexOf(symbolSecond!) ? 1 : -1;
};

export const sortWatchlistSymbols = (
  symbols: MergedTerminalSymbol[],
  group: SymbolGroupType,
  favoriteData: TerminalAccountSymbols,
): MergedTerminalSymbol[] => {
  if (group === StaticSymbolGroup.RECENTS) {
    return symbols.toSorted((a, b) => createSymbolsCompareFn(a, b, favoriteData.recent!));
  }
  if (group === StaticSymbolGroup.POPULAR) {
    return symbols.toSorted((a, b) => createSymbolsCompareFn(a, b, favoriteData.popular!));
  }

  return symbols;
};

export const filterSymbolsSearch =
  (query: string) =>
  ({ symbol, description }: MergedTerminalSymbol): boolean => {
    if (!query) return true;
    return (
      symbol!.toLowerCase().includes(query.toLowerCase()) || description!.toLowerCase().includes(query.toLowerCase())
    );
  };

const DayOfWeekMap: Record<DayOfWeek, number> = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

export const isSymbolAvailable = (sessions: TerminalSymbolSession[]): { available: boolean; remainingTime?: Date } => {
  const getUtcDate = (hour: number, minute: number, second: number) =>
    dayjs().utc().hour(hour).minute(minute).second(second);

  const currentDate = dayjs().utc();
  const dayName = currentDate.format("dddd") as DayOfWeek;

  // NOTE - In day, we have many sessions & we need take current session
  // monday - close: 21:55
  // monday - close: 23:59 <-- current time 23:55
  // isBefore has added 1 second because
  // 23:59:59.isBefore(23:59:59) = false
  // 23:59:59.isBefore(23:59:59.add(1, "second")) = true
  let currentSession: TerminalSymbolSession = {};
  for (let i = 0; i < sessions.length; i += 1) {
    if (sessions[i].dayOfWeek === dayName) {
      const { closeHours, closeMinutes, closeSeconds } = sessions[i];

      if (currentDate.isBefore(getUtcDate(closeHours!, closeMinutes!, closeSeconds!).add(1, "second"))) {
        currentSession = sessions[i];
        break;
      }

      if (i >= sessions.length - 1) {
        if (sessions[0].dayOfWeek !== dayName) {
          currentSession = sessions[0];
          break;
        }
      } else if (sessions[i + 1]?.dayOfWeek !== dayName) {
        currentSession = sessions[i + 1];
        break;
      }
    }
  }
  if (!currentSession.dayOfWeek) {
    const currentDayNum = currentDate.day();
    for (const [index, { dayOfWeek }] of sessions.entries()) {
      if (dayOfWeek) {
        if (DayOfWeekMap[dayOfWeek] > currentDayNum) {
          currentSession = sessions[index];
          break;
        }
        if (index === sessions.length - 1) {
          currentSession = sessions[0];
          break;
        }
      }
    }
  }

  const sessionDiffCurrentDays =
    DayOfWeekMap[currentSession.dayOfWeek!] === currentDate.day()
      ? 0
      : DayOfWeekMap[currentSession.dayOfWeek!] > currentDate.day()
        ? dayjs().day(DayOfWeekMap[currentSession.dayOfWeek!]).utc().diff(currentDate, "day")
        : dayjs().day(DayOfWeekMap[currentSession.dayOfWeek!]).utc().add(1, "week").diff(currentDate, "day");
  const { closeHours, closeSeconds, closeMinutes, openHours, openSeconds, openMinutes } = currentSession;

  const openDate = getUtcDate(openHours!, openMinutes!, openSeconds!).add(sessionDiffCurrentDays, "day");
  const closeDate = getUtcDate(closeHours!, closeMinutes!, closeSeconds!).add(sessionDiffCurrentDays, "day");
  // "[)" in isBetween means inclusive openDate
  // 00:00:00.isBetween(00:00:00, 21:55:00) = false
  // 00:00:00.isBetween(00:00:00, 21:55:00, null, "[)") = true
  const available = currentDate.isBetween(openDate, closeDate, null, "[)");

  if (available) return { available };

  return {
    available,
    remainingTime: openDate.toDate(),
  };
};
