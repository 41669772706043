import { forwardRef } from "react";

import { css, darkThemeSelector, keyframes } from "@/styles";

import { Text } from "../text";

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

export const overlayStyles = css({
  backgroundColor: "rgba(0, 0, 0, .4)",
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,

  [darkThemeSelector]: {
    backgroundColor: "rgba(0, 0, 0, .15)",
  },
});

export const panelStyles = css({
  backgroundColor: "$lite",
  boxShadow: "0px 10px 40px -20px rgba(0, 0, 0, 0.1)",

  "@bp2": {
    borderRadius: "24px",
  },
});

type TitleProps = React.ComponentProps<typeof Text> & { as?: any };
export const DialogTitleText = forwardRef<React.ElementRef<typeof Text>, TitleProps>(
  ({ children, as = "h2", size = 6, ...restProps }, ref) => {
    return (
      <Text as={as} weight="bold" size={size} align="center" lineHeight="4" {...restProps} ref={ref}>
        {children}
      </Text>
    );
  },
);
DialogTitleText.displayName = "DialogTitleText";

type DescriptionProps = React.ComponentProps<typeof Text> & { as?: any };
export const DialogDescriptionText = forwardRef<React.ElementRef<typeof Text>, DescriptionProps>(
  ({ children, as = "p", ...restProps }, ref) => {
    return (
      <Text as={as} family="roboto" color="dimmed" align="center" {...restProps} ref={ref}>
        {children}
      </Text>
    );
  },
);
DialogDescriptionText.displayName = "DialogDescriptionText";
