import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { TCWidgetColors } from "../../helpers/colors";
import { getTCWidgetLanguage } from "../../helpers/languages";
import { WidgetBlock } from "../../widgets/block";

type Props = {
  token: string;
  language: string;
  instrument: string;
  colors: TCWidgetColors;
};

const NewsVolumeWidget: FC<Props> = ({ colors, instrument, language, token }) => {
  const { t } = useTranslation();

  const { backgroundColor, baseColor, bearishColor, bullishColor, neutralColor, primaryColor, surfaceColor } = colors;

  return (
    <WidgetBlock
      title={t("terminal.insights.widgets.news-volume.title")}
      description={t("terminal.insights.widgets.news-volume.description")!}
    >
      <tc-news-volume
        token={token}
        lang={getTCWidgetLanguage(language)}
        instrument={instrument}
        base-color={baseColor}
        background-color={backgroundColor}
        surface-color={surfaceColor}
        primary-color={primaryColor}
        bearish-color={bearishColor}
        bullish-color={bullishColor}
        neutral-color={neutralColor}
      />
    </WidgetBlock>
  );
};

export { NewsVolumeWidget };
