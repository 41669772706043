import Big from "big.js";
import { TFunction } from "i18next";
import { InfiniteData } from "react-query";

import { NumberFormat } from "@/app/components";
import { getNumberColorClassname } from "@/app/ui/colors";
import {
  TerminalDealEventType,
  TerminalDealType,
  TerminalDealUpdateType,
  TerminalEventDeal,
  TerminalOrder,
  TerminalOrderItemsContainer,
  TradingServerSymbolType,
} from "@/services/openapi";
import { Toast, toast, ToastIcons } from "@/shared/ui";

import { getBaseSymbol, getQuoteSymbol } from "../helpers";
import { TerminalTableState, useLayoutContext } from "../layout/context";
import { SymbolIcon } from "../symbols/icon";
import { getOrderTypeText } from "../trading-tables/components/trading-table/order-type";
import { getOrderCurrentPrice } from "../trading-tables/trading-tables.helpers";
import { getInstrumentType, MergedTerminalSymbol } from "./symbols";

export type LiveOrder = {
  id: number;
  symbol: string;
  type: TerminalDealType;
  swap: number;
  volume: number;
  price: number;
  event: TerminalDealEventType;
  date: string;
  isUpdate: boolean;
  marginRate: number;
  stopLoss?: number;
  takeProfit?: number;
  updateVolume?: number;
  updatePrice?: number;
  updateProfit?: number | null;
  updateType?: TerminalDealType;
  updateAction?: TerminalDealUpdateType;
  updatePosition?: number;
};

export const getClosedOrderById = ({
  closedOrdersWrapper,
  orderId,
}: {
  orderId: number;
  closedOrdersWrapper: InfiniteData<TerminalOrderItemsContainer>;
}): TerminalOrder | undefined => {
  let neededOrder: TerminalOrder | undefined;

  const closedOrders = closedOrdersWrapper.pages[0].items;

  if (closedOrders) {
    closedOrders.forEach(order => {
      if (order.position === orderId) {
        neededOrder = order;
        return;
      }
    });

    return neededOrder;
  }
};

export const transformTerminalOrder = ({ d, e, p, s, sl, sw, t, tp, v, dt, u, rm }: TerminalEventDeal): LiveOrder => ({
  marginRate: rm!,
  id: d!,
  symbol: s!,
  date: dt!,
  type: t!,
  swap: sw!,
  volume: v!,
  price: p!,
  stopLoss: sl!,
  takeProfit: tp!,
  event: e!,
  updatePosition: u?.i,
  updateProfit: u?.r,
  updateAction: u?.u,
  updatePrice: u?.p,
  updateType: u?.t,
  updateVolume: u?.v,
  isUpdate: !!u,
});

export const normalizeOrdersList = (list: TerminalEventDeal[]) => {
  const initObject: { [key: string]: LiveOrder } = {};
  list.forEach(item => (initObject[item.d!] = transformTerminalOrder(item)));
  return initObject;
};

export const isPendingOrder = (type: TerminalDealType): boolean => {
  switch (type) {
    case TerminalDealType.BuyStop:
    case TerminalDealType.SellStop:
    case TerminalDealType.BuyLimit:
    case TerminalDealType.SellLimit:
      return true;
    default:
      return false;
  }
};

export const isMarketOrder = (type: TerminalDealType): boolean => {
  switch (type) {
    case TerminalDealType.Buy:
    case TerminalDealType.Sell:
      return true;
    default:
      return false;
  }
};

export const isBuyOrder = (type: TerminalDealType): boolean => {
  switch (type) {
    case TerminalDealType.Buy:
    case TerminalDealType.BuyLimit:
    case TerminalDealType.BuyStop:
    case TerminalDealType.BuyStopLimit:
      return true;
    default:
      return false;
  }
};

export const isSellOrder = (type: TerminalDealType): boolean => {
  switch (type) {
    case TerminalDealType.Sell:
    case TerminalDealType.SellLimit:
    case TerminalDealType.SellStop:
    case TerminalDealType.SellStopLimit:
      return true;
    default:
      return false;
  }
};

export const majorCurrencies = ["USD", "GBP", "EUR", "JPY"];

// TODO: naming
const calculate = ({
  symbols,
  baseResult,
  quoteCurrency,
  type,
  currency,
}: {
  symbols: MergedTerminalSymbol[];
  quoteCurrency: string;
  baseResult: Big;
  type: TerminalDealType;
  currency: string;
}) => {
  const baseSymbol = getBaseSymbol({
    symbols,
    baseCurrencyPredicate: currency,
    quoteCurrencyPredicate: quoteCurrency,
  });

  if (baseSymbol) {
    if (!baseSymbol.ask || !baseSymbol.bid) {
      return 0;
    }
    const baseCurrentPrice = getOrderCurrentPrice({ type, ask: baseSymbol.ask, bid: baseSymbol.bid })!;
    return baseResult.div(baseCurrentPrice).toNumber();
  }

  const quoteSymbol = getQuoteSymbol({
    symbols,
    baseCurrencyPredicate: quoteCurrency,
    quoteCurrencyPredicate: currency,
  });

  if (quoteSymbol) {
    if (!quoteSymbol.ask || !quoteSymbol.bid) {
      return 0;
    }
    const quoteCurrentPrice = getOrderCurrentPrice({
      type,
      ask: quoteSymbol.ask,
      bid: quoteSymbol.bid,
      inverse: true,
    })!;
    return baseResult.mul(quoteCurrentPrice).toNumber();
  }

  return null;
};

export const countProfitAndLoss = ({
  contractSize,
  currentPrice,
  openPrice,
  type,
  volume,
  accountCurrency,
  baseCurrency,
  quoteCurrency,
  symbols,
}: {
  symbols: MergedTerminalSymbol[];
  type: TerminalDealType;
  openPrice: number;
  accountCurrency: string;
  volume: number | undefined;
  currentPrice: number | undefined;
  contractSize: number | undefined;
  baseCurrency: string | undefined;
  quoteCurrency: string | undefined;
}) => {
  if (!volume || !currentPrice || !contractSize || !baseCurrency || !quoteCurrency) {
    return 0;
  }

  const direction = isBuyOrder(type) ? 1 : -1;
  const baseResult = new Big(currentPrice).minus(openPrice).mul(volume).mul(contractSize).mul(direction);

  // first case: Quote Currency = Account currency
  if (quoteCurrency === accountCurrency) {
    return baseResult.toNumber();
  }

  // second case: Base Currency = Account currency
  if (baseCurrency === accountCurrency) {
    if (!currentPrice) {
      return 0;
    }
    return baseResult.div(currentPrice).toNumber();
  }

  // third case: Base Currency or Quote Currency !== Account currency
  const result = calculate({ baseResult, currency: accountCurrency, quoteCurrency, symbols, type });

  if (result) {
    return result;
  }

  // fourth case:
  for (const currency of majorCurrencies) {
    const baseSymbol = getBaseSymbol({
      symbols,
      baseCurrencyPredicate: accountCurrency,
      quoteCurrencyPredicate: currency,
    });

    const quoteSymbol = getQuoteSymbol({
      symbols,
      baseCurrencyPredicate: currency,
      quoteCurrencyPredicate: accountCurrency,
    });

    if (!baseSymbol && !quoteSymbol) {
      continue;
    }

    const result = calculate({ baseResult, currency, quoteCurrency, symbols, type });
    if (result) {
      if (baseSymbol) {
        if (!baseSymbol.ask || !baseSymbol.bid) {
          return 0;
        }
        const baseCurrentPrice = getOrderCurrentPrice({
          type,
          ask: baseSymbol.ask,
          bid: baseSymbol.bid,
          inverse: true,
        })!;

        return new Big(result).div(baseCurrentPrice).toNumber();
      }

      if (quoteSymbol) {
        if (!quoteSymbol.ask || !quoteSymbol.bid) {
          return 0;
        }
        const quoteCurrentPrice = getOrderCurrentPrice({
          type,
          ask: quoteSymbol.ask,
          bid: quoteSymbol.bid,
          inverse: true,
        })!;

        return new Big(result).mul(quoteCurrentPrice).toNumber();
      }
    }
  }

  return 0;
};

// TODO: naming
const calculateMar = ({
  symbols,
  volume,
  baseCurrency,
  type,
  contractSize,
  leverage,
  currency,
  marginRate,
  finalMultiplier,
}: {
  symbols: MergedTerminalSymbol[];
  baseCurrency: string;
  volume: number;
  contractSize: number;
  type: TerminalDealType;
  currency: string;
  leverage: number;
  marginRate: number;
  finalMultiplier: number;
}) => {
  const baseSymbol = getBaseSymbol({
    symbols,
    baseCurrencyPredicate: currency,
    quoteCurrencyPredicate: baseCurrency,
  });

  if (baseSymbol) {
    if (!baseSymbol.ask || !baseSymbol.bid) {
      return 0;
    }
    const baseCurrentPrice = getOrderCurrentPrice({ type, ask: baseSymbol.ask, bid: baseSymbol.bid })!;
    return new Big(volume)
      .mul(contractSize)
      .div(baseCurrentPrice)
      .div(leverage)
      .mul(marginRate)
      .mul(finalMultiplier)
      .toNumber();
  }

  const quoteSymbol = getQuoteSymbol({
    symbols,
    baseCurrencyPredicate: baseCurrency,
    quoteCurrencyPredicate: currency,
  });

  if (quoteSymbol) {
    if (!quoteSymbol.ask || !quoteSymbol.bid) {
      return 0;
    }
    const quoteCurrentPrice = getOrderCurrentPrice({
      type,
      ask: quoteSymbol.ask,
      bid: quoteSymbol.bid,
      inverse: true,
    })!;
    return new Big(volume)
      .mul(contractSize)
      .mul(quoteCurrentPrice)
      .div(leverage)
      .mul(marginRate)
      .mul(finalMultiplier)
      .toNumber();
  }

  return null;
};

export const countMargin = ({
  type,
  symbols,
  contractSize,
  leverage,
  openPrice,
  volume,
  accountCurrency,
  baseCurrency,
  quoteCurrency,
  instrumentType,
  marginRate: marginRateType,
  marginRateInitialMarketBuy,
  marginRateInitialMarketSell,
  marginRateMaintenanceMarketBuy,
  marginRateMaintenanceMarketSell,
}: {
  symbols: MergedTerminalSymbol[];
  type: TerminalDealType;
  openPrice: number;
  volume: number;
  contractSize: number;
  leverage: number;
  accountCurrency: string;
  baseCurrency: string;
  quoteCurrency: string;
  instrumentType: TradingServerSymbolType;
  marginRate: "initial" | "maintenance";
  marginRateInitialMarketBuy: number;
  marginRateInitialMarketSell: number;
  marginRateMaintenanceMarketBuy: number;
  marginRateMaintenanceMarketSell: number;
}) => {
  const isForex = getInstrumentType(instrumentType) === "forex";

  const marginRate = getMarginRate({
    marginRate: marginRateType,
    marginRateInitialMarketBuy,
    marginRateInitialMarketSell,
    marginRateMaintenanceMarketBuy,
    marginRateMaintenanceMarketSell,
    type,
  });

  const finalMultiplier = isForex ? 1 : openPrice;

  return countBaseMargin({
    type,
    symbols,
    contractSize,
    leverage: isForex ? leverage : 1,
    openPrice,
    volume,
    accountCurrency,
    baseCurrency,
    quoteCurrency,
    marginRate,
    finalMultiplier,
  });
};

const getMarginRate = ({
  marginRate,
  marginRateInitialMarketBuy,
  marginRateInitialMarketSell,
  marginRateMaintenanceMarketBuy,
  marginRateMaintenanceMarketSell,
  type,
}: {
  type: TerminalDealType;
  marginRate: "initial" | "maintenance";
  marginRateInitialMarketBuy: number;
  marginRateInitialMarketSell: number;
  marginRateMaintenanceMarketBuy: number;
  marginRateMaintenanceMarketSell: number;
}) => {
  if (type === TerminalDealType.Buy) {
    if (marginRate === "initial") {
      return marginRateInitialMarketBuy;
    } else {
      return marginRateMaintenanceMarketBuy;
    }
  } else {
    if (marginRate === "initial") {
      return marginRateInitialMarketSell;
    } else {
      return marginRateMaintenanceMarketSell;
    }
  }
};

const countMarginWithRate = ({
  type,
  contractSize,
  leverage,
  openPrice,
  volume,
  instrumentType,
  marginRate,
  marginRateType,
  marginRateInitialMarketBuy,
  marginRateInitialMarketSell,
  marginRateMaintenanceMarketBuy,
  marginRateMaintenanceMarketSell,
}: {
  type: TerminalDealType;
  openPrice: number;
  volume: number;
  contractSize: number;
  leverage: number;
  instrumentType: TradingServerSymbolType;
  marginRate: number;
  marginRateType: "initial" | "maintenance";
  marginRateInitialMarketBuy: number;
  marginRateInitialMarketSell: number;
  marginRateMaintenanceMarketBuy: number;
  marginRateMaintenanceMarketSell: number;
}) => {
  const isForex = getInstrumentType(instrumentType) === "forex";

  const marginCoeff = getMarginRate({
    marginRate: marginRateType,
    marginRateInitialMarketBuy,
    marginRateInitialMarketSell,
    marginRateMaintenanceMarketBuy,
    marginRateMaintenanceMarketSell,
    type,
  });

  if (isForex) {
    return new Big(volume).mul(contractSize).div(leverage).mul(marginCoeff).mul(marginRate).toNumber();
  }

  return new Big(volume).mul(contractSize).mul(openPrice).mul(marginCoeff).mul(marginRate).toNumber();
};

export const countBaseMargin = ({
  type,
  symbols,
  contractSize,
  leverage,
  openPrice,
  volume,
  accountCurrency,
  baseCurrency,
  quoteCurrency,
  marginRate,
  finalMultiplier,
}: {
  symbols: MergedTerminalSymbol[];
  type: TerminalDealType;
  openPrice: number;
  volume: number;
  contractSize: number;
  leverage: number;
  accountCurrency: string;
  baseCurrency: string;
  quoteCurrency: string;
  marginRate: number;
  // TODO: naming
  finalMultiplier: number;
}): number => {
  if (baseCurrency === accountCurrency) {
    return new Big(volume).mul(contractSize).div(leverage).mul(marginRate).mul(finalMultiplier).toNumber();
  }

  if (quoteCurrency === accountCurrency) {
    return new Big(volume)
      .mul(contractSize)
      .mul(openPrice)
      .div(leverage)
      .mul(marginRate)
      .mul(finalMultiplier)
      .toNumber();
  }

  const result = calculateMar({
    baseCurrency,
    contractSize,
    currency: accountCurrency,
    finalMultiplier,
    leverage,
    marginRate,
    symbols,
    type,
    volume,
  });

  if (result) {
    return result;
  }

  for (const currency of majorCurrencies) {
    const baseSymbol = getBaseSymbol({
      symbols,
      baseCurrencyPredicate: accountCurrency,
      quoteCurrencyPredicate: currency,
    });

    const quoteSymbol = getQuoteSymbol({
      symbols,
      baseCurrencyPredicate: currency,
      quoteCurrencyPredicate: accountCurrency,
    });

    if (!baseSymbol && !quoteSymbol) {
      continue;
    }

    const result = calculateMar({
      baseCurrency,
      contractSize,
      currency,
      finalMultiplier,
      leverage,
      marginRate,
      symbols,
      type,
      volume,
    });
    if (result) {
      if (baseSymbol) {
        if (!baseSymbol.ask || !baseSymbol.bid) {
          return 0;
        }
        const baseCurrentPrice = getOrderCurrentPrice({
          type,
          ask: baseSymbol.ask,
          bid: baseSymbol.bid,
          inverse: true,
        })!;

        return new Big(result).div(baseCurrentPrice).toNumber();
      }

      if (quoteSymbol) {
        if (!quoteSymbol.ask || !quoteSymbol.bid) {
          return 0;
        }
        const quoteCurrentPrice = getOrderCurrentPrice({
          type,
          ask: quoteSymbol.ask,
          bid: quoteSymbol.bid,
          inverse: true,
        })!;

        return new Big(result).mul(quoteCurrentPrice).toNumber();
      }
    }
  }

  return 0;
};

export const updateOrdersList = ({
  t,
  newOrder,
  currency,
  list,
  symbols,
  currencyDecimalScale,
  changeTable,
}: {
  t: TFunction;
  newOrder: LiveOrder;
  currency: string;
  currencyDecimalScale: number;
  list: {
    [key: string]: LiveOrder;
  };
  symbols: MergedTerminalSymbol[];
  changeTable: ReturnType<typeof useLayoutContext>["changeTable"];
}): {
  [key: string]: LiveOrder;
} => {
  const updatedList: typeof list = {};
  for (const key in list) {
    updatedList[key] = { ...list[key] };
  }

  const oldOrder: LiveOrder = updatedList[newOrder.id];

  const isMarket = isMarketOrder(newOrder.type);

  const { volumeDecimalScale } = symbols.find(({ symbol }) => symbol === newOrder.symbol) || {};

  const marketMessageInfo = {
    id: newOrder.id,
    type: getOrderTypeText(t, newOrder.updateType!),
    volume: newOrder.updateVolume?.toFixed(volumeDecimalScale),
    symbol: newOrder.symbol,
    position: newOrder.updatePosition,
  };

  const pendingMessageInfo = {
    id: newOrder.id,
    type: getOrderTypeText(t, newOrder.type),
    volume: newOrder.volume.toFixed(volumeDecimalScale),
    symbol: newOrder.symbol,
  };

  if (isMarket) {
    if (newOrder.event === TerminalDealEventType.Delete) {
      delete updatedList[newOrder.id];
      if (newOrder.updateAction === TerminalDealUpdateType.Close) {
        toast({
          icon: <SymbolIcon size="md" symbol={marketMessageInfo.symbol} />,
          title: t("terminal.messages.market-order-closed", { symbol: marketMessageInfo.symbol })!,
          description: (
            <span className={getNumberColorClassname(newOrder.updateProfit!)}>
              <NumberFormat value={newOrder.updateProfit} decimalScale={currencyDecimalScale} currency={currency} />
            </span>
          ),
          action: (
            <Toast.Action
              altText={t("terminal.messages.market-order-closed-button")}
              onClick={() => {
                changeTable(TerminalTableState.CLOSED_POSITIONS);
              }}
            >
              {t("terminal.messages.market-order-closed-button")}
            </Toast.Action>
          ),
        });
      }
      return updatedList;
    }

    updatedList[newOrder.id] = {
      ...oldOrder,
      ...newOrder,
    };

    if (newOrder.isUpdate) {
      if (oldOrder && oldOrder.type !== newOrder.updateType && newOrder.updateType !== newOrder.type) {
        toast({
          text: t("terminal.messages.market-order-partially-closed", marketMessageInfo),
          icon: <SymbolIcon size="md" symbol={marketMessageInfo.symbol} />,
          action: (
            <Toast.Action
              altText={t("terminal.messages.market-order-partially-closed-button")}
              onClick={() => {
                changeTable(TerminalTableState.CLOSED_POSITIONS);
              }}
            >
              {t("terminal.messages.market-order-partially-closed-button")}
            </Toast.Action>
          ),
        });
      } else {
        toast({
          title: t("terminal.messages.market-order-opened", marketMessageInfo)!,
          description: <>#{marketMessageInfo.id}</>,
          icon: <SymbolIcon size="md" symbol={marketMessageInfo.symbol} />,
          action: (
            <Toast.Action
              altText={t("terminal.messages.market-order-opened-button")}
              onClick={() => {
                changeTable(TerminalTableState.OPEN);
              }}
            >
              {t("terminal.messages.market-order-opened-button")}
            </Toast.Action>
          ),
        });
      }
    }
    return updatedList;
  }

  if (!isMarket) {
    if (newOrder.event === TerminalDealEventType.Delete) {
      const oldPending = isPendingOrder(oldOrder?.type);
      const newPending = isPendingOrder(newOrder.type);
      if (oldPending && newPending) {
        delete updatedList[newOrder.id];
        return updatedList;
      } else {
        return updatedList;
      }
    }
    if (oldOrder) {
      toast({ text: t("terminal.messages.pending-order-modified", pendingMessageInfo), icon: ToastIcons.SUCCESS });
    } else {
      toast({
        text: t("terminal.messages.pending-order-placed", pendingMessageInfo),
        icon: ToastIcons.SUCCESS,
        action: (
          <Toast.Action
            altText={t("terminal.messages.pending-order-placed-button")}
            onClick={() => {
              changeTable(TerminalTableState.PENDING);
            }}
          >
            {t("terminal.messages.pending-order-placed-button")}
          </Toast.Action>
        ),
      });
    }
    updatedList[newOrder.id] = {
      ...oldOrder,
      ...newOrder,
    };
  }

  return updatedList;
};

export { countMarginWithRate };
