import { Flex } from "@/components/flex";
import { styled } from "@/styles";

export const Wrapper = styled("div", {
  display: "grid",
  alignItems: "flex-start",
  "@bp2": {
    px: "16px",
  },

  variants: {
    hasImage: {
      true: {
        "@bp3": {
          gridTemplateColumns: "60% 1fr",
        },
      },
      false: {
        gridTemplateColumns: "1fr",
      },
    },
  },
});

export const Container = styled("main", {
  maxWidth: "100%",

  "@bp3": { maxWidth: "600px", px: "0px" },
});

export const ChildrenWrapper = styled("div", {
  "@bp2": {
    m: "25px 0",
  },
});

export const Head = styled(Flex, {
  position: "relative",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "0 -24px",
  padding: "0 24px",
  paddingBottom: "0px",
  borderBottom: "1px solid transparent",
  background: "transparent",
  transition: "all 0.5s cubic-bezier(0.22, 1, 0.36, 1)",
  willChange: "transform",

  h1: {
    margin: 0,
    transition: "all 0.5s cubic-bezier(0.22, 1, 0.36, 1)",
  },

  "@bp2": { margin: "0", padding: "0", borderBottom: "none" },

  variants: {
    sticky: {
      true: {
        position: "sticky",
        top: "0px",
        paddingBottom: "9px",

        backgroundColor: "$bgBack",
        borderBottom: "1px solid $bgBorder",

        h1: {
          margin: "0 auto",
          transform: "scale(0.6)",
        },

        "@bp2": {
          position: "relative",
          top: "initial",
          paddingBottom: "0",
          backgroundColor: "transparent",
          borderBottom: "none",

          h1: {
            transform: "none",
          },
        },
      },
      false: {
        "@bp2": {
          h1: {
            transform: "none",
          },
        },
      },
    },
  },
});
