import { useEffect } from "react";
import { useNavigate, useRouteError } from "react-router-dom";

import image404 from "@/assets/images/404.png";
import image500 from "@/assets/images/500.png";
import { Box } from "@/components/box";
import { Button } from "@/components/button";
import { Footer } from "@/components/footer";
import { Grid } from "@/components/grid";
import { Header } from "@/components/header";
import { Text } from "@/components/text";
import { useMarketingCookies } from "@/hooks/marketing-cookies.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { darkTheme, styled } from "@/styles";

const Wrapper = styled(Grid, {
  minHeight: "100%",
  gridTemplateRows: "auto 1fr auto",
  background: "$white",
});

const Content = styled("main", {
  py: "25px",
  maxWidth: "650px",
  px: "$containerXPadding",

  m: "0 auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",

  "@bp3": {
    py: "50px",
  },
});

const Image = styled("img", {
  width: "178px",
  height: "76px",
  mx: "auto",
  mb: "40px",

  "@bp3": {
    width: "auto",
    height: "auto",
    mb: "56px",
  },
});

export const ErrorPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useMarketingCookies();

  const error = useRouteError() as any;

  const is404 = error.status === 404;
  const is500 = error.status === 500;

  const errorMessage = is404 ? t("error.not-found-message") : t("error.went-wrong");

  useEffect(() => {
    document.body.classList.remove(darkTheme.className);
  }, []);

  return (
    <Wrapper>
      <Header />
      <Content>
        {is404 && <Image src={image404} alt="not found" />}
        {is500 && <Image src={image500} alt="server error" />}
        <Text
          weight="bold"
          align="center"
          lineHeight={{ "@initial": "4", "@bp3": "6" }}
          css={{
            fontSize: "26px",
            mb: "20px",
            "@bp3": { fontSize: "56px", mb: "40px" },
          }}
        >
          {errorMessage}
        </Text>
        <Box css={{ mx: "auto" }}>
          {is404 ? (
            <Button variant="black" onClick={() => navigate(-1)}>
              {t("button.go-back")}
            </Button>
          ) : (
            <Button variant="black" onClick={() => window.location.reload()}>
              {t("button.reload")}
            </Button>
          )}
        </Box>
      </Content>
      <Footer />
    </Wrapper>
  );
};
