import * as Collapsible from "@radix-ui/react-collapsible";
import { type FC, memo, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Logo } from "@/app/components";
import { toggleLiveChat } from "@/app/libs/liveChat";
import {
  IconArrowTransfer,
  IconChartUp,
  IconChatHelp,
  IconChevronDown,
  IconCollection,
  IconDeposit,
  IconNotification,
  IconNotificationNew,
  IconProcessing,
  IconUser,
  IconWallet,
  IconWithdraw,
} from "@/domains/icons";
import { LanguageSwitcherContainer } from "@/features/language-switcher/container";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useHover } from "@/hooks/hover.hook";
import { useTerminalAutoRoute } from "@/hooks/terminal-auto-route.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { isCurrentPageTerminal } from "@/routes/terminal.routes";
import { cn } from "@/shared/styles";
import { useIsReadOnly, useProfileData } from "@/state/server/profile/profile.hooks";
import { styled } from "@/styles";

import { SidebarNavItem } from "./nav-item";

const sidebarWidthExpanded = 252;
const sidebarWidthCollapsed = 72;

const Wrapper = styled("div", {
  background: "$elevation3",
  height: "100%",
  overflowY: "auto",
  p: "8px",
  display: "flex",
  flexDirection: "column",
  gap: 8,

  "@bp3": {
    background: "$elevation3",
    position: "fixed",
    top: 0,
    bottom: 0,
    overflowY: "auto",
    p: "8px",
    width: sidebarWidthExpanded,
    display: "flex",
    flexDirection: "column",
    elementTransition: "width",
    transitionDuration: "300ms",
    hideScroll: "true",
    zIndex: "10",
  },

  variants: {
    collapsed: {
      true: {
        width: sidebarWidthCollapsed,
      },
    },
  },
});

type Props = {
  onLanguageSelect: () => void;
  initiallyCollapsed?: boolean;
  onClose?: () => void;
  onDeposit?: () => void;
};

const _SidebarContent: FC<Props> = ({ onClose, initiallyCollapsed, onLanguageSelect, onDeposit }) => {
  const { t } = useTranslation();

  const profileData = useProfileData();

  const isReadOnly = useIsReadOnly();

  const [paymentMenuOpened, { toggle: togglePaymentMenu, open: openPaymentMenu }] = useDisclosure();

  const location = useLocation();
  const isCurrentPageInSubMenu = useMemo(
    () =>
      [
        cabinetRoutes.deposit,
        cabinetRoutes.withdrawal,
        cabinetRoutes.transfer,
        cabinetRoutes.transactionHistory,
      ].includes(location.pathname),
    [location.pathname],
  );
  const { isLoading, open } = useTerminalAutoRoute();

  const { ref, hovered } = useHover({ delay: 400 });

  const isCollapsed = !!initiallyCollapsed && !hovered;

  useEffect(() => {
    if (isCurrentPageInSubMenu) {
      openPaymentMenu();
    }
  }, [isCurrentPageInSubMenu]);

  return (
    <Wrapper collapsed={isCollapsed} ref={ref}>
      <div className="flex p-4">
        <Logo.Container className="text-accent-over">
          <Logo.Symbol />
          <Logo.Word className={cn("transition-opacity", isCollapsed && "opacity-0")} />
        </Logo.Container>
      </div>
      <nav className="flex grow flex-col justify-between">
        <div className="flex flex-col gap-0.5">
          <SidebarNavItem.Button
            onClick={open}
            active={useMemo(() => isCurrentPageTerminal(location.pathname), [location.pathname])}
            startSection={<IconChartUp />}
            data-test="navlink-terminal"
            isLoading={isLoading}
            isCollapsed={isCollapsed}
            title={t("cabinet.navigation.terminal")}
          />
          <SidebarNavItem.Link
            to={cabinetRoutes.dashboard}
            onClick={onClose}
            startSection={<IconCollection />}
            data-test="navlink-accounts"
            isCollapsed={isCollapsed}
            title={t("cabinet.navigation.accounts")}
          />
          <Collapsible.Root open={paymentMenuOpened && !isCollapsed}>
            <SidebarNavItem.Button
              onClick={togglePaymentMenu}
              startSection={<IconWallet />}
              data-test="navlink-payments"
              isCollapsed={isCollapsed}
              active={!paymentMenuOpened && isCurrentPageInSubMenu}
              endSection={
                <div
                  className={cn("ms-auto transition", isCollapsed && "opacity-0", paymentMenuOpened && "rotate-180")}
                >
                  <IconChevronDown />
                </div>
              }
              title={t("cabinet.navigation.payments")}
            />
            <Collapsible.Content className="collapsible">
              <div className="mt-0.5 flex flex-col gap-0.5 ps-5">
                {!isReadOnly && (
                  <SidebarNavItem.Link
                    to={cabinetRoutes.deposit}
                    onClick={() => {
                      onClose && onClose();
                      onDeposit && onDeposit();
                    }}
                    startSection={<IconDeposit />}
                    data-test="navlink-deposit"
                    isCollapsed={isCollapsed}
                    title={t("cabinet.navigation.deposit")}
                  />
                )}
                {!isReadOnly && (
                  <SidebarNavItem.Link
                    to={cabinetRoutes.withdrawal}
                    onClick={onClose}
                    startSection={<IconWithdraw />}
                    data-test="navlink-withdrawal"
                    isCollapsed={isCollapsed}
                    title={t("cabinet.navigation.withdrawal")}
                  />
                )}
                {!isReadOnly && (
                  <SidebarNavItem.Link
                    to={cabinetRoutes.transfer}
                    onClick={onClose}
                    startSection={<IconArrowTransfer />}
                    data-test="navlink-transfer"
                    isCollapsed={isCollapsed}
                    title={t("cabinet.navigation.transfer")}
                  />
                )}
                <SidebarNavItem.Link
                  to={cabinetRoutes.transactionHistory}
                  onClick={onClose}
                  startSection={<IconProcessing />}
                  data-test="navlink-transaction-history"
                  isCollapsed={isCollapsed}
                  title={t("cabinet.navigation.transaction-history")}
                />
              </div>
            </Collapsible.Content>
          </Collapsible.Root>
        </div>
        <div className="flex flex-col gap-0.5">
          <SidebarNavItem.Button
            onClick={toggleLiveChat}
            startSection={<IconChatHelp />}
            data-test="navlink-support"
            isCollapsed={isCollapsed}
            title={t("cabinet.navigation.support")}
          />
          <SidebarNavItem.Link
            to={cabinetRoutes.profileSettings}
            onClick={onClose}
            startSection={<IconUser />}
            data-test="navlink-profile-settings"
            isCollapsed={isCollapsed}
            title={t("cabinet.navigation.profile-settings")}
          />
          <SidebarNavItem.Link
            to={cabinetRoutes.notifications}
            onClick={onClose}
            startSection={profileData.notificationsCount! > 0 ? <IconNotificationNew /> : <IconNotification />}
            data-test="navlink-notifications"
            isCollapsed={isCollapsed}
            title={t("cabinet.navigation.notifications")}
          />

          <LanguageSwitcherContainer>
            {({ icon, name }) => (
              <SidebarNavItem.Button
                onClick={onLanguageSelect}
                startSection={<img src={icon!} alt={name!} />}
                data-test="language-switcher-button"
                isCollapsed={isCollapsed}
                title={name}
              />
            )}
          </LanguageSwitcherContainer>
        </div>
      </nav>
    </Wrapper>
  );
};

const SidebarContent = memo(_SidebarContent);

export { sidebarWidthExpanded, sidebarWidthCollapsed, SidebarContent };
