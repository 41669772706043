import { FC, useCallback } from "react";

import { TradingAccount } from "@/services/openapi";
import { useChangeAccountNameMutation } from "@/state/server/accounts";

import { AccountNameForm, IAccountNameFormValues } from "./account-name.form";

type Props = {
  account: TradingAccount;
  onClose: () => void;
};

export const AccountNameContainer: FC<Props> = ({ account, onClose }) => {
  const { mutateAsync: changeAccountName } = useChangeAccountNameMutation({ successMessage: "account-name-changed" });

  const handleSubmit = useCallback(
    ({ value }: IAccountNameFormValues) => {
      return changeAccountName(
        { id: account.id!, stringContainer: { value } },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    },
    [changeAccountName, account],
  );

  return <AccountNameForm onSubmit={handleSubmit} accountName={account.name!} />;
};
