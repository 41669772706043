import { FC, ReactNode, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { Grid } from "@/components/grid";
import { Text } from "@/components/text";
import { fromDepositState } from "@/features/deposit/components/verification";
import { onExitDialog } from "@/features/onboarding/components/exit-dialog";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { useTerminalAutoRoute } from "@/hooks/terminal-auto-route.hook";

import { Header } from "./components/Header/Header";
import { NavigationItem } from "./components/navigation/navigation.helpers";
import { ProgressBar } from "./components/navigation/progress-bar";
import * as Styled from "./onboarding.container.styled";

type Props = {
  children: ReactNode;
  title: string;
  image?: string;
  description?: string;
  activeStep: string;
  setStep: (step: string) => void;
  navigation: NavigationItem[];
};

export const OnboardingContainer: FC<Props> = ({
  children,
  title,
  description,
  image,
  activeStep,
  setStep,
  navigation,
}) => {
  const hasImage = !!image;
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();
  const { open: openTerminal } = useTerminalAutoRoute();
  const { state } = useLocation();

  const [exitCount, setExitCount] = useState(1);
  const isFromDeposit = state === fromDepositState;

  const currentStepIndex = useMemo(
    () => navigation.findIndex(item => item.id.toLowerCase() === activeStep),
    [activeStep, navigation],
  );

  const closeButtonAction = () => {
    if (!isFromDeposit) {
      track(amplitudeEvents.kyc.close(currentStepIndex + 1));
      openTerminal();
    } else if (exitCount < 2) {
      onExitDialog(true);
      setExitCount(prevState => prevState + 1);
    } else {
      track(amplitudeEvents.kyc.close(currentStepIndex + 1));
      navigate(-1);
    }
  };

  return (
    <Styled.Wrapper hasImage={hasImage}>
      <Styled.Container>
        <Header title={title} onClose={closeButtonAction} />

        {description && (
          <Text as="h3" size={{ "@initial": "1", "@bp2": "2" }} family="roboto" css={{ mt: "16px" }} color="dimmed">
            {description}
          </Text>
        )}
        {isMobile && <ProgressBar activeStep={activeStep} setStep={setStep} navigation={navigation} />}

        <Styled.ChildrenWrapper>{children}</Styled.ChildrenWrapper>
      </Styled.Container>
      {hasImage && (
        <Grid
          css={{
            placeItems: "center",
            display: "none",
            "@bp3": { display: "grid", placeItems: "end" },
          }}
        >
          <img alt="background" src={image} style={{ width: 190, height: 320 }} />
        </Grid>
      )}
    </Styled.Wrapper>
  );
};
