// https://www.tradingview.com/charting-library-docs/latest/customization/Featuresets/

import { ChartingLibraryFeatureset } from "public/charting_library/charting_library";

const basicDisabledFeatures: ChartingLibraryFeatureset[] = [
  "header_symbol_search",
  "symbol_search_hot_key",
  "header_compare",
  "header_screenshot",
  "header_settings",
  "timeframes_toolbar",
  "header_undo_redo",
  "main_series_scale_menu",
  "legend_inplace_edit",
  "header_quick_search",
  // "widget_logo"
];
const basicEnabledFeatures: ChartingLibraryFeatureset[] = [
  "hide_resolution_in_legend",
  "accessible_keyboard_shortcuts",
  "always_show_legend_values_on_mobile",
  // "saveload_separate_drawings_storage",
];

const getMobileFeatures = ({
  isChartExpanded,
}: {
  isChartExpanded: boolean;
}): { enabledFeatures: ChartingLibraryFeatureset[]; disabledFeatures: ChartingLibraryFeatureset[] } => {
  if (isChartExpanded) {
    return {
      disabledFeatures: [...basicDisabledFeatures, "header_fullscreen_button"],
      enabledFeatures: [...basicEnabledFeatures, "study_templates", "show_zoom_and_move_buttons_on_touch"],
    };
  }

  return {
    disabledFeatures: [
      ...basicDisabledFeatures,
      "header_fullscreen_button",
      "left_toolbar",
      "header_saveload",
      "header_indicators",
      "display_market_status",
    ],
    enabledFeatures: [...basicEnabledFeatures, "show_zoom_and_move_buttons_on_touch"],
  };
};

const getDesktopFeatures = (): ReturnType<typeof getMobileFeatures> => {
  return {
    disabledFeatures: [...basicDisabledFeatures],
    enabledFeatures: [...basicEnabledFeatures, "study_templates"],
  };
};

const getTradingViewFeatures = ({ isMobile, isChartExpanded }: { isMobile: boolean; isChartExpanded: boolean }) => {
  if (isMobile) {
    return getMobileFeatures({ isChartExpanded });
  }

  return getDesktopFeatures();
};

export { getTradingViewFeatures };

//  enabled_features exness
//   [
//     "hide_left_toolbar_by_default",
//     "charting_library_debug_mode",
//     "adaptive_logo",
//     "remove_library_container_border",
//     "study_templates",
//     "items_favoriting",
//     "header_screenshot",
//     "chart_crosshair_menu"
// ]

//  disabled_features exness
//  [
//     "symbol_search_hot_key",
//     "header_symbol_search",
//     "header_compare",
//     "use_localstorage_for_settings",
//     "support_multicharts",
//     "save_chart_properties_to_local_storage",
//     "chart_property_page_timezone_sessions",
//     "show_dom_first_time",
//     "widget_logo",
//     "timezone_menu",
//     "header_screenshot",
//     "header_fullscreen_button",
//     "border_around_the_chart",
//     "symbol_info",
//     "edit_buttons_in_legend",
//     "display_market_status",
//     "header_settings",
//     "create_volume_indicator_by_default",
//     "create_volume_indicator_by_default_once"
// ]
