import { type FC, type ReactNode } from "react";

import { Sidebar, sidebarWidthCollapsed } from "@/components/sidebar";
import { cn } from "@/shared/styles";

type Props = {
  isTableExpanded?: boolean;
  // slots
  chartSymbolsSlot: ReactNode;
  accountSelectSlot: ReactNode;
  depositButtonSlot: ReactNode;
  chartSlot: ReactNode;
  placeOrderSlot: ReactNode;
  tablesSlot: ReactNode;
  accountSummarySlot: ReactNode;
};

const TerminalDesktopFrame: FC<Props> = ({
  isTableExpanded,
  chartSymbolsSlot,
  accountSelectSlot,
  depositButtonSlot,
  chartSlot,
  placeOrderSlot,
  tablesSlot,
  accountSummarySlot,
}) => {
  return (
    <div className="flex h-full flex-col bg-surface-under-canvas">
      <Sidebar initiallyCollapsed />
      <div
        className="grid grow grid-rows-[auto_1fr_auto] gap-px overflow-auto"
        style={{ marginLeft: sidebarWidthCollapsed + 1 }}
      >
        <div className="flex items-center justify-between gap-4 overflow-auto bg-surface-canvas px-4 py-2">
          {chartSymbolsSlot}
          <div className="flex items-center gap-6">
            {accountSelectSlot}
            {depositButtonSlot}
          </div>
        </div>
        <div
          className={cn(
            "grid grow grid-rows-[1fr_auto] gap-px overflow-auto",
            isTableExpanded && "grid-rows-[2fr_1fr]",
          )}
        >
          <div className="grid grid-cols-[1fr,332px] gap-px overflow-auto">
            {chartSlot}
            {placeOrderSlot}
          </div>
          {tablesSlot}
        </div>
        {accountSummarySlot}
      </div>
    </div>
  );
};

export { TerminalDesktopFrame };
