import React from "react";

import { Button } from "@/components/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/dialog";
import { Flex } from "@/components/flex";
import { DoubleOkIcon } from "@/components/icons";
import { Text } from "@/components/text";
import { EmailConfirmDialogContent } from "@/features/onboarding/components/sumsub/email-confirm-dialog-content";
import { useTranslation } from "@/hooks/translator.hook";

import { ChangeEmailButton } from "./change-email/change-email-button";

export const PersonalLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text family="roboto" lineHeight="3" css={{ mb: "8px" }} size={{ "@initial": "1", "@bp2": "2" }}>
      {children}
    </Text>
  );
};

export const PersonalValue = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text weight="bold" size={{ "@initial": "2", "@bp2": "4" }}>
      {children}
    </Text>
  );
};

export const PersonalEmail = ({ email, isEmailConfirmed }: { email: string; isEmailConfirmed: boolean }) => {
  const { t } = useTranslation();

  return (
    <>
      <PersonalLabel>{t("profile-settings.personal.email")}</PersonalLabel>
      <Flex css={{ alignItems: "center", gap: "8px", flexWrap: "wrap" }}>
        <ChangeEmailButton isEmailConfirmed={isEmailConfirmed} email={email} />
        <PersonalValue>{email}</PersonalValue>
        {isEmailConfirmed ? (
          <DoubleOkIcon />
        ) : (
          <Dialog>
            <DialogTrigger asChild>
              <Button size="small" variant="secondary">
                {t("button.verify-email")}
              </Button>
            </DialogTrigger>
            <DialogContent>
              <EmailConfirmDialogContent email={email} />
            </DialogContent>
          </Dialog>
        )}
      </Flex>
    </>
  );
};
