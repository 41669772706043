import { type FC, memo, useEffect, useState } from "react";

import { NumberFormat } from "@/app/components";
import { getNumberColorClassname } from "@/app/ui/colors";
import { cn } from "@/shared/styles";

import { calculateSymbolPriceChange } from "../../helpers/formulas";

type Props = {
  bid: number | undefined;
  priceLast24H: number | null | undefined;
};

const _SymbolPriceChange: FC<Props> = ({ bid, priceLast24H }) => {
  const [priceChange, setPriceChange] = useState(() => calculateSymbolPriceChange({ bid, priceLast24H }));

  useEffect(() => {
    setPriceChange(calculateSymbolPriceChange({ bid, priceLast24H }));
  }, [bid, priceLast24H]);

  if (priceChange === null) {
    return null;
  }

  const color = getNumberColorClassname(priceChange.direction);

  return (
    <div className={cn("flex items-center", color ? color : "text-contrast-secondary")}>
      {priceChange.direction === 1 && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.38071 13.2646L7.38071 5.09899L3.78981 8.70126L2.90344 7.82626L8.00572 2.73535L13.0966 7.82626L12.233 8.70126L8.63071 5.09899L8.63071 13.2646L7.38071 13.2646Z"
            fill="currentColor"
          />
        </svg>
      )}
      {priceChange.direction === -1 && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.61929 2.73542L8.61928 10.901L12.2102 7.29874L13.0966 8.17374L7.99428 13.2646L2.90338 8.17374L3.76701 7.29874L7.36928 10.901L7.36929 2.73542L8.61929 2.73542Z"
            fill="currentColor"
          />
        </svg>
      )}
      <div>
        <NumberFormat value={priceChange.priceChange} decimalScale={2} suffix="%" />
      </div>
    </div>
  );
};

const SymbolPriceChange = memo(_SymbolPriceChange);

export { SymbolPriceChange };
