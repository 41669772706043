import { FC, memo } from "react";

import { Box } from "@/components/box";
import { UnstyledText } from "@/components/text";
import { SymbolIcon } from "@/features/terminal/symbols/icon";

import { AddChartSymbolRow } from "./add-chart-symbol-row";
import { WatchlistStyledCell } from "./styles";
import { WatchlistFavoriteButton } from "./watchlist-favorite-button";
import { WatchStatefulValue } from "./watchlist-stateful-value";

type Props = {
  isChartFavorite: boolean;
  accountId: string;
  isFavorite: boolean;
  isWatchlist: boolean;
  symbol: string;
  chartFavoritesCount: number;
  setSymbol: (symbol: string) => void;
  bid?: number;
  ask?: number;
  eventTime?: number;
  onClose?: () => void;
};

const _WatchlistTableRow: FC<Props> = ({
  isFavorite,
  symbol,
  bid,
  ask,
  eventTime,
  isWatchlist,
  accountId,
  isChartFavorite,
  onClose,
  chartFavoritesCount,
  setSymbol,
}) => {
  return (
    <AddChartSymbolRow
      accountId={accountId}
      isChartFavorite={isChartFavorite}
      symbol={symbol}
      onClose={onClose}
      chartFavoritesCount={chartFavoritesCount}
      setSymbol={setSymbol}
    >
      <WatchlistStyledCell wide css={{ minWidth: 150 }}>
        <div className="flex items-center gap-2">
          <SymbolIcon symbol={symbol} />
          <UnstyledText size="2">{symbol}</UnstyledText>
        </div>
      </WatchlistStyledCell>
      {isWatchlist && (
        <>
          <WatchlistStyledCell css={{ width: "80px", textAlign: "end" }}>
            {bid ? <WatchStatefulValue trigger={eventTime!} value={bid} /> : <Box css={{ p: 4, minWidth: 60 }}>—</Box>}
          </WatchlistStyledCell>
          <WatchlistStyledCell css={{ width: "80px", textAlign: "end" }}>
            {ask ? <WatchStatefulValue trigger={eventTime!} value={ask} /> : <Box css={{ p: 4, minWidth: 60 }}>—</Box>}
          </WatchlistStyledCell>
        </>
      )}
      <WatchlistStyledCell>
        <WatchlistFavoriteButton isFavorite={isFavorite} symbol={symbol} accountId={accountId} />
      </WatchlistStyledCell>
    </AddChartSymbolRow>
  );
};

export const WatchlistTableRow = memo(_WatchlistTableRow);
