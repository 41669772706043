import { type FC, memo } from "react";
import { useLocation } from "react-router-dom";

import { Sheet } from "@/components/sheet";
import { SidebarContent } from "@/components/sidebar/sidebar-content";
import { UnstyledButton } from "@/components/unstyled-button.tsx";
import { IconMenu } from "@/domains/icons";
import { LanguageSwitcherContainer } from "@/features/language-switcher/language-switcher.container";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";

const _SidebarButton: FC = () => {
  const { pathname } = useLocation();
  const [sheetOpened, { onOpenChange: sheetOnOpenChange, close: closeSheet }] = useDisclosure();
  const [languageDialogOpened, { onOpenChange: languageDialogOpenChange, open: languageDialogOpen }] = useDisclosure();

  const isTradingPage = pathname === cabinetRoutes.mt4Terminal || pathname === cabinetRoutes.mt5Terminal;

  const openBottomSheet = () => {
    closeSheet();
    languageDialogOpen();
  };

  return (
    <>
      <Sheet open={sheetOpened} onOpenChange={sheetOnOpenChange}>
        <Sheet.Trigger asChild>
          <UnstyledButton css={{ color: isTradingPage ? "$textInverse" : "$textMain", "@bp3": { display: "none" } }}>
            <IconMenu />
          </UnstyledButton>
        </Sheet.Trigger>
        <Sheet.Content>
          <SidebarContent onLanguageSelect={openBottomSheet} onClose={closeSheet} />
        </Sheet.Content>
      </Sheet>
      <LanguageSwitcherContainer onOpenChange={languageDialogOpenChange} open={languageDialogOpened} />
    </>
  );
};

export const SidebarButton = memo(_SidebarButton);
