import { FC, memo } from "react";

import { SelectOptionType } from "@/components/form";
import { Stack } from "@/components/stack";
import { SearchInput, Select } from "@/shared/ui";

import { useSymbolsContext } from "../contexts/symbols.context";
import { MergedTerminalSymbol } from "../helpers/symbols";
import { WatchlistTable } from "./components/table/watchlist-table";

type Props = {
  symbols: MergedTerminalSymbol[];
  search: string;
  setSearch: (value: string) => void;
  group: ReturnType<typeof useSymbolsContext>["group"];
  changeGroup: ReturnType<typeof useSymbolsContext>["changeGroup"];
  groupOptions: SelectOptionType[];
  isWatchlist: boolean;
  chartFavoritesCount: number;
  setSymbol: (symbol: string) => void;
  onClose?: () => void;
};

const _Watchlist: FC<Props> = ({
  symbols,
  search,
  setSearch,
  group,
  changeGroup,
  groupOptions,
  isWatchlist,
  onClose,
  chartFavoritesCount,
  setSymbol,
}) => {
  return (
    <Stack gap="1" css={{ height: "100%" }}>
      <SearchInput value={search} onChange={setSearch} size="sm" />
      <Select value={group} onValueChange={changeGroup} size="sm">
        {groupOptions.map(({ label, value }) => (
          <Select.Item key={value} value={value as string}>
            {label}
          </Select.Item>
        ))}
      </Select>
      <WatchlistTable
        symbols={symbols}
        isWatchlist={isWatchlist}
        onClose={onClose}
        chartFavoritesCount={chartFavoritesCount}
        setSymbol={setSymbol}
      />
    </Stack>
  );
};

export const Watchlist = memo(_Watchlist);
