import { TerminalSymbolSession } from "@/services/openapi";

// https://www.tradingview.com/charting-library-docs/latest/connecting_data/Trading-Sessions
// https://charting-library.tradingview-widget.com/checksession.html

const dayMappings = {
  Sunday: "1",
  Monday: "2",
  Tuesday: "3",
  Wednesday: "4",
  Thursday: "5",
  Friday: "6",
  Saturday: "7",
};

const getSymbolSessions = (sessions: TerminalSymbolSession[]): string => {
  const result: string[] = [];
  const dayObj: { [key: string]: TerminalSymbolSession[] } = {};
  sessions.forEach(session => {
    const day = dayMappings[session.dayOfWeek!];
    dayObj[day] = dayObj[day] ? dayObj[day].concat(session) : [session];
  });

  for (const day in dayObj) {
    const interval: string[] = [];

    dayObj[day].forEach(({ closeHours, closeMinutes, openHours, openMinutes }) => {
      const openDate = `${String(openHours).padStart(2, "0")}${String(openMinutes).padStart(2, "0")}`;
      const closeDate = `${String(closeHours).padStart(2, "0")}${String(closeMinutes).padStart(2, "0")}`;
      interval.push(`${openDate}-${closeDate}`);
    });
    result.push(`${interval.join(",")}:${day}`);
  }
  return result.join("|");
};

export { getSymbolSessions };
