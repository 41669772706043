import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

const styles = cva(
  "inline-grid h-7 min-w-[53px] place-items-center rounded-[8px] border px-2 font-roboto text-[14px] font-normal leading-normal",
  {
    variants: {
      variant: {
        orange: "border-warning-bg text-warning-text",
        blue: "border-[#0070FF] text-[#0070FF]", // FIXME:
      },
    },
  },
);

type Props = ComponentPropsWithoutRef<"div"> & VariantProps<typeof styles>;

const Badge = forwardRef<ElementRef<"div">, Props>(({ className, variant, ...props }, ref) => {
  return <div className={cn(styles({ variant }), className)} {...props} ref={ref} />;
});

export { Badge };
