import { FC } from "react";
import { Link } from "react-router-dom";

import { track } from "@/app/libs/amplitude";
import { Button } from "@/components/button";
import { Text } from "@/components/text";
import { styled } from "@/styles";

import { Props, WidgetButtonProps } from "./types";

const Info = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "60px 32px",
  background: "$lite",
  borderRadius: "16px",
  textAlign: "center",

  "@bp2": {
    mb: "30px",
  },
});

const StyledText = styled(Text, {
  lineHeight: "$3",
  color: "$darkGray !important",
});

const ContentWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "8px",
});

const WidgetButton: FC<WidgetButtonProps> = ({ style, action, text }) => {
  const { web, analyticsAction, type } = action;

  return (
    <Link to={web!} onClick={() => track(analyticsAction ?? "")}>
      <Button
        variant={style === "Secondary" ? "secondary" : "primary"}
        size="small"
        css={{ width: "100%", "@bp2": { width: "auto" } }}
      >
        {text?.title}
      </Button>
    </Link>
  );
};

export const CompleteProfileInfo: FC<Props> = ({ text, action, children }) => {
  return (
    <Info>
      <Text
        weight="bold"
        size={{ "@initial": "2", "@bp2": "4" }}
        css={{ mb: 8 }}
        lineHeight={{ "@initial": "2", "@bp2": "3" }}
      >
        {text?.title}
      </Text>
      <StyledText
        family="roboto"
        size={{ "@initial": "1", "@bp2": "2" }}
        lineHeight={{ "@initial": "2", "@bp2": "3" }}
        css={{ mb: 24 }}
      >
        {text?.subtitle}
      </StyledText>

      <ContentWrapper>
        {children?.map((child, index) => {
          if (child.type === "Button") return <WidgetButton key={index} {...child} />;
          else return null;
        })}
      </ContentWrapper>
    </Info>
  );
};
