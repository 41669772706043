// types from radix
type PointerDownOutsideEvent = CustomEvent<{
  originalEvent: PointerEvent;
}>;
type FocusOutsideEvent = CustomEvent<{
  originalEvent: FocusEvent;
}>;

export const preventCloseModalOnToastClick = (event: PointerDownOutsideEvent | FocusOutsideEvent) => {
  if (event.target instanceof Element) {
    const isToastClick = document.getElementById("toasts")?.contains(event.target);
    if (isToastClick) {
      event.preventDefault();
    }
  }
};

export const focusModalAutocloseElement = (event: Event) => {
  const autoCloseElement = document.querySelector('[data-autofocus="true"]');
  if (autoCloseElement) {
    event.preventDefault();
    (autoCloseElement as HTMLElement).focus();
  }
};
