import { FC } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "@/hooks/translator.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { TradingAccount, TradingServerPlatform } from "@/services/openapi";
import { Button } from "@/shared/ui";
import { useUpdateLastAccountMutation } from "@/state/server/accounts";

import { MetatraderInstallButton } from "./metatrader-install-button";
import { AccountPlatformInfo } from "./platform-info";

type Props = {
  account: TradingAccount;
};

export const TradeButtonDialogContent: FC<Props> = ({ account }) => {
  const { t } = useTranslation();
  const { mutateAsync: updateLastAccount } = useUpdateLastAccountMutation();

  const version = account.platform === TradingServerPlatform.MetaTrader5 ? 5 : 4;

  const onClick = () => updateLastAccount({ id: account.id! });

  return (
    <AccountPlatformInfo account={account}>
      <Button asChild>
        <Link to={version === 5 ? cabinetRoutes.mt5Terminal : cabinetRoutes.mt4Terminal} onClick={onClick}>
          {t("accounts.metatrader.trade-in-browser", { version })}
        </Link>
      </Button>
      <MetatraderInstallButton version={version} account={account} />
    </AccountPlatformInfo>
  );
};
