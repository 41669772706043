import dayjs from "dayjs";
import { FC } from "react";

import { Flex } from "@/components/flex";
import { Text } from "@/components/text";
import { TransactionStatusBadge } from "@/features/transactions/components/transaction-status-badge";
import {
  getTransactionAmount,
  getTransactionTypeIcon,
} from "@/features/transactions/components/transactions-table/transaction-table-row";
import { FundsTx } from "@/services/openapi";
import { styled } from "@/styles";

const Container = styled("div", {
  border: "1px solid",
  borderColor: "$bgBorder",
  borderRadius: "8px",
  p: "16px",

  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

const Row = styled(Flex, {
  alignItems: "center",
  justifyContent: "space-between",
});

type Props = {
  transaction: FundsTx;
  simplify?: boolean;
};

export const TransactionCard: FC<Props> = ({ transaction, simplify }) => {
  const { date, description, status, amounts, type, typeTitle } = transaction;
  return (
    <Container>
      <Row>
        <Flex>
          <Flex css={{ alignItems: "center", gap: "8px" }}>
            {getTransactionTypeIcon(type!)}
            <Text family="roboto" size="1">
              {simplify ? type : typeTitle}
            </Text>
          </Flex>
        </Flex>
        <Text family="roboto" size="1">
          {dayjs(date).format("DD.MM.YYYY HH.mm")}
        </Text>
      </Row>
      <Text
        css={{
          display: "none",
          "@bp2": {
            display: "block",
          },
        }}
        family="roboto"
      >
        {description}
      </Text>
      <Row>
        <Text
          css={{
            span: {
              fontSize: "18px",
              fontWeight: "$bold",
              "@bp2": {
                fontSize: "14px",
                fontWeight: "$normal",
              },
            },
          }}
        >
          {getTransactionAmount(amounts!)}
        </Text>
        {!simplify && <TransactionStatusBadge status={status!} size="small" />}
      </Row>
    </Container>
  );
};
