import { FC, ReactNode } from "react";

import { Grid } from "../grid";
import { Pagination, PaginationProps } from "../pagination";

export type TableFooterExternalProps = PaginationProps & {
  footerLeft?: ReactNode;
};

export const TableFooter: FC<TableFooterExternalProps> = ({ changePage, currentPage, pageSize, total, footerLeft }) => {
  return (
    <Grid
      css={{
        gridTemplateRows: "auto auto",
        gap: "16px",
        justifyContent: "center",

        mt: "25px",
        "@bp2": { justifyContent: "space-between", gridTemplateRows: "auto", gridTemplateColumns: "auto auto" },
      }}
    >
      <Grid css={{ placeItems: "center" }}>{footerLeft}</Grid>
      <Grid css={{ placeItems: "center" }}>
        <Pagination changePage={changePage} currentPage={currentPage} pageSize={pageSize} total={total} />
      </Grid>
    </Grid>
  );
};
