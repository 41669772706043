import { type FC, type ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { useScript } from "@/hooks/script.hook";
import { Loader } from "@/shared/ui";
import { useTradingCentralCredentialsQuery } from "@/state/server/terminal";

import { TCWidgetColors, useTCWidgetColors } from "../helpers/colors";

type Props = {
  children: ({ colors, language, token }: { colors: TCWidgetColors; language: string; token: string }) => ReactNode;
};

const WidgetContainer: FC<Props> = ({ children }) => {
  const { data } = useTradingCentralCredentialsQuery();

  const { i18n } = useTranslation();

  const colors = useTCWidgetColors();

  const status = useScript("https://global.tradingcentral.com/widgets/client/619/latest/package.js");

  if (!data || status !== "ready") {
    return (
      <div className="mt-6">
        <Loader />
      </div>
    );
  }

  return children({ colors, language: i18n.resolvedLanguage, token: data.token! }) as JSX.Element;
};

export { WidgetContainer };
