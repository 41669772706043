import { FC, memo, useMemo, useState } from "react";

import { SelectOptionType } from "@/components/form";
import { useTranslation } from "@/hooks/translator.hook";

import { useCurrentSymbolContext } from "../contexts/current-symbol-context";
import { useSymbolsContext } from "../contexts/symbols.context";
import { filterSymbolsSearch, getSymbolsFilteringFunction, sortWatchlistSymbols } from "../helpers/symbols";
import { Watchlist } from "./watchlist";

type Props = {
  isWatchlist?: boolean;
  onClose?: () => void;
};

const _WatchlistContainer: FC<Props> = ({ isWatchlist = true, onClose }) => {
  const { t } = useTranslation();

  const { setSymbol } = useCurrentSymbolContext();
  const { symbols, symbolGroups, group, changeGroup, favorites } = useSymbolsContext();

  const chartFavoritesCount = favorites.charts!.length;

  const [search, setSearch] = useState("");

  const groupOptions: SelectOptionType[] = useMemo(
    () => symbolGroups.map(group => ({ value: group, label: t(`terminal.groups.${group}`)! })),
    [symbolGroups, t],
  );

  const filteringFunction = useMemo(
    () =>
      getSymbolsFilteringFunction({
        popular: favorites.popular!,
        recent: favorites.recent!,
        group,
        hasSearch: !!search,
      }),
    [group, favorites, search],
  );

  const visibleSymbols = symbols.filter(filterSymbolsSearch(search)).filter(filteringFunction);

  const sortedSymbols = sortWatchlistSymbols(visibleSymbols, group, favorites);

  return (
    <Watchlist
      symbols={sortedSymbols}
      group={group}
      groupOptions={groupOptions}
      search={search}
      changeGroup={changeGroup}
      setSearch={setSearch}
      isWatchlist={isWatchlist}
      onClose={onClose}
      chartFavoritesCount={chartFavoritesCount}
      setSymbol={setSymbol}
    />
  );
};

export const WatchlistContainer = memo(_WatchlistContainer);
