import { type FC } from "react";

import { TCWidgetColors } from "../helpers/colors";
import { NewsVolumeWidget } from "./widgets/news-volume";
import { SentimentTrendWidget } from "./widgets/sentiment-trend";
import { TechnicalsSummaryScoreWidget } from "./widgets/technicals-summary-score";

type Props = {
  token: string;
  language: string;
  instrument: string;
  colors: TCWidgetColors;
};

const Analytics: FC<Props> = ({ colors, token, language, instrument }) => {
  return (
    <div className="flex flex-col gap-2">
      {/* <WidgetBlock title="Target price" description="dasds">
        <div className="flex items-center justify-between gap-2">
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-1 font-gilroy text-[16px] font-semibold leading-[1.2]">
              <span className="text-contrast-primary">1.095345</span>
              <div className="flex items-center gap-0.5">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M8.61932 2.73542L8.61932 10.901L12.2102 7.29874L13.0966 8.17374L7.99432 13.2646L2.90341 8.17374L3.76704 7.29874L7.36932 10.901L7.36932 2.73542L8.61932 2.73542Z"
                    fill="#FF3434"
                  />
                </svg>

                <span className="text-negative-text">5%</span>
              </div>
            </div>

            <div className="font-roboto text-[14px] leading-normal">
              <span className="text-negative-text">Strong down</span>
              <span className="text-contrast-secondary"> signal</span>
            </div>
          </div>

          <div className="grid size-14 place-items-center rounded-full bg-surface-canvas">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.33686 7.18233C8.85011 7.71038 8.83811 8.55451 8.31007 9.06776C6.4593 10.8667 5.33335 13.313 5.33335 16C5.33335 18.6871 6.4593 21.1334 8.31007 22.9323C8.83811 23.4455 8.85011 24.2897 8.33686 24.8177C7.8236 25.3458 6.97947 25.3577 6.45143 24.8445C4.11971 22.5781 2.66669 19.4557 2.66669 16C2.66669 12.5444 4.11971 9.42195 6.45143 7.15555C6.97947 6.6423 7.8236 6.65429 8.33686 7.18233ZM23.8156 7.18233C24.3288 6.65429 25.173 6.6423 25.701 7.15555C28.0327 9.42195 29.4858 12.5444 29.4858 16C29.4858 19.4557 28.0327 22.5781 25.701 24.8445C25.173 25.3577 24.3288 25.3458 23.8156 24.8177C23.3023 24.2897 23.3143 23.4455 23.8424 22.9323C25.6931 21.1334 26.8191 18.6871 26.8191 16C26.8191 13.313 25.6931 10.8667 23.8424 9.06776C23.3143 8.55451 23.3023 7.71038 23.8156 7.18233ZM11.8484 10.9988C12.3166 11.5672 12.2353 12.4075 11.6669 12.8756C10.6439 13.7182 10.0758 14.8307 10.0758 16C10.0758 17.1694 10.6439 18.2818 11.6669 19.1244C12.2353 19.5926 12.3166 20.4329 11.8484 21.0013C11.3803 21.5697 10.54 21.651 9.97155 21.1828C8.4313 19.9142 7.40911 18.0847 7.40911 16C7.40911 13.9153 8.4313 12.0859 9.97155 10.8173C10.54 10.3491 11.3803 10.4304 11.8484 10.9988ZM20.304 10.9988C20.7722 10.4304 21.6125 10.3491 22.1809 10.8173C23.7211 12.0859 24.7433 13.9153 24.7433 16C24.7433 18.0847 23.7211 19.9142 22.1809 21.1828C21.6125 21.651 20.7722 21.5697 20.304 21.0013C19.8359 20.4329 19.9171 19.5926 20.4856 19.1244C21.5086 18.2818 22.0767 17.1694 22.0767 16C22.0767 14.8307 21.5086 13.7182 20.4856 12.8756C19.9171 12.4075 19.8359 11.5672 20.304 10.9988Z"
                fill="#FF3434"
              />
              <path
                d="M18.6666 16C18.6666 17.4728 17.4726 18.6667 15.9999 18.6667C14.5271 18.6667 13.3332 17.4728 13.3332 16C13.3332 14.5273 14.5271 13.3334 15.9999 13.3334C17.4726 13.3334 18.6666 14.5273 18.6666 16Z"
                fill="#FF3434"
              />
            </svg>
          </div>
        </div>

        <Button size="sm" variant="secondary">
          Trade with signal
        </Button>
      </WidgetBlock> */}

      <SentimentTrendWidget colors={colors} instrument={instrument} language={language} token={token} />
      <TechnicalsSummaryScoreWidget colors={colors} instrument={instrument} language={language} token={token} />
      <NewsVolumeWidget colors={colors} instrument={instrument} language={language} token={token} />
    </div>
  );
};

export { Analytics };
