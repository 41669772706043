import { type FC } from "react";

import { Badge } from "@/components/badge/badge";
import { TradingAccount } from "@/services/openapi";

import { AccountBadge } from "../badge";
import { AccountCard } from "../card";
import { formatAccountPlatform } from "../helpers";
import { RestoreButton } from "./restore-button";

type Props = {
  account: TradingAccount;
};

const ArchivedAccountCard: FC<Props> = ({ account }) => {
  const { uiPlatform, type, id, actions } = account;

  return (
    <AccountCard>
      <AccountCard.Header account={account} showSettingsButton={false} />
      <AccountCard.Badges>
        <AccountBadge size="small" type={type!} />
        <Badge size="small">{formatAccountPlatform(uiPlatform!.platform!)}</Badge>
      </AccountCard.Badges>
      {actions!.canRestore && (
        <AccountCard.Footer>
          <RestoreButton accountId={id!} />
        </AccountCard.Footer>
      )}
    </AccountCard>
  );
};

export { ArchivedAccountCard };
