import { Navigate, Outlet, useNavigate } from "react-router-dom";

import { useAuth } from "@/contexts/auth.context";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { authRoutes } from "./auth.routes";

export const PrivateRoute = ({ children }: { children?: React.ReactElement }) => {
  const { isAuthenticated } = useAuth();
  const profile = useProfileData();
  const navigate = useNavigate();

  if (!profile?.options?.isSurveyCompleted && location.pathname !== onboardingRoutes.onboarding) {
    navigate(onboardingRoutes.onboarding, { replace: true });
  }

  if (!isAuthenticated) {
    return <Navigate to={authRoutes.signIn} replace />;
  }

  return children ? children : <Outlet />;
};
