import { NumericFormat } from "react-number-format";

import { Text } from "../text";

export const BalanceFormat = ({
  value,
  currency,
  styled = true,
  prefix,
  fixedDecimalScale,
  ...restProps
}: {
  value: number | string;
  currency: string;
  prefix?: string;
  styled?: boolean;
  fixedDecimalScale?: boolean;
}) => {
  const renderFormat = () => (
    <NumericFormat
      decimalScale={2}
      fixedDecimalScale={fixedDecimalScale}
      value={value}
      thousandSeparator=","
      displayType="text"
      prefix={prefix}
      suffix={currency ? ` ${currency}` : undefined}
    />
  );

  if (styled) {
    return (
      <Text weight="bold" size={{ "@initial": "1", "@bp3": "4" }} css={{ whiteSpace: "nowrap" }} {...restProps}>
        {renderFormat()}
      </Text>
    );
  }

  return renderFormat();
};
