import { type FC } from "react";

import { PnlFormat, PositiveNumberFormat } from "@/app/components";
import { getNumberColorClassname } from "@/app/ui/colors";
import { Badge } from "@/components/badge/badge";
import { SetBalanceButton } from "@/features/dashboard/components/set-balance-button/set-balance-button";
import { TradeButton } from "@/features/dashboard/components/trade-button/trade-button";
import { useTranslation } from "@/hooks/translator.hook";
import { TradingAccount, TradingAccountType } from "@/services/openapi";

import { AccountBadge } from "../badge";
import { AccountCard } from "../card";
import { formatAccountPlatform } from "../helpers";

type Props = {
  account: TradingAccount;
};

const DemoAccountCard: FC<Props> = ({ account }) => {
  const { uiPlatform, currency, marginFree, equity, digits, pnL, actions } = account;

  const { t } = useTranslation();

  return (
    <AccountCard>
      <AccountCard.Header account={account} />
      <AccountCard.Badges>
        <AccountBadge size="small" type={TradingAccountType.Demo} data-test="account-badge-demo" />
        <Badge data-test="account-badge-platform" size="small">
          {formatAccountPlatform(uiPlatform!.platform!)}
        </Badge>
      </AccountCard.Badges>
      <AccountCard.DataList>
        <AccountCard.DataListItem
          label={t("accounts.list.equity")}
          value={<PositiveNumberFormat value={equity} decimalScale={digits} currency={currency!} />}
        />
        <AccountCard.DataListItem
          label={t("accounts.list.free-margin")}
          value={<PositiveNumberFormat value={marginFree} decimalScale={digits} currency={currency!} />}
        />
        <AccountCard.DataListItem
          label={t("accounts.list.pnl")}
          valueClassName={getNumberColorClassname(pnL!)}
          value={<PnlFormat value={pnL} decimalScale={digits} currency={currency!} />}
        />
      </AccountCard.DataList>
      <AccountCard.Footer>
        <TradeButton account={account} />
        {actions!.canMakeDemoDeposit && <SetBalanceButton account={account} />}
      </AccountCard.Footer>
    </AccountCard>
  );
};

export { DemoAccountCard };
