import { FC } from "react";
import { useController } from "react-hook-form";

import { SubmitButton } from "@/app/form";
import { Box } from "@/components/box";
import { DialogButtons, DialogClose } from "@/components/dialog";
import { Flex } from "@/components/flex";
import { HookForm } from "@/components/form/hook-form";
import { InfoIcon, OkIcon } from "@/components/icons";
import { Text } from "@/components/text";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { TradingServerUiPlatform } from "@/services/openapi";
import { Button, ButtonGroup } from "@/shared/ui";

enum ChangeAccountPlatformFields {
  PLATFORM = "platform",
}

export interface IChangeAccountPlatformFormValues {
  [ChangeAccountPlatformFields.PLATFORM]: TradingServerUiPlatform;
}

type Props = {
  onSubmit: (values: IChangeAccountPlatformFormValues) => void;
  platform: TradingServerUiPlatform;
};

export const ChangeAccountPlatformForm: FC<Props> = ({ onSubmit, platform: platformProp }) => {
  const { t } = useTranslation();

  const form = useHookForm<IChangeAccountPlatformFormValues>({
    defaultValues: {
      [ChangeAccountPlatformFields.PLATFORM]: platformProp,
    },
  });

  const { control, watch } = form;

  const { platform } = watch();

  const { field } = useController({ name: ChangeAccountPlatformFields.PLATFORM, control });

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <ButtonGroup value={field.value} onValueChange={field.onChange} data-test="dialog-platform-switcher">
        <ButtonGroup.Item value={TradingServerUiPlatform.Doto}>Doto</ButtonGroup.Item>
        <ButtonGroup.Item value={TradingServerUiPlatform.MetaTrader5}>MetaTrader 5</ButtonGroup.Item>
      </ButtonGroup>
      {/* TODO: create separate component */}
      <Flex css={{ gap: 12, mt: 16 }}>
        <Box
          css={{
            color: platform === TradingServerUiPlatform.Doto ? "$positiveDefault" : "$textSecondary",
          }}
        >
          {platform === TradingServerUiPlatform.Doto ? <OkIcon /> : <InfoIcon />}
        </Box>
        <Text color="dimmed" family="roboto" lineHeight="3">
          {platform === TradingServerUiPlatform.Doto
            ? t("accounts.servers.doto-description")
            : t("accounts.servers.mt-description", { version: 5 })}
        </Text>
      </Flex>
      <DialogButtons>
        <SubmitButton data-test="dialog-platform-submit">{t("button.confirm")}</SubmitButton>
        <DialogClose asChild>
          <Button data-test="dialog-platform-cancel" variant="tertiary">
            {t("button.cancel")}
          </Button>
        </DialogClose>
      </DialogButtons>
    </HookForm>
  );
};
