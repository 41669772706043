export const cabinetRoutes = {
  dashboard: "/my-accounts",
  deposit: "/deposit",
  depositDetails: "/deposit/details",
  withdrawal: "/withdrawal",
  transfer: "/transfer",
  transactionHistory: "/transaction-history",
  trading: "/web-trading",
  mt4Terminal: "/web-trading/mt4",
  mt5Terminal: "/web-trading/mt5",
  notifications: "/notifications",
  profileSettings: "/profile",
  depositRedirect: "/deposit/redirect",
};
