import { useSearchParams } from "react-router-dom";

import { Dialog, DialogContent } from "@/components/dialog";
import { DialogName, useDialog } from "@/hooks/dialog.hook";

import { ChangeEmailDialogContent } from "./change-email-dialog-content";

export const ChangeEmailDialog = () => {
  const { onClose, open } = useDialog(DialogName.CHANGE_EMAIL);
  const [searchParams] = useSearchParams();

  const userId = searchParams.get("userId")!;
  const code = searchParams.get("code")!;

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <ChangeEmailDialogContent code={code} userId={userId} />
      </DialogContent>
    </Dialog>
  );
};
