import { profileService } from "../api";
import {
  ProfileApiConfirmUserEmailRequest,
  ProfileApiRemoveFcmTokenRequest,
  ProfileApiSaveEventRequest,
  ProfileApiUpdateFcmTokenRequest,
  ProfileApiUpdateProfileSettingsRequest,
  ProfileApiUpdateUserEmailRequest,
} from "../openapi";

export const updateActivity = async (values: ProfileApiSaveEventRequest) =>
  await (
    await profileService().saveEvent(values)
  ).data;

export const getProfile = async () => await (await profileService().getProfile()).data;

export const getSettings = async () => await (await profileService().getProfileSettings()).data;

export const confirmUserProfile = async () => await (await profileService().confirmUserProfile()).data;

export const updateUserEmail = async (values: ProfileApiUpdateUserEmailRequest) =>
  await (
    await profileService().updateUserEmail(values)
  ).data;

export const confirmUserEmail = async (values: ProfileApiConfirmUserEmailRequest) =>
  await (
    await profileService().confirmUserEmail(values)
  ).data;

export const changeUserSettings = async (values: ProfileApiUpdateProfileSettingsRequest) =>
  await (
    await profileService().updateProfileSettings(values)
  ).data;

export const updateFcmToken = async (values: ProfileApiUpdateFcmTokenRequest) =>
  await (
    await profileService().updateFcmToken(values)
  ).data;

export const removeFcmToken = async (values: ProfileApiRemoveFcmTokenRequest) =>
  await (
    await profileService().removeFcmToken(values)
  ).data;
