import SumsubWebSdk from "@sumsub/websdk-react";
import { FC, lazy, Suspense, useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { logError } from "@/app/libs/sentry";
import { Box } from "@/components/box";
import { Button } from "@/components/button";
import { DialogName, getDialogSearchParams } from "@/hooks/dialog.hook";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { ExternalKycStatus, SumSubInfo } from "@/services/openapi";
import { profileQueryKeys, useProfileHeaderQuery } from "@/state/server/profile";
import { theme } from "@/styles";

import { OnboardingContainer } from "../../onboarding.container";
import { NavigationTypes } from "../navigation/navigation.helpers";
import { OnboardingMobileNavigation } from "../navigation/onboarding-mobile-navigation";
import { useSumSub } from "./sumsub.helpers";

const LazyOnboardingFooter = lazy(() =>
  import("../footer/onboarding-footer").then(module => ({
    default: module.OnboardingFooter,
  })),
);

const customCssStr: string = `button.submit \n {\n background: ${theme.colors.primary.value};\n}\n button.submit:hover {\nbackground: ${theme.colors.primary.value} !important;\n} button.submit:active {\nbackground: ${theme.colors.primary.value} !important;\n}`;

interface Props {
  sumSubInfo: SumSubInfo;
  isKycCompleted: boolean;
  setBackStep: () => void;
  email: string;
  isEmailConfirmed: boolean;
  navigationParams: NavigationTypes;
}

export const SumsubContainer: FC<Props> = ({ sumSubInfo, setBackStep, isKycCompleted, navigationParams }) => {
  const { isMobile } = useScreenSize();
  const { t } = useTranslation();
  const [sumsubComplete, setSumsubComplete] = useState(isKycCompleted);
  const client = useQueryClient();

  const { refetch: refetchProfile, isFetching } = useProfileHeaderQuery();

  const navigate = useNavigate();
  const { data, expirationHandler } = useSumSub(sumSubInfo.levelNames![0].sumSubName!);

  const onDone = useCallback(async () => {
    const { data: profile } = await refetchProfile();
    if (profile!.kycStatus === ExternalKycStatus.Approved) {
      navigate({
        pathname: cabinetRoutes.dashboard,
        search: getDialogSearchParams(DialogName.CONGRATULATIONS),
      });
    } else {
      navigate({
        pathname: cabinetRoutes.dashboard,
        search: getDialogSearchParams(DialogName.VERIFICATION),
      });
    }
  }, [navigate, refetchProfile]);

  return (
    <Box>
      <OnboardingContainer title={t("onboarding.sumsub.header")} {...navigationParams}>
        {data && (
          // https://developers.sumsub.com/web-sdk/#introduction
          <SumsubWebSdk
            accessToken={data.accessToken!}
            expirationHandler={expirationHandler}
            onMessage={(type: any) => {
              if (type === "idCheck.onApplicantSubmitted") {
                client.invalidateQueries(profileQueryKeys.header());
                setSumsubComplete(true);
              }
            }}
            config={{
              uiConf: {
                customCssStr,
              },
            }}
            onError={(error: any) =>
              logError(error, {
                tags: {
                  sumsub: true,
                },
              })
            }
          />
        )}
      </OnboardingContainer>
      <Suspense fallback={<></>}>
        {!isMobile ? (
          <LazyOnboardingFooter
            backButtonFn={setBackStep}
            right={
              <Button disabled={!sumsubComplete || isFetching} onClick={onDone}>
                {t("button.done")}
              </Button>
            }
          />
        ) : (
          <OnboardingMobileNavigation onComplete={onDone} {...navigationParams} />
        )}
      </Suspense>
    </Box>
  );
};
