import { type FC, memo } from "react";

import { WidgetContainer } from "../widgets/container";
import { Analytics } from "./analytics";

type Props = {
  instrument: string;
};

const _AnalyticsContainer: FC<Props> = ({ instrument }) => {
  return (
    <WidgetContainer>
      {({ colors, language, token }) => (
        <Analytics colors={colors} language={language} token={token} instrument={instrument} />
      )}
    </WidgetContainer>
  );
};

const Component = memo(_AnalyticsContainer);

export { Component as AnalyticsContainer };
