import { NoContentMessage } from "@/components/no-content-message";
import { useTranslation } from "@/hooks/translator.hook";
import { TradingAccountType } from "@/services/openapi";

import { CreateAccountButton } from "../../components/create-account/create-account-button";

const DemoAccountsEmptyState = () => {
  const { t } = useTranslation();

  return (
    <NoContentMessage
      title={t("accounts.no-items.no-demo-title")}
      description={t("accounts.no-items.no-demo-description")}
    >
      <CreateAccountButton type={TradingAccountType.Demo} />
    </NoContentMessage>
  );
};

export { DemoAccountsEmptyState };
