import { type FC, type ReactNode } from "react";

import { TooltipQuestionIcon } from "@/components/icons";
import { Tooltip } from "@/shared/ui";

type Props = {
  children: ReactNode;
  title: string;
  description?: string;
};

const WidgetBlock: FC<Props> = ({ children, title, description }) => (
  <div className="flex flex-col gap-4 rounded-[16px] border border-card-border bg-card-bg p-4">
    <div className="flex items-center justify-between gap-2">
      <h4 className="font-gilroy text-[16px] font-semibold leading-[1.2] text-contrast-secondary">{title}</h4>
      {description && (
        <Tooltip content={description}>
          <TooltipQuestionIcon />
        </Tooltip>
      )}
    </div>
    {children}
  </div>
);

export { WidgetBlock };
