import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SubmitButton } from "@/app/form";
import { cn } from "@/shared/styles";

import { usePlaceOrderContext } from "../context";

type Props = {
  isDemoAccount: boolean;
  isPendingOrder: ReturnType<typeof usePlaceOrderContext>["isPendingOrder"];
  isBuyOrder: ReturnType<typeof usePlaceOrderContext>["isBuyOrder"];
  hasNoFreeMargin: ReturnType<typeof usePlaceOrderContext>["hasNoFreeMargin"];
  isMobile: boolean;
  isMobileSymbolPage: boolean;
};

const PlaceOrderSubmitButton: FC<Props> = ({
  hasNoFreeMargin,
  isBuyOrder,
  isDemoAccount,
  isPendingOrder,
  isMobile,
  isMobileSymbolPage,
}) => {
  const { t } = useTranslation();

  const label = useMemo(() => {
    if (hasNoFreeMargin) {
      if (isDemoAccount) {
        return t("terminal.place-order.submit-button.deposit-demo");
      }
      return t("terminal.place-order.submit-button.deposit-real");
    }
    if (isPendingOrder) {
      return t("terminal.place-order.submit-button.pending");
    }
    if (isBuyOrder) {
      return t("terminal.place-order.submit-button.market-buy");
    }
    return t("terminal.place-order.submit-button.market-sell");
  }, [hasNoFreeMargin, isBuyOrder, isDemoAccount, isPendingOrder, t]);

  return (
    <div
      className={cn("sticky bottom-0 mt-4 bg-surface-canvas px-4 pb-4", !isMobileSymbolPage && isMobile && "bottom-10")}
    >
      <SubmitButton variant="secondary" className="w-full">
        {label}
      </SubmitButton>
    </div>
  );
};

export { PlaceOrderSubmitButton };
