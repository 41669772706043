import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { TCWidgetColors } from "../helpers/colors";
import { getTCWidgetLanguage } from "../helpers/languages";
import { WidgetBlock } from "../widgets/block";

type Props = {
  token: string;
  language: string;
  instrument: string;
  colors: TCWidgetColors;
};

const News: FC<Props> = ({ colors, token, language, instrument }) => {
  const { t } = useTranslation();

  const {
    backgroundColor,
    baseColor,
    bearishColor,
    bullishColor,
    neutralColor,
    newsColor,
    primaryColor,
    socialColor,
    surfaceColor,
  } = colors;

  return (
    <WidgetBlock title={t("terminal.insights.widgets.news-feed.title")}>
      <tc-news-feed
        token={token}
        article-lang="en"
        lang={getTCWidgetLanguage(language)}
        instrument={instrument}
        base-color={baseColor}
        background-color={backgroundColor}
        surface-color={surfaceColor}
        primary-color={primaryColor}
        bearish-color={bearishColor}
        bullish-color={bullishColor}
        neutral-color={neutralColor}
        news-color={newsColor}
        social-color={socialColor}
        display-inside={true}
      />
    </WidgetBlock>
  );
};

export { News };
