import { FC, useCallback } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { Dialog, DialogTrigger } from "@/components/dialog";
import { NewDialogContent } from "@/components/new-dialog";
import { useTranslation } from "@/hooks/translator.hook";
import { getTerminalRoute } from "@/routes/terminal.routes";
import { TradingAccount, TradingServerPlatform, TradingServerUiPlatform } from "@/services/openapi";
import { Button } from "@/shared/ui";

import { TradeButtonDialogContent } from "./trade-button-dialog-content";

export type TradeButtonLinkItemType = {
  serverTerminalUrl: string;
  serverTerminalIOsUrl: string;
  serverTerminalAndroidUrl: string;
  serverTerminalMacOsUrl: string;
  serverTerminalLinuxOsUrl: string;
  platformType: TradingServerPlatform;
};

type Props = {
  account: TradingAccount;
};

export const accountSearchParamsName = "account";

export const TradeButton: FC<Props> = ({ account }) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const onOpen = useCallback(() => {
    setSearchParams(prevParams => {
      prevParams.set(accountSearchParamsName, account.id!);
      return prevParams;
    });
  }, [setSearchParams, account.id]);

  const onClose = useCallback(() => {
    setSearchParams(prevParams => {
      prevParams.delete(accountSearchParamsName);
      return prevParams;
    });
  }, [setSearchParams]);

  if (account.uiPlatform!.platform === TradingServerUiPlatform.Doto) {
    return (
      <Button size="sm" asChild data-test="account-trade-btn">
        <Link to={getTerminalRoute(account.id!)}>{t("button.trade")}</Link>
      </Button>
    );
  }

  return (
    <Dialog
      open={searchParams.get(accountSearchParamsName) === account.id}
      onOpenChange={flag => {
        if (flag) {
          onOpen();
        } else {
          onClose();
        }
      }}
    >
      <DialogTrigger asChild>
        <Button size="sm" data-test="account-trade-btn">
          {t("button.trade")}
        </Button>
      </DialogTrigger>
      <NewDialogContent title={account.uiPlatform!.description!}>
        <TradeButtonDialogContent account={account} />
      </NewDialogContent>
    </Dialog>
  );
};
