import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { track } from "@/app/libs/amplitude";
import { TradingAccountType, TradingServer } from "@/services/openapi";
import { toast, ToastIcons } from "@/shared/ui";
import { useCreateTradingAccountMutation } from "@/state/server/accounts";

import { CreateAccountForm, ICreateAccountFormValues } from "./create-account.form";
import { getCreateAccountSubmitValue } from "./helpers";

type Props = {
  type: TradingAccountType;
  servers: TradingServer[];
  onClose: () => void;
};

export const CreateAccountContainer: FC<Props> = ({ servers, onClose, type }) => {
  const { t } = useTranslation();

  const [_, setSearchParams] = useSearchParams();

  const { mutateAsync: createAccount } = useCreateTradingAccountMutation();

  const handleSubmit = useCallback(
    ({ platform, type, currency }: ICreateAccountFormValues) => {
      return createAccount(
        { createTradingAccountRequest: getCreateAccountSubmitValue({ platform, type, servers, currency }) },
        {
          onSuccess: () => {
            setSearchParams(prevParams => {
              prevParams.set("tab", type);
              return prevParams;
            });
            onClose();
            track("open_new_real_account");
            toast({
              title: t("success-message.account-created.title")!,
              description: t("success-message.account-created.description")!,
              icon: ToastIcons.SUCCESS,
            });
          },
        },
      );
    },
    [createAccount, onClose, servers, t, setSearchParams],
  );

  if (!servers) return null;

  return <CreateAccountForm servers={servers} onSubmit={handleSubmit} type={type} />;
};
