import { FC, memo, useEffect, useState } from "react";

import { styled } from "@/styles";

const StyledValue = styled("div", {
  borderRadius: "4px",
  display: "inline-block",
  p: 4,
  minWidth: 60,

  variants: {
    color: {
      negative: {
        border: "1px solid",
        borderColor: "$negativeDefault",
      },
      positive: {
        border: "1px solid",
        borderColor: "$positiveDefault",
      },
    },
  },
});

const getTextColor = (value: number) => {
  if (value > 0) return "positive";
  if (value < 0) return "negative";
  return;
};

interface Props {
  trigger: number;
  value: number;
}

const _WatchStatefulValue: FC<Props> = ({ value, trigger }) => {
  const [prevLastValue, setPrevLastValue] = useState(value);
  const [currentLastValue, setCurrentLastValue] = useState(value);

  useEffect(() => {
    setPrevLastValue(currentLastValue);
    setCurrentLastValue(value);
  }, [trigger]);

  const color = getTextColor(value - prevLastValue);

  return <StyledValue color={color}>{value}</StyledValue>;
};

export const WatchStatefulValue = memo(_WatchStatefulValue);
