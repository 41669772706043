// https://www.tradingview.com/charting-library-docs/latest/ui_elements/drawings/
// https://www.tradingview.com/charting-library-docs/latest/ui_elements/drawings/Drawings-List

import { AccessList } from "public/charting_library/charting_library";

const cursors = ["Cross", "Dot", "Arrow", "Eraser"];
const annotationsTools = [
  "Text",
  "Anchored Text",
  "Note",
  "Anchored Note",
  "Signpost",
  "Callout",
  "Comment",
  "Price Label",
  "Price Note",
  "Arrow Marker",
  "Arrow Mark Left",
  "Arrow Mark Right",
  "Arrow Mark Up",
  "Arrow Mark Down",
  "Flag Mark",
  "Balloon",
];
const other = ["Font Icons"];

const getDrawingAccess = (): AccessList => ({
  type: "black",
  tools: [...cursors, ...annotationsTools, ...other].map(name => ({ name })),
});

export { getDrawingAccess };
