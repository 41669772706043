import { type FC, useCallback, useState } from "react";

import { Flex } from "@/components/flex";
import { Grid } from "@/components/grid";
import { Pagination } from "@/components/pagination";
import { FundsTx } from "@/services/openapi";

import { TransactionCard } from "./transaction-card";

interface TransactionGridProps {
  items: FundsTx[];
}

const PAGE_SIZE = 5;

export const TransactionGrid: FC<TransactionGridProps> = ({ items }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const visibleItems = items.slice(startIndex, endIndex);

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "8px",
        marginTop: "16px",
      }}
    >
      {visibleItems.map((item: FundsTx) => (
        <TransactionCard key={item.id} transaction={item} />
      ))}
      <></>
      <Grid css={{ placeItems: "center", marginTop: "24px" }}>
        <Pagination changePage={onPageChange} currentPage={currentPage} pageSize={PAGE_SIZE} total={items?.length} />
      </Grid>
    </Flex>
  );
};
