import { type FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Chip } from "@/shared/ui";

import { useCurrentSymbolContext } from "../contexts/current-symbol-context";
import { AnalyticsContainer } from "./analytics/container";
import { NewsContainer } from "./news/container";

enum Tabs {
  ANALYTICS = "analalytics",
  NEWS = "news",
  CALENDAR = "calendar",
}

const InsightsContainer: FC = () => {
  const { t } = useTranslation();

  const { symbolInfo } = useCurrentSymbolContext();
  const instrument = symbolInfo.nameTradingCentral!;

  const [tab, setTab] = useState<Tabs>(Tabs.ANALYTICS);

  return (
    <>
      <Chip.Group value={tab} onValueChange={setTab} className="mb-4">
        <Chip value={Tabs.ANALYTICS}>{t("terminal.insights.tabs.analytics")}</Chip>
        <Chip value={Tabs.NEWS}>{t("terminal.insights.tabs.news")}</Chip>
        {/* <Chip value={Tabs.CALENDAR}>{t("terminal.insights.tabs.calendar")}</Chip> */}
      </Chip.Group>
      {tab === Tabs.ANALYTICS && <AnalyticsContainer instrument={instrument} />}
      {tab === Tabs.NEWS && <NewsContainer instrument={instrument} />}
    </>
  );
};

export { InsightsContainer };
