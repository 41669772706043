import { CSSProperties, FC } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";

import { Box } from "@/components/box";
import { useTerminalAccountContext } from "@/features/terminal/contexts/account.context";
import { MergedTerminalSymbol } from "@/features/terminal/helpers/symbols";
import { useTranslation } from "@/hooks/translator.hook";
import { styled } from "@/styles";

import { WatchlistStyledHead, WatchlistStyledRow } from "./styles";
import { WatchlistTableRow } from "./watchlist-table-row";

const StyledList = styled(List, {
  hideScroll: true,
});

const ItemRenderer = ({
  index,
  data,
  style,
}: {
  style: CSSProperties;
  index: number;
  data: {
    symbols: MergedTerminalSymbol[];
    accountId: string;
    isWatchlist: boolean;
    setSymbol: (symbol: string) => void;
    chartFavoritesCount: number;
    onClose?: () => void;
  };
}) => {
  const { symbols, chartFavoritesCount, isWatchlist, accountId, onClose, setSymbol } = data;
  return (
    <div style={style} key={symbols[index].symbol}>
      <WatchlistTableRow
        isWatchlist={isWatchlist}
        isFavorite={symbols[index].isFavorite!}
        isChartFavorite={symbols[index].isChartFavorite!}
        symbol={symbols[index].symbol!}
        ask={symbols[index].ask}
        bid={symbols[index].bid}
        eventTime={symbols[index].eventTime}
        onClose={onClose}
        accountId={accountId}
        chartFavoritesCount={chartFavoritesCount}
        setSymbol={setSymbol}
      />
    </div>
  );
};

type Props = {
  symbols: MergedTerminalSymbol[];
  isWatchlist: boolean;
  chartFavoritesCount: number;
  setSymbol: (symbol: string) => void;
  onClose?: () => void;
};

export const WatchlistTable: FC<Props> = ({ symbols, isWatchlist, onClose, chartFavoritesCount, setSymbol }) => {
  const { t } = useTranslation();
  const { account } = useTerminalAccountContext();

  return (
    <Box css={{ height: "100%", overflow: "auto", display: "flex", flexDirection: "column" }}>
      <WatchlistStyledRow css={{ height: "auto" }}>
        <WatchlistStyledHead wide css={{ minWidth: 150 }}>
          {t("terminal.symbol")}
        </WatchlistStyledHead>
        {isWatchlist && (
          <>
            <WatchlistStyledHead css={{ width: "80px", textAlign: "end" }}>{t("terminal.bid")}</WatchlistStyledHead>
            <WatchlistStyledHead css={{ width: "80px", textAlign: "end" }}>{t("terminal.ask")}</WatchlistStyledHead>
          </>
        )}
        <WatchlistStyledHead css={{ width: "40px", flexShrink: 0 }}></WatchlistStyledHead>
      </WatchlistStyledRow>
      <Box css={{ height: "100%", overflow: "auto" }}>
        <AutoSizer>
          {({ height, width }) => (
            <StyledList
              height={height}
              width={width}
              itemData={{ symbols, isWatchlist, onClose, chartFavoritesCount, accountId: account.id!, setSymbol }}
              itemCount={symbols.length}
              itemSize={56}
            >
              {/* @ts-ignore */}
              {ItemRenderer}
            </StyledList>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
};
