import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { useCurrentSymbolContext } from "@/features/terminal/contexts/current-symbol-context";
import { useExtendedOrdersContext } from "@/features/terminal/contexts/extended-orders.context";
import { useOrdersContext } from "@/features/terminal/contexts/orders.context";
import { useLayoutContext } from "@/features/terminal/layout/context";

import { TradingTableEmptyState } from "../../ui/empty-state";
import { TradingTable } from "../table";
import { PendingOrdersBodyRow } from "./body-row";
import { PendingOrdersHeaderRow } from "./header-row";

type Props = {
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
  orders: ReturnType<typeof useExtendedOrdersContext>["extendedPendingOrders"];
  setSymbol: ReturnType<typeof useCurrentSymbolContext>["setSymbol"];
  ordersIsLoading: ReturnType<typeof useOrdersContext>["ordersIsLoading"];
  openWatchlist: ReturnType<typeof useLayoutContext>["openWatchlist"];
};

const PendingOrdersTable: FC<Props> = ({
  orders,
  accountId,
  currency,
  openWatchlist,
  ordersIsLoading,
  setSymbol,
  currencyDecimalScale,
}) => {
  const { t } = useTranslation();

  return (
    <TradingTable
      items={orders}
      emptyState={
        <TradingTableEmptyState
          title={t("terminal.empty-state.pending-title")}
          description={t("terminal.empty-state.pending-description")}
          action={
            <TradingTableEmptyState.Button onClick={openWatchlist}>
              {t("terminal.go-to-watchlist")}
            </TradingTableEmptyState.Button>
          }
        />
      }
      isLoading={ordersIsLoading}
      renderHeader={() => (
        <PendingOrdersHeaderRow accountId={accountId} ordersCount={orders.length} currency={currency} />
      )}
      renderRow={order => (
        <PendingOrdersBodyRow
          key={order.id}
          order={order}
          accountId={accountId}
          setSymbol={setSymbol}
          currency={currency}
          currencyDecimalScale={currencyDecimalScale}
        />
      )}
    />
  );
};

export { PendingOrdersTable };
