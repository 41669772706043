import { accountsService } from "../api";
import {
  AccountsApiArchiveAccountRequest,
  AccountsApiChangeAccountLeverageRequest,
  AccountsApiChangeAccountMainPasswordRequest,
  AccountsApiChangeAccountNameRequest,
  AccountsApiChangeAccountTradeModeRequest,
  AccountsApiChangeAccountUiPlatformRequest,
  AccountsApiCreateTradingAccountRequest,
  AccountsApiGetLastAccountRequest,
  AccountsApiGetTradingAccountRequest,
  AccountsApiGetTradingAccountsRequest,
  AccountsApiGetTradingAccountsTotalBalanceRequest,
  AccountsApiMakeDemoDepositRequest,
  AccountsApiRestoreAccountRequest,
  AccountsApiUpdateLastActionRequest,
} from "../openapi";

export const getTradingAccounts = async (values: AccountsApiGetTradingAccountsRequest) =>
  await (
    await accountsService().getTradingAccounts(values)
  ).data;

export const getTradingAccount = async (values: AccountsApiGetTradingAccountRequest) =>
  await (
    await accountsService().getTradingAccount(values)
  ).data;

export const restoreAccount = async (values: AccountsApiRestoreAccountRequest) =>
  await (
    await accountsService().restoreAccount(values)
  ).data;

export const makeDemoDeposit = async (values: AccountsApiMakeDemoDepositRequest) =>
  await (
    await accountsService().makeDemoDeposit(values)
  ).data;

export const getServers = async () => await (await accountsService().getServers()).data;

export const createTradingAccount = async (values: AccountsApiCreateTradingAccountRequest) =>
  await (
    await accountsService().createTradingAccount(values)
  ).data;

export const getTradingAccountsTotalBalance = async (values: AccountsApiGetTradingAccountsTotalBalanceRequest) =>
  await (
    await accountsService().getTradingAccountsTotalBalance(values)
  ).data;

export const changeAccountName = async (values: AccountsApiChangeAccountNameRequest) =>
  await (
    await accountsService().changeAccountName(values)
  ).data;

export const changeAccountLeverage = async (values: AccountsApiChangeAccountLeverageRequest) =>
  await (
    await accountsService().changeAccountLeverage(values)
  ).data;

export const archiveAccount = async (values: AccountsApiArchiveAccountRequest) =>
  await (
    await accountsService().archiveAccount(values)
  ).data;

export const changeAccountMainPassword = async (values: AccountsApiChangeAccountMainPasswordRequest) =>
  await (
    await accountsService().changeAccountMainPassword(values)
  ).data;

export const changeAccountUiPlatform = async (values: AccountsApiChangeAccountUiPlatformRequest) =>
  await (
    await accountsService().changeAccountUiPlatform(values)
  ).data;

export const changeAccountTradeMode = async (values: AccountsApiChangeAccountTradeModeRequest) =>
  await (
    await accountsService().changeAccountTradeMode(values)
  ).data;

export const getLastAccount = async (values: AccountsApiGetLastAccountRequest) =>
  await (
    await accountsService().getLastAccount(values)
  ).data;

export const updateLastAccount = async (values: AccountsApiUpdateLastActionRequest) =>
  await (
    await accountsService().updateLastAction(values)
  ).data;
