export const OkIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22ZM11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20ZM15.7071 9.70711L14.2929 8.29289L11 11.5858L7.70711 8.29289L6.29289 9.70711L11 14.4142L15.7071 9.70711Z"
      fill="#08D74F"
    />
  </svg>
);

export const CircleOkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 25 24">
    <rect width="24" height="24" x="0.333" fill="currentColor" rx="12"></rect>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M10.805 14.529L17 8.333l.943.943-7.138 7.138L7 12.61l.943-.942 2.862 2.862z"
      clipRule="evenodd"
    ></path>
  </svg>
);
