import * as DialogPrimitive from "@radix-ui/react-dialog";
import React, { forwardRef } from "react";

import { useScreenSize } from "@/hooks/screen-size.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { CSS, styled } from "@/styles";

import { CloseIcon } from "../icons";
import { UnstyledButton } from "../unstyled-button.tsx";
import { overlayStyles, panelStyles } from "./styles";

/** @deprecated Перенесен в \@/domaint/ui-kit оставлен для обратной совместимости */
const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;

const StyledOverlay = styled(DialogPrimitive.Overlay, overlayStyles, {
  position: "fixed",
  zIndex: 50,
  inset: "0",

  display: "grid",
  placeItems: "center",
  overflowY: "auto",
  overflowX: "hidden",
});

const StyledContent = styled(DialogPrimitive.Content, panelStyles, {
  width: "100%",
  height: "100%",

  position: "relative",
  zIndex: 50,

  p: "24px 16px",

  "@bp2": {
    p: "60px",
    mt: "5vh",
    mb: "30px",
    height: "auto",
  },

  variants: {
    size: {
      xs: {
        "@bp2": {
          maxWidth: "368px",
        },
      },
      small: {
        "@bp2": {
          maxWidth: "475px",
        },
      },
      middle: {
        "@bp2": {
          maxWidth: "526px",
        },
      },
    },
    noSidePadding: {
      true: {
        p: "24px 0",

        "@bp2": {
          p: "60px 0",
        },
      },
    },
  },

  "&:focus": {
    outline: "none",
  },

  defaultVariants: {
    size: "middle",
  },
});

const IconButton = styled(UnstyledButton, {
  borderRadius: "$round",
  height: 35,
  width: 35,
  display: "grid",
  placeItems: "center",
  position: "absolute",
  top: 12,
  insetInlineEnd: 17,
  color: "$textMain",

  "&:hover": { backgroundColor: "$bgBorder" },
  "&:focus": { boxShadow: "0 0 0 2px $colors$bgBorder" },
  "&:active": { transform: "scale(0.9)" },
});

type DialogContentProps = React.ComponentProps<typeof StyledContent> & {
  css?: CSS;
  closeBtnCSS?: CSS;
};

const DialogContent = forwardRef<React.ElementRef<typeof StyledContent>, DialogContentProps>(
  ({ children, closeBtnCSS, ...props }, forwardedRef) => {
    const { t } = useTranslation();
    const { isLargeDesktop } = useScreenSize();

    return (
      <DialogPrimitive.Portal>
        <StyledOverlay>
          <StyledContent
            onOpenAutoFocus={e => !isLargeDesktop && e.preventDefault()}
            onCloseAutoFocus={e => !isLargeDesktop && e.preventDefault()}
            {...props}
            ref={forwardedRef}
          >
            {children}
            <DialogPrimitive.Close asChild>
              <IconButton aria-label={t("button.close")!} css={closeBtnCSS}>
                <CloseIcon />
              </IconButton>
            </DialogPrimitive.Close>
          </StyledContent>
        </StyledOverlay>
      </DialogPrimitive.Portal>
    );
  },
);
DialogContent.displayName = "DialogContent";

const DialogClose = DialogPrimitive.Close;
const DialogTitle = DialogPrimitive.Title;
const DialogDescription = DialogPrimitive.Description;

const DialogButtons = ({ children }: { children?: React.ReactNode }) => {
  return <div className="mt-[24px] flex flex-row-reverse flex-wrap gap-4 *:flex-1 md:mt-[32px]">{children}</div>;
};

export { Dialog, DialogClose, DialogContent, DialogDescription, DialogTitle, DialogTrigger, DialogButtons };
