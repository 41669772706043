import { type FC, Fragment } from "react";

import { useCurrentSymbolContext } from "../../contexts/current-symbol-context";
import { PlaceOrderContainer } from "../container";
import { TerminalPlaceOrderProvider } from "../context";
import { TradingUnavailableBlock } from "../trading-unavailable/block";

const MobilePlaceOrderBlock: FC = () => {
  const { currentSymbol, isTradingAvailable } = useCurrentSymbolContext();

  return (
    <Fragment key={currentSymbol}>
      {isTradingAvailable.available ? (
        <TerminalPlaceOrderProvider key={currentSymbol}>
          <PlaceOrderContainer />
        </TerminalPlaceOrderProvider>
      ) : (
        <TradingUnavailableBlock />
      )}
    </Fragment>
  );
};

export { MobilePlaceOrderBlock };
