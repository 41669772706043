import { IOrderLineAdapter } from "public/charting_library/charting_library";
import React, { memo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { TerminalCloseOrderType } from "@/services/openapi";
import { toast, ToastIcons } from "@/shared/ui";
import { useCloseTerminalOrdersMutation, useModifyTerminalOrderMutation } from "@/state/server/terminal";

import { LiveOrder } from "../../helpers/orders";
import { getOrderTypeText, getTypeColor } from "../../trading-tables/components/trading-table/order-type";
import { ChartColors } from "../config/colors";

type Props = {
  order: LiveOrder;
  accountId: string;
  volumeDecimalScale: number;
  chartColors: ChartColors;
};

const _ChartPendingOrder: React.FC<Props> = ({ order, accountId, volumeDecimalScale, chartColors }) => {
  const { t } = useTranslation();

  const ref = useRef<IOrderLineAdapter | null>(null);

  const { mutate: modifyOrder } = useModifyTerminalOrderMutation();
  const { mutate: closeOrder } = useCloseTerminalOrdersMutation({
    onSuccess: () => {
      toast({
        icon: ToastIcons.SUCCESS,
        text: t("terminal.messages.pending-order-cancelled", {
          id: order.volume,
          type: getOrderTypeText(t, order.type),
          volume: order.volume.toFixed(volumeDecimalScale),
          symbol: order.symbol,
        }),
      });
    },
  });

  useEffect(() => {
    window.tvWidget.activeChart().dataReady(() => {
      // https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.IChartWidgetApi#createorderline
      try {
        ref.current = window.tvWidget
          .activeChart()
          .createOrderLine({ disableUndo: true })
          .setLineStyle(5)
          .onCancel(() => {
            closeOrder({
              tradingAccountId: accountId,
              terminalCloseOrderRequest: { id: order.id, type: TerminalCloseOrderType.Limit },
            });
          })
          // updatable params
          .setLineColor(
            getTypeColor(order.type) === "positive" ? chartColors.positivePressed : chartColors.negativePressed,
          )
          .setQuantityTextColor(chartColors.orderLineText)
          .setBodyBackgroundColor(chartColors.orderLineBackground)
          .setBodyBorderColor(chartColors.orderLineBackground)
          .setCancelButtonBackgroundColor(chartColors.orderLineBackground)
          .setCancelButtonBorderColor(chartColors.orderLineBackground)
          .setCancelButtonIconColor(chartColors.iconClose)
          .setQuantityBackgroundColor(chartColors.orderLineBackground)
          .setQuantityBorderColor(chartColors.orderLineBackground)
          .setBodyTextColor(chartColors.orderLineText)
          .setQuantity(String(order.volume))
          .setPrice(order.price)
          .setText(`${getOrderTypeText(t, order.type)}:`)
          .onMove(() => {
            const newPrice = ref.current!.getPrice();

            modifyOrder(
              {
                tradingAccountId: accountId,
                id: order.id,
                terminalModifyOrderRequest: { stopLoss: order.stopLoss, takeProfit: order.takeProfit, price: newPrice },
              },
              {
                onError: () => {
                  ref.current!.setPrice(order.price);
                },
              },
            );
          });
      } catch (e) {
        console.log(e, "pending chart open order");
      }
    });

    return () => {
      ref.current && ref.current.remove();
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      window.tvWidget.activeChart().dataReady(() => {
        ref
          .current!.setLineColor(
            getTypeColor(order.type) === "positive" ? chartColors.positivePressed : chartColors.negativePressed,
          )
          .setQuantityTextColor(chartColors.orderLineText)
          .setBodyBackgroundColor(chartColors.orderLineBackground)
          .setBodyBorderColor(chartColors.orderLineBackground)
          .setCancelButtonBackgroundColor(chartColors.orderLineBackground)
          .setCancelButtonBorderColor(chartColors.orderLineBackground)
          .setCancelButtonIconColor(chartColors.iconClose)
          .setQuantityBackgroundColor(chartColors.orderLineBackground)
          .setQuantityBorderColor(chartColors.orderLineBackground)
          .setBodyTextColor(chartColors.orderLineText);
      });
    }
  }, [chartColors]);

  useEffect(() => {
    if (ref.current) {
      window.tvWidget.activeChart().dataReady(() => {
        ref
          .current!.setQuantity(String(order.volume))
          .setLineColor(
            getTypeColor(order.type) === "positive" ? chartColors.positivePressed : chartColors.negativePressed,
          )
          .setPrice(order.price)
          .setText(`${getOrderTypeText(t, order.type)}:`)
          .onMove(() => {
            const newPrice = ref.current!.getPrice();

            modifyOrder(
              {
                tradingAccountId: accountId,
                id: order.id,
                terminalModifyOrderRequest: { stopLoss: order.stopLoss, takeProfit: order.takeProfit, price: newPrice },
              },
              {
                onError: () => {
                  ref.current!.setPrice(order.price);
                },
              },
            );
          });
      });
    }
  }, [order]);

  return null;
};

const ChartPendingOrder = memo(_ChartPendingOrder);

ChartPendingOrder.displayName = "ChartPendingOrder";

export { ChartPendingOrder };
