import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { BottomSheet, BottomSheetContent } from "@/components/bottom-sheet";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { Button } from "@/shared/ui";

import img from "./assets/Shield.png";
import { ContentProps } from "./types";
import { fromDepositState } from "./verification.container";

export const DepositVerificationBottomSheet: FC<ContentProps> = ({
  open,
  onOpenChange,
  submitDeposit,
  isSurveyMandatory,
  depositLimit,
  withdrawLimit,
  isSurveyCompleted,
}) => {
  const { t } = useTranslation();

  return (
    <BottomSheet open={open} onOpenChange={onOpenChange}>
      <BottomSheetContent
        image={{
          src: img,
        }}
        title={
          isSurveyMandatory && !isSurveyCompleted
            ? t("deposit.verification.dialog.mandatory.title")!
            : t("deposit.verification.dialog.title")!
        }
        description={
          isSurveyMandatory && !isSurveyCompleted
            ? t("deposit.verification.dialog.mandatory.description")!
            : t("deposit.verification.dialog.description", {
                depositAmount: depositLimit,
                currency: "USD",
                withdrawAmount: withdrawLimit,
              })!
        }
      >
        <div className="px-6">
          {isSurveyCompleted && (
            <Button variant="secondary" className="w-full" onClick={submitDeposit}>
              {t("deposit.verification.dialog.button-payment")}
            </Button>
          )}

          <Button variant="tertiary" className="mt-3 w-full" asChild>
            <Link to={onboardingRoutes.onboarding} state={fromDepositState}>
              {t("deposit.verification.dialog.button-verify")}
            </Link>
          </Button>
        </div>
      </BottomSheetContent>
    </BottomSheet>
  );
};
