import { t } from "i18next";
import { FC } from "react";

import { Dialog, DialogContent, DialogTitle } from "@/components/dialog";
import { DialogTitleText } from "@/components/dialog/styles";
import { LanguagesList } from "@/features/language-switcher/components/languages-list";

import { LanguageDialogProps } from "../types";

export const LanguageSwitcherDialog: FC<LanguageDialogProps> = ({
  open,
  languagesInfo,
  onOpenChange,
  onLocaleChange,
}) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent noSidePadding css={{ py: "24px" }} closeBtnCSS={{ top: "23px" }} size="xs">
        <DialogTitle asChild>
          <DialogTitleText size={4} css={{ mb: "24px", "@bp2": { mb: "24px" } }}>
            {t("language-switcher.title")}
          </DialogTitleText>
        </DialogTitle>
        <LanguagesList languagesInfo={languagesInfo} onChange={onLocaleChange} />
      </DialogContent>
    </Dialog>
  );
};
