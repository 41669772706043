import { FC, useCallback } from "react";

import { TradingAccount } from "@/services/openapi";
import { useChangeAccountPlatformMutation } from "@/state/server/accounts";

import { ChangeAccountPlatformForm, IChangeAccountPlatformFormValues } from "./form";

type Props = {
  account: TradingAccount;
  onClose: () => void;
};

export const ChangeAccountPlatformContainer: FC<Props> = ({ account, onClose }) => {
  const { mutateAsync: changePlatform } = useChangeAccountPlatformMutation({ successMessage: "platform-changed" });

  const handleSubmit = useCallback(
    ({ platform }: IChangeAccountPlatformFormValues) => {
      return changePlatform(
        { id: account.id!, platform },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    },
    [changePlatform, account, onClose],
  );

  return <ChangeAccountPlatformForm platform={account.uiPlatform!.platform!} onSubmit={handleSubmit} />;
};
