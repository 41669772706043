import { useTheme } from "@/contexts/theme.context";
import { darkTheme, theme } from "@/styles";

interface TCWidgetColors {
  baseColor: string;
  backgroundColor: string;
  surfaceColor: string;
  primaryColor: string;
  bearishColor: string;
  bullishColor: string;
  neutralColor: string;
  newsColor: string;
  socialColor: string;
}

const TCWidgetLightColors: TCWidgetColors = {
  baseColor: theme.colors.contrastPrimary.value,
  backgroundColor: theme.colors.elevation1.value,
  surfaceColor: theme.colors.surfaceUnderCanvas.value,
  primaryColor: theme.colors.contrastPrimary.value,
  bearishColor: theme.colors.negativeBgDefault.value,
  bullishColor: theme.colors.positiveBgDefault.value,
  neutralColor: theme.colors.constantLabel.value,
  newsColor: theme.colors.positiveText.value,
  socialColor: theme.colors.positiveText.value,
};

const TCWidgetDarkColors: TCWidgetColors = {
  baseColor: darkTheme.colors.contrastPrimary.value,
  backgroundColor: darkTheme.colors.elevation1.value,
  surfaceColor: darkTheme.colors.surfaceUnderCanvas.value,
  primaryColor: darkTheme.colors.contrastPrimary.value,
  bearishColor: darkTheme.colors.negativeBgDefault.value,
  bullishColor: darkTheme.colors.positiveBgDefault.value,
  neutralColor: darkTheme.colors.constantLabel.value,
  newsColor: darkTheme.colors.positiveText.value,
  socialColor: darkTheme.colors.positiveText.value,
};

const useTCWidgetColors = () => {
  const { isDarkTheme } = useTheme();
  return isDarkTheme ? TCWidgetDarkColors : TCWidgetLightColors;
};

export { TCWidgetLightColors, TCWidgetDarkColors, useTCWidgetColors };
export type { TCWidgetColors };
