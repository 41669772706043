import { FC, useCallback, useEffect } from "react";

import { Dialog, DialogContent } from "@/components/dialog";
import { AnyObjectType } from "@/constants/types";
import { SurveyGroup } from "@/services/openapi";
import { useUpdateSurveyMutation } from "@/state/server/kyc";

import { NavigationTypes } from "../navigation/navigation.helpers";
import { DeclarationDialogContent } from "../sumsub/declaration-dialog-content";
import { SurveyForm } from "./survey.form";
import { createSurveyRequestItems, useDeclarationDialog } from "./survey.helpers";

interface Props {
  email: string;
  surveyGroup: SurveyGroup;
  navigationParams: NavigationTypes;
  setBackStep: () => void;
  setNextStep: () => void;
}

export const SurveyContainer: FC<Props> = ({ setBackStep, setNextStep, surveyGroup, navigationParams }) => {
  const { mutateAsync: updateSurvey } = useUpdateSurveyMutation();

  const { onDepositFunds, onClose, onUploadDocuments, open } = useDeclarationDialog(setNextStep);

  const isDeclaration = surveyGroup.title === "Declaration";

  const submitSurvey = useCallback(
    (values: AnyObjectType) => {
      return updateSurvey(
        {
          surveyUpdate: {
            surveyGroupId: surveyGroup.id!,
            items: createSurveyRequestItems(values),
          },
        },
        {
          onSuccess: () => {
            // if (isDeclaration) {
            //   onOpen();
            // } else {
            //   setNextStep();
            // }

            setNextStep();
          },
        },
      );
    },
    [updateSurvey, setNextStep, surveyGroup.id],
  );

  useEffect(() => {
    if (isDeclaration) window.scrollTo(0, 0);
  }, [isDeclaration]);

  return (
    <>
      <SurveyForm
        surveyGroup={surveyGroup}
        navigationParams={navigationParams}
        onSubmit={submitSurvey}
        setBackStep={setBackStep}
      />
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent>
          <DeclarationDialogContent onDepositFunds={onDepositFunds} onUploadDocuments={onUploadDocuments} />
        </DialogContent>
      </Dialog>
    </>
  );
};
