import { TerminalDealType } from "@/services/openapi";

import { MergedTerminalSymbol } from "../helpers/symbols";

const getOrderCurrentPrice = ({
  type,
  ask,
  bid,
  inverse,
}: {
  type: TerminalDealType;
  ask: number | undefined;
  bid: number | undefined;
  inverse?: boolean;
}) => {
  switch (type) {
    case TerminalDealType.Sell:
    case TerminalDealType.BuyLimit:
    case TerminalDealType.BuyStop:
      return inverse ? bid : ask;
    case TerminalDealType.Buy:
    case TerminalDealType.SellLimit:
    case TerminalDealType.SellStop:
      return inverse ? ask : bid;
  }
};

const getBidAsk = ({
  ask,
  bid,
  symbol,
  symbolInfo,
}: {
  ask: number | undefined;
  bid: number | undefined;
  symbol: string;
  symbolInfo: MergedTerminalSymbol;
}) => {
  if (symbolInfo.symbol === symbol) {
    return { ask: symbolInfo.ask || 0, bid: symbolInfo.bid || 0 };
  }

  return { ask: ask || 0, bid: bid || 0 };
};

export { getOrderCurrentPrice, getBidAsk };
