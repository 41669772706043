import { FC, lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";

import { Box } from "@/components/box";
import { Grid } from "@/components/grid";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { styled } from "@/styles/stitches.config";

const OnboardingHeader = lazy(() =>
  import("./onboarding-header").then(module => ({ default: module.OnboardingHeader })),
);

const Wrapper = styled(Grid, {
  background: "$lightGray",
  minHeight: "100%",
  gridTemplateRows: "auto 1fr",
});

const Container = styled(Box, {
  mx: "auto",
  px: "24px",
  maxWidth: "$appContainer",
  mt: "32px",
});

export const OnboardingLayout: FC = () => {
  const { isMobile } = useScreenSize();

  return (
    <>
      <Wrapper>
        {!isMobile && (
          <Suspense fallback={<></>}>
            <OnboardingHeader />
          </Suspense>
        )}
        <Box>
          <Container>
            <Outlet />
          </Container>
        </Box>
      </Wrapper>
    </>
  );
};
