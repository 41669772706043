import { terminalService } from "../api";
import {
  TerminalApiAddLastIndicatorRequest,
  TerminalApiAddOrUpdateChartDataRequest,
  TerminalApiAddStudyTemplateDataRequest,
  TerminalApiAddSymbolToChartsRequest,
  TerminalApiAddSymbolToFavoritesRequest,
  TerminalApiCloseOrdersRequest,
  TerminalApiGetAccountSymbolsRequest,
  TerminalApiGetChartDataRequest,
  TerminalApiGetChartsMetaInfoRequest,
  TerminalApiGetLastIndicatorRequest,
  TerminalApiGetStudyTemplateDataRequest,
  TerminalApiGetStudyTemplatesMetaInfoRequest,
  TerminalApiGetSymbolChartFirstDateRequest,
  TerminalApiGetSymbolChartsRequest,
  TerminalApiGetSymbolsLastPricesRequest,
  TerminalApiGetSymbolsRequest,
  TerminalApiGetTerminalAccountTokenRequest,
  TerminalApiModifyOrderRequest,
  TerminalApiOpenOrderRequest,
  TerminalApiPositionsHistoryRequest,
  TerminalApiRemoveChartDataRequest,
  TerminalApiRemoveStudyTemplateDataRequest,
  TerminalApiRemoveSymbolFromChartsRequest,
  TerminalApiRemoveSymbolFromFavoritesRequest,
  TerminalApiUpdateFavoriteSymbolsGroupRequest,
} from "../openapi";

export const getTerminalSymbols = async (values: TerminalApiGetSymbolsRequest) =>
  await (
    await terminalService().getSymbols(values)
  ).data;

export const getSymbolCharts = async (values: TerminalApiGetSymbolChartsRequest) =>
  await (
    await terminalService().getSymbolCharts(values)
  ).data;

export const getSymbolChartFirstDate = async (values: TerminalApiGetSymbolChartFirstDateRequest) =>
  await (
    await terminalService().getSymbolChartFirstDate(values)
  ).data;

export const getSymbolsLastPrices = async (values: TerminalApiGetSymbolsLastPricesRequest) =>
  await (
    await terminalService().getSymbolsLastPrices(values)
  ).data;

export const getTerminalPositionsHistory = async (values: TerminalApiPositionsHistoryRequest) =>
  await (
    await terminalService().positionsHistory(values)
  ).data;

export const getTerminalAccountSymbols = async (values: TerminalApiGetAccountSymbolsRequest) =>
  await (
    await terminalService().getAccountSymbols(values)
  ).data;

export const modifyTerminalOrder = async (values: TerminalApiModifyOrderRequest) =>
  await (
    await terminalService().modifyOrder(values)
  ).data;

export const closeTerminalOrders = async (values: TerminalApiCloseOrdersRequest) =>
  await (
    await terminalService().closeOrders(values)
  ).data;

export const openTerminalOrder = async (values: TerminalApiOpenOrderRequest) =>
  await (
    await terminalService().openOrder(values)
  ).data;

export const addSymbolToCharts = async (values: TerminalApiAddSymbolToChartsRequest) =>
  await (
    await terminalService().addSymbolToCharts(values)
  ).data;

export const removeSymbolFromCharts = async (values: TerminalApiRemoveSymbolFromChartsRequest) =>
  await (
    await terminalService().removeSymbolFromCharts(values)
  ).data;

export const addSymbolToFavorites = async (values: TerminalApiAddSymbolToFavoritesRequest) =>
  await (
    await terminalService().addSymbolToFavorites(values)
  ).data;

export const removeSymbolFromFavorites = async (values: TerminalApiRemoveSymbolFromFavoritesRequest) =>
  await (
    await terminalService().removeSymbolFromFavorites(values)
  ).data;

export const updateFavoriteSymbolsGroup = async (values: TerminalApiUpdateFavoriteSymbolsGroupRequest) =>
  await (
    await terminalService().updateFavoriteSymbolsGroup(values)
  ).data;

export const getTerminalAccountToken = async (values: TerminalApiGetTerminalAccountTokenRequest) =>
  await (
    await terminalService().getTerminalAccountToken(values)
  ).data;

export const addStudyTemplateData = async (values: TerminalApiAddStudyTemplateDataRequest) =>
  await (
    await terminalService().addStudyTemplateData(values)
  ).data;

export const getStudyTemplateData = async (values: TerminalApiGetStudyTemplateDataRequest) =>
  await (
    await terminalService().getStudyTemplateData(values)
  ).data;

export const removeStudyTemplateData = async (values: TerminalApiRemoveStudyTemplateDataRequest) =>
  await (
    await terminalService().removeStudyTemplateData(values)
  ).data;

export const getStudyTemplatesMetaInfo = async (values: TerminalApiGetStudyTemplatesMetaInfoRequest) =>
  await (
    await terminalService().getStudyTemplatesMetaInfo(values)
  ).data;

export const addOrUpdateChartData = async (values: TerminalApiAddOrUpdateChartDataRequest) =>
  await (
    await terminalService().addOrUpdateChartData(values)
  ).data;

export const getChartData = async (values: TerminalApiGetChartDataRequest) =>
  await (
    await terminalService().getChartData(values)
  ).data;

export const removeChartData = async (values: TerminalApiRemoveChartDataRequest) =>
  await (
    await terminalService().removeChartData(values)
  ).data;

export const getChartsMetaInfo = async (values: TerminalApiGetChartsMetaInfoRequest) =>
  await (
    await terminalService().getChartsMetaInfo(values)
  ).data;

export const addLastIndicator = async (values: TerminalApiAddLastIndicatorRequest) =>
  await (
    await terminalService().addLastIndicator(values)
  ).data;

export const getLastIndicator = async (values: TerminalApiGetLastIndicatorRequest) =>
  await (
    await terminalService().getLastIndicator(values)
  ).data;

export const getTradingCentralWidgetCredentials = async () =>
  await (
    await terminalService().getTradingCentralWidgetCredentials()
  ).data;
