import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

const rootStyles = cva("group inline-flex items-center bg-bg-deep", {
  variants: {
    size: {
      sm: "min-h-[50px] rounded-[24px] px-0.5",
      md: "min-h-[50px] rounded-[24px] px-0.5 lg:min-h-[72px] lg:rounded-[40px] lg:px-1",
    },
    grow: {
      true: "flex *:flex-1",
    },
  },
});

type Props<T extends string> = Omit<
  ComponentPropsWithoutRef<typeof ToggleGroup.Root>,
  "defaultValue" | "value" | "onValueChange" | "type"
> &
  VariantProps<typeof rootStyles> & {
    defaultValue?: T;
    value?: T;
    onValueChange?: (value: T) => void;
  };

const ButtonGroup = <T extends string>({
  orientation = "horizontal",
  className,
  onValueChange,
  grow = true,
  size = "md",
  ...props
}: Props<T>) => (
  <ToggleGroup.Root
    className={cn(rootStyles({ grow, size }), className)}
    orientation={orientation}
    type="single"
    data-size={size}
    onValueChange={
      onValueChange
        ? value => {
            if (value) onValueChange(value as T);
          }
        : undefined
    }
    {...props}
  />
);

const itemStyles = cva([
  "flex items-center justify-center font-gilroy font-bold leading-[1.2] text-text-secondary outline-none transition-colors hover:text-text data-[state=on]:bg-bg data-[state=on]:text-text",
  "group-data-[size=sm]:h-[46px] group-data-[size=sm]:rounded-[24px] group-data-[size=sm]:px-6 group-data-[size=sm]:text-[14px]",
  "group-data-[size=md]:h-[46px] group-data-[size=md]:rounded-[24px] group-data-[size=md]:px-6 group-data-[size=md]:text-[14px] group-data-[size=md]:lg:h-[64px] group-data-[size=md]:lg:rounded-[40px] group-data-[size=md]:lg:px-[40px] group-data-[size=md]:lg:text-[18px]",
])();

const Item = forwardRef<ElementRef<typeof ToggleGroup.Item>, ComponentPropsWithoutRef<typeof ToggleGroup.Item>>(
  ({ className, ...props }, ref) => {
    return <ToggleGroup.Item className={cn(itemStyles, className)} {...props} ref={ref} />;
  },
);

const Component = Object.assign(ButtonGroup, {
  Item,
});

export { Component as ButtonGroup };
