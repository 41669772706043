import { cva } from "class-variance-authority";
import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { Tabs } from "@/shared/ui";

import { useCurrentSymbolContext } from "../../contexts/current-symbol-context";
import { InsightsContainer } from "../../insights/container";
import { PlaceOrderContainer } from "../container";
import { TerminalPlaceOrderProvider } from "../context";
import { TradingUnavailableBlock } from "../trading-unavailable/block";

const tabContentStyles = cva("overflow-auto pt-4 scrollbar-hide")();

enum PlaceOrderTab {
  TRADE = "trade",
  INSIGHTS = "insights",
}

const PlaceOrderTabs: FC = () => {
  const { t } = useTranslation();

  const { isTradingAvailable } = useCurrentSymbolContext();

  return (
    <Tabs defaultValue={PlaceOrderTab.TRADE} className="flex grow flex-col overflow-auto bg-surface-canvas pt-2">
      <Tabs.List grow className="mx-4">
        <Tabs.Trigger value={PlaceOrderTab.TRADE}>{t("terminal.place-order.tabs.trade")}</Tabs.Trigger>
        <Tabs.Trigger value={PlaceOrderTab.INSIGHTS}>{t("terminal.place-order.tabs.insights")}</Tabs.Trigger>
      </Tabs.List>
      {isTradingAvailable.available ? (
        <TerminalPlaceOrderProvider>
          <Tabs.Content className={tabContentStyles} value={PlaceOrderTab.TRADE}>
            <PlaceOrderContainer />
          </Tabs.Content>
        </TerminalPlaceOrderProvider>
      ) : (
        <Tabs.Content className={tabContentStyles} value={PlaceOrderTab.TRADE}>
          <TradingUnavailableBlock />
        </Tabs.Content>
      )}
      <Tabs.Content className={tabContentStyles} value={PlaceOrderTab.INSIGHTS}>
        <div className="px-4 pb-4">
          <InsightsContainer />
        </div>
      </Tabs.Content>
    </Tabs>
  );
};

export { PlaceOrderTabs };
