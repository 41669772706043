import { FC, FocusEventHandler, ReactNode, useId, useState } from "react";
import DatePicker, { DatePickerProps } from "react-date-picker";
import { useFormContext } from "react-hook-form";

import { Box } from "@/components/box";
import { CloseIcon, DatePickerIcon } from "@/components/icons";
import { styled } from "@/styles";
import { convertDateToISOString } from "@/utils/dates";

import { Error, Label } from "../styles";

const DateWrapper = styled("div", {
  abbr: {
    textDecoration: "none",
  },

  // styles for input
  ".react-date-picker": {
    width: "100%",
    input: {
      "&:focus-visible": { outline: "none" },
      "&::placeholder": {
        color: "$extraLightGray",
      },
    },

    "&__inputGroup__divider": {
      color: "$black",
    },
  },

  ".react-date-picker--is-empty": {
    "&::placeholder": {
      color: "$extraLightGray",
    },

    ".react-date-picker__inputGroup__divider": {
      color: "$extraLightGray",
    },
  },

  ".react-date-picker__wrapper": {
    transition: "all 0.15s ease",
    width: "100%",

    background: "$white",
    border: "1px solid",
    borderColor: "$gray",

    p: "15px 24px",

    fontFamily: "$roboto",
    fontWeight: "$normal",
    fontSize: "14px",
    borderRadius: "8px",
    color: "$black",

    "@bp3": {
      p: "20px 24px",
      borderRadius: "16px",
      fontSize: "16px",
    },

    "&:hover": { borderColor: "$black" },
  },
  ".react-date-picker__button": {
    py: "0",
    "svg path": { transition: "stroke 0.15s ease", fill: "$darkGray" },
    "&:enabled:focus": { "svg path": { fill: "$black" } },
    "&:enabled:hover": { "svg path": { fill: "$black" } },
    "&:focus-visible": { outline: "none" },
  },
  ".react-date-picker .react-date-picker__calendar": {
    maxWidth: "100%",
  },

  ".react-date-picker__calendar-button": {
    paddingInlineEnd: "0",
  },
  ".react-date-picker__inputGroup__input:invalid": {
    backgroundColor: "transparent",
  },

  // styles for calendar
  ".react-calendar": {
    background: "$white",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "16px",
    border: "none",
    p: "24px",

    ".react-calendar__navigation": {
      m: "0",
      height: "50px",
    },

    ".react-calendar__tile, .react-calendar__navigation__arrow, .react-calendar__navigation__label": {
      color: "$black",

      fontWeight: "$bolder",
      fontSize: "18px",
      lineHeight: "$1",

      "&:enabled:hover, &:enabled:focus": {
        color: "$black",
        backgroundColor: "$gray",
      },
      "&:disabled": { backgroundColor: "transparent", opacity: "0.4" },
    },
    ".react-calendar__month-view__days__day--neighboringMonth": {
      opacity: "0.4",
    },
    ".react-calendar__navigation button[disabled]": {
      backgroundColor: "transparent",
      opacity: "0.4",
    },
    ".react-calendar__tile--now": {
      backgroundColor: "transparent",
    },
    ".react-calendar__tile--active, .react-calendar__tile--hasActive": {
      background: "$gray",
    },
  },
});

export type DateInputExternalProps = Omit<DatePickerProps, "onChange" | "value" | "minDate" | "maxDate"> & {
  value: string | null | undefined;
  onChange: (value: string | null | undefined) => void;
  onBlur?: FocusEventHandler;
  label?: ReactNode;
  minDate?: string;
  maxDate?: string;
  showIcon?: boolean;
};

type Props = DateInputExternalProps;

export const DateInput: FC<Props> = ({
  value,
  onChange,
  onBlur,
  maxDate,
  name,
  minDate,
  label,
  showIcon = true,
  ...restProps
}) => {
  const [innerValue, innerOnChange] = useState<Date | null | undefined>(
    value ? (convertDateToISOString(value, true) as Date) : undefined,
  );
  const id = useId();

  const { getFieldState, formState } = useFormContext();
  const { isSubmitted, isDirty } = formState;

  const { isTouched, error } = getFieldState(name!, formState);
  const showError = (isTouched || isSubmitted || isDirty) && !!error;

  const clearIcon = value ? <CloseIcon /> : <></>;

  return (
    <DateWrapper>
      {label && <Label label={label} name={id} />}
      <Box
        onBlur={onBlur}
        css={{
          // TODO: move out of component scope
          borderRadius: "16px",
          ".react-date-picker__wrapper": {
            border: showError ? "solid 1px $negativeDefault" : "none",
          },
        }}
      >
        <DatePicker
          {...restProps}
          onChange={(value: Date) => {
            innerOnChange(value);
            onChange(convertDateToISOString(value) as string);
          }}
          value={innerValue}
          locale="en"
          format="dd-MM-y"
          dayPlaceholder="DD"
          monthPlaceholder="MM"
          yearPlaceholder="YYYY"
          disableCalendar
          calendarIcon={showIcon ? <DatePickerIcon /> : <></>}
          clearIcon={clearIcon}
          name={id}
          className={!value ? "react-date-picker--is-empty" : ""}
          minDate={minDate ? (convertDateToISOString(minDate, true) as Date) : undefined}
          maxDate={maxDate ? (convertDateToISOString(maxDate, true) as Date) : undefined}
        />
      </Box>

      {showError && <Error errorMessage={error.message} />}
    </DateWrapper>
  );
};
