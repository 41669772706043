import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { DialogButtons } from "@/components/dialog";
import { Stack } from "@/components/stack";
import { Text } from "@/components/text";
import { TradingAccount } from "@/services/openapi";

import { PlatformCopyButton } from "./platform-copy-button";

type Props = {
  account: TradingAccount;
  children?: ReactNode;
};

export const AccountPlatformInfo: FC<Props> = ({ children, account }) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack gap="4">
        <Text family="roboto" color="dimmed" css={{ lineHeight: 1.5 }}>
          {t("accounts.dialogs.trade-button.credentials")}
        </Text>
        <PlatformCopyButton title={t("common.server")} value={account.serverAddress!} />
        <PlatformCopyButton title={t("common.login")} value={account.login!.toString()} />
        <div>
          <Text css={{ lineHeight: 1.2, mb: 12 }} weight="bold" size="3">
            {t("common.password")}
          </Text>
          <Text color="dimmed" family="roboto" lineHeight="3">
            {t("accounts.metatrader.password-sent")}
          </Text>
        </div>
      </Stack>
      <DialogButtons>{children}</DialogButtons>
    </>
  );
};
