import { cabinetRoutes } from "@/routes/cabinet.routes";
import { useAccountQuery } from "@/state/server/accounts";
import { useBonusesQuery } from "@/state/server/bonuses";
import {
  useSymbolsLastPricesQuery,
  useTerminalSymbolsFavoritesQuery,
  useTerminalSymbolsQuery,
  useTerminalTokenQuery,
} from "@/state/server/terminal";

import { useTerminalNavigate } from "./helpers";

const useTerminalHandle = ({
  accountIdUrl,
  isMobileAppMode,
}: {
  accountIdUrl: string | null;
  isMobileAppMode: boolean;
}) => {
  const navigate = useTerminalNavigate(isMobileAppMode);

  // account
  const { data: account } = useAccountQuery(accountIdUrl!, {
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: () => {
      navigate(cabinetRoutes.dashboard, { replace: true });
    },
  });
  const { data: socketToken } = useTerminalTokenQuery(accountIdUrl!, {
    cacheTime: 0,
    onError: () => {
      navigate(cabinetRoutes.dashboard, { replace: true });
    },
  });

  // symbols
  const { data: symbols } = useTerminalSymbolsQuery(account?.serverId!, {
    enabled: !!account,
  });
  const { data: lastPrices } = useSymbolsLastPricesQuery(
    { tradingServerId: account?.serverId! },
    { cacheTime: 0, enabled: !!account },
  );
  const { data: favorites } = useTerminalSymbolsFavoritesQuery(accountIdUrl!, {
    cacheTime: 0,
  });

  // other
  const { data: bonuses } = useBonusesQuery();

  const showLoader = !socketToken || !account || !lastPrices || !symbols || !favorites || !bonuses;

  return {
    socketToken: socketToken!,
    account: account!,
    lastPrices: lastPrices!,
    symbols: symbols!,
    favorites: favorites!,
    bonuses: bonuses!,
    showLoader,
  };
};

export { useTerminalHandle };
