import { TradingServerUiPlatform } from "@/services/openapi";

const platformMappings = {
  [TradingServerUiPlatform.Doto]: "Doto",
  [TradingServerUiPlatform.MetaTrader5]: "MT5",
  [TradingServerUiPlatform.MetaTrader4]: "MT4",
};
const formatAccountPlatform = (platform: TradingServerUiPlatform) => platformMappings[platform];

export { formatAccountPlatform };
