import Big from "big.js";
import { FC } from "react";
import { Trans } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { HookForm, SubmitButton, useControlledField, useHookForm } from "@/app/form";
import { TerminalPopover } from "@/features/terminal/components/popover";
import { formatInputNumberValue, getInputNumberValue } from "@/features/terminal/helpers/formatting";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalDealType } from "@/services/openapi";
import { StepperInput } from "@/shared/ui";

import { ModifyOrderSymbol } from "../order-symbol";
import { ModifyProfitAndLoss } from "../profit-and-loss";

enum Fields {
  VOLUME = "volume",
}

type FormValues = {
  [Fields.VOLUME]: string;
};

type Props = {
  onSubmit: (values: FormValues) => Promise<unknown>;
  volume: number;
  volumeDecimalScale: number;
  priceDecimalScale: number;
  volumeStep: number;
  volumeMin: number;
  currency: string;
  currentPrice: number;
  openPrice: number;
  contractSize: number;
  orderType: TerminalDealType;
  baseCurrency: string;
  quoteCurrency: string;
  symbol: string;
  swap: number;
  currencyDecimalScale: number;
  isMobile?: boolean;
};

export const PartialCloseForm: FC<Props> = ({
  onSubmit,
  volume: volumeMax,
  volumeDecimalScale,
  priceDecimalScale,
  volumeMin,
  volumeStep,
  currency,
  orderType,
  currentPrice,
  openPrice,
  contractSize,
  baseCurrency,
  quoteCurrency,
  symbol,
  swap,
  isMobile,
  currencyDecimalScale,
}) => {
  const { t } = useTranslation();

  const form = useHookForm<FormValues>({
    defaultValues: {
      [Fields.VOLUME]: formatInputNumberValue(volumeMax, volumeDecimalScale),
    },
  });
  const { watch, formState, control } = form;
  const { isValid } = formState;

  const { volume: v } = watch();
  const volume = getInputNumberValue(v);

  const [field, { invalid, pending }] = useControlledField<FormValues>({
    name: Fields.VOLUME,
    control,
    rules: { min: volumeMin, max: volumeMax, required: true },
  });

  return (
    <HookForm form={form} onSubmit={onSubmit} className="flex grow flex-col">
      <ModifyOrderSymbol symbol={symbol} isMobile={isMobile} />
      <StepperInput
        size={isMobile ? "sm" : "md"}
        autoFocus
        decimalScale={volumeDecimalScale}
        pending={pending}
        invalid={invalid}
        placeholder={t("terminal.not-set")!}
        minusDisabled={(volume || 0) <= volumeMin}
        plusDisabled={(volume || 0) >= volumeMax}
        onIncrement={() => {
          if (!volume) {
            field.onChange(formatInputNumberValue(volumeMin, volumeDecimalScale));
            return;
          }
          field.onChange(formatInputNumberValue(new Big(volume).add(volumeStep).toNumber(), volumeDecimalScale));
        }}
        onDecrement={() => {
          if ((volume || 0) > volumeMax) {
            field.onChange(formatInputNumberValue(volumeMax, volumeDecimalScale));
            return;
          }
          field.onChange(formatInputNumberValue(new Big(volume!).minus(volumeStep).toNumber(), volumeDecimalScale));
        }}
        descriptor={
          volumeMin !== volumeMax ? (
            <Trans
              i18nKey="terminal.order-settings.from-to-volume"
              components={{
                fromButton: (
                  <StepperInput.DescriptorButton
                    onClick={() => field.onChange(formatInputNumberValue(volumeMin, volumeDecimalScale))}
                  />
                ),
                fromValue: <NumberFormat value={volumeStep} decimalScale={volumeDecimalScale} />,
                toButton: (
                  <StepperInput.DescriptorButton
                    onClick={() => field.onChange(formatInputNumberValue(volumeMax, volumeDecimalScale))}
                  />
                ),
                toValue: <NumberFormat value={volumeMax} decimalScale={volumeDecimalScale} />,
              }}
            />
          ) : null
        }
        {...field}
      />
      <TerminalPopover.List className="mt-6">
        {isMobile && (
          <>
            <TerminalPopover.ListItem
              value={<NumberFormat value={openPrice} decimalScale={priceDecimalScale} />}
              label={`${t("terminal.open-price")}:`}
            />
            <TerminalPopover.ListItem
              value={<NumberFormat value={currentPrice} decimalScale={priceDecimalScale} />}
              label={`${t("terminal.current-price")}:`}
            />
            <TerminalPopover.ListItem
              value={
                isValid && !!volume ? (
                  <NumberFormat
                    value={new Big(swap).div(volumeMax).mul(volume).round(volumeDecimalScale).toNumber()}
                    decimalScale={volumeDecimalScale}
                  />
                ) : (
                  "—"
                )
              }
              label={`${t("terminal.swap")}, ${currency}:`}
            />
          </>
        )}
        <TerminalPopover.ListItem
          value={
            isValid && !!volume ? (
              <ModifyProfitAndLoss
                accountCurrency={currency}
                baseCurrency={baseCurrency}
                quoteCurrency={quoteCurrency}
                contractSize={contractSize}
                currentPrice={currentPrice}
                openPrice={openPrice}
                orderType={orderType}
                volume={volume}
                currencyDecimalScale={currencyDecimalScale}
              />
            ) : (
              "—"
            )
          }
          label={t("terminal.pnl-currency-full", { currency })}
        />
      </TerminalPopover.List>
      <TerminalPopover.Buttons isMobile={isMobile}>
        <SubmitButton>{t("button.confirm")}</SubmitButton>
      </TerminalPopover.Buttons>
    </HookForm>
  );
};
