import { FC, useCallback } from "react";

import { FundsTransferType, TradingAccount } from "@/services/openapi";
import { useBonusesQuery } from "@/state/server/bonuses";
import { useTransferMutation } from "@/state/server/funds";

import { ITransferFormValues, TransferForm } from "./transfer.form";

type Props = {
  accounts: TradingAccount[];
};

export const TransferContainer: FC<Props> = ({ accounts }) => {
  const { mutateAsync: transfer } = useTransferMutation({ successMessage: "transfer-confirmed" });
  const { data: bonusData } = useBonusesQuery();

  const handleSubmit = useCallback(
    (values: ITransferFormValues) => {
      return transfer({
        fundsTransferRequest: {
          ...values,
          sourceType: FundsTransferType.TradingAccount,
          destinationType: FundsTransferType.TradingAccount,
        },
      });
    },
    [transfer],
  );

  if (!bonusData?.items) return null;

  return <TransferForm bonus={bonusData.items} onSubmit={handleSubmit} accounts={accounts} />;
};
