import { LiveChatWidget as LiveChatWidgetDefault } from "@livechat/widget-react";
import { useStore } from "@nanostores/react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { $liveChatState, setLiveChatVisibility } from "@/app/libs/liveChat/store";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { terminalRoutes } from "@/routes/terminal.routes";
import { usePlatformData } from "@/state/server/platform/platform.hooks";

export const LiveChatWidget = () => {
  const { i18n } = useTranslation();
  const { liveChatInfo } = usePlatformData();
  const visibility = useStore($liveChatState);
  const location = useLocation();

  const group = useMemo(() => {
    if (liveChatInfo && liveChatInfo.groupsIds) {
      const currentLiveChatInfo = liveChatInfo.groupsIds.find(({ locale }) => locale === i18n.resolvedLanguage);

      if (currentLiveChatInfo) {
        return currentLiveChatInfo.id!;
      }
    }
  }, [i18n.resolvedLanguage, liveChatInfo]);

  useEffect(() => {
    if (
      [
        cabinetRoutes.mt4Terminal,
        cabinetRoutes.mt5Terminal,
        terminalRoutes.terminal,
        terminalRoutes.terminalMobile,
        terminalRoutes.terminalSymbolMobile,
        terminalRoutes.terminalPositionsMobile,
        onboardingRoutes.onboarding,
      ].includes(location.pathname)
    ) {
      setLiveChatVisibility("hidden");
    }
  }, [location.pathname]);

  return (
    <LiveChatWidgetDefault
      license={import.meta.env.VITE_LIVE_CHAT_LICENSE_NUMBER}
      visibility={visibility}
      group={group}
    />
  );
};
