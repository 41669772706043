import { type FC, type ReactNode } from "react";

import { PositiveNumberFormat } from "@/app/components";
import { AccountSettingsButton } from "@/features/dashboard/components/account-settings/account-settings-button";
import { TradingAccount } from "@/services/openapi";
import { cn } from "@/shared/styles";
import { Skeleton } from "@/shared/ui";
import { useIsReadOnly } from "@/state/server/profile/profile.hooks";

const Card: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="flex flex-col gap-4 rounded-[24px] border bg-bg p-6">{children}</div>;
};

const AccentText: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="font-gilroy text-[18px] font-semibold leading-[1.2] text-text">{children}</div>;
};

const Footer: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="flex flex-col gap-2">{children}</div>;
};

const Badges: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="flex flex-wrap gap-2">{children}</div>;
};

const DataList: FC<{ children: ReactNode }> = ({ children }) => {
  return <ul className="flex flex-col gap-1">{children}</ul>;
};

const DataListItem: FC<{ label: ReactNode; value: ReactNode; valueClassName?: string }> = ({
  label,
  value,
  valueClassName,
}) => {
  return (
    <li className="flex items-center justify-between">
      <div className="font-roboto text-[14px] leading-[1.5] text-text">{label}</div>
      <div className={cn("font-roboto text-[14px] leading-[1.5] text-text", valueClassName)}>{value}</div>
    </li>
  );
};

const CardSkeleton: FC<{ rows: number }> = ({ rows }) => {
  return (
    <Card>
      <div className="flex items-center justify-between">
        <div className="flex grow flex-col gap-2">
          <Skeleton className="h-4 w-[20%]" />
          <Skeleton className="h-6 w-[50%]" />
        </div>
        <Skeleton className="size-10 rounded-full" />
      </div>
      <Badges>
        <Skeleton className="aspect-video h-8 rounded-[8px]" />
        <Skeleton className="aspect-video h-8 rounded-[8px]" />
      </Badges>
      {rows > 0 && (
        <div className="my-2 grid gap-4">
          {[...Array(rows)].map((_, i) => (
            <li key={i} className="grid grid-cols-[4fr,3fr] gap-8">
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-full" />
            </li>
          ))}
        </div>
      )}
      <Footer>
        <Skeleton className="h-10 w-full rounded-[32px]" />
      </Footer>
    </Card>
  );
};

const Header: FC<{ account: TradingAccount; showSettingsButton?: boolean }> = ({
  account,
  showSettingsButton = true,
}) => {
  const { equity, currency, digits, name } = account;

  const isReadOnly = useIsReadOnly();

  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex flex-col gap-1">
        <h4 className="line-clamp-1 break-all font-roboto text-[14px] leading-[1.5] text-text" title={name!}>
          {name}
        </h4>
        <AccentText>
          <PositiveNumberFormat value={equity} decimalScale={digits} currency={currency!} />
        </AccentText>
      </div>
      {!isReadOnly && showSettingsButton && <AccountSettingsButton account={account} />}
    </div>
  );
};

const Component = Object.assign(Card, {
  DataList,
  DataListItem,
  Header,
  Footer,
  Badges,
  AccentText,
  Skeleton: CardSkeleton,
});

export { Component as AccountCard };
