import { VisibleTimeRange } from "public/charting_library/charting_library";
import React, { createContext, memo, useContext, useEffect, useMemo, useState } from "react";

import { useTerminalLastIndicatorQuery } from "@/state/server/terminal";

type ChartInfo = {
  data: object | null;
  range: VisibleTimeRange | null;
};

type ContextProps = {
  chartInfo: ChartInfo;
  lastInterval: string | undefined;
  chartDataIsLoaded: boolean;
  setChartInfo: SetState<ChartInfo>;
};

const Context = createContext<ContextProps | undefined>(undefined);

const Provider = memo(({ children, accountId }: { children: React.ReactNode; accountId: string }) => {
  const [chartInfo, setChartInfo] = useState<ContextProps["chartInfo"]>({
    data: null,
    range: null,
  });

  const { data: lastChartData } = useTerminalLastIndicatorQuery(accountId);

  const chartDataIsLoaded = !!lastChartData;

  useEffect(() => {
    if (chartDataIsLoaded) {
      setChartInfo({
        range: chartInfo.range,
        data: lastChartData.indicator ? JSON.parse(lastChartData.indicator.chartContent!) : null,
      });
    }
  }, [chartDataIsLoaded]);

  const value: ContextProps = useMemo(
    () => ({
      chartInfo,
      setChartInfo,
      lastInterval: lastChartData?.indicator?.interval!,
      chartDataIsLoaded,
    }),
    [chartInfo, lastChartData, chartDataIsLoaded],
  );
  return <Context.Provider value={value}>{children}</Context.Provider>;
});

Provider.displayName = "TerminalChartContextProvider";

const useChartContext = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useChartContext must be used within a TerminalChartContextProvider");
  }

  return context;
};

export { Provider as TerminalChartContextProvider, useChartContext };
