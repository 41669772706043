import dayjs from "dayjs";
import { FC } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import { AddressInfo, ProfileInfo } from "@/services/openapi";
import { styled } from "@/styles";

import { PersonalEmail, PersonalLabel, PersonalValue } from "./styles";

const Container = styled("ul", {
  display: "flex",
  flexWrap: "wrap",
  gap: "10px 30px",

  "@bp3": {
    gap: "24px 75px",
  },
});

type Props = {
  email: string;
  isEmailConfirmed: boolean;
  profile: ProfileInfo;
  address: AddressInfo;
};

export const ProfileFilledContent: FC<Props> = ({ email, address, isEmailConfirmed, profile }) => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <li>
          <PersonalEmail email={email} isEmailConfirmed={isEmailConfirmed} />
        </li>
        <li>
          <PersonalLabel>{t("profile-settings.personal.name")}</PersonalLabel>
          <PersonalValue>{profile.firstName}</PersonalValue>
        </li>
        <li>
          <PersonalLabel>{t("profile-settings.personal.surname")}</PersonalLabel>
          <PersonalValue>{profile.lastName}</PersonalValue>
        </li>
      </Container>
      <Container css={{ mt: "24px" }}>
        <li>
          <PersonalLabel>{t("profile-settings.personal.date-of-birth")}</PersonalLabel>
          <PersonalValue>{dayjs(profile.dateOfBirth).format("DD.MM.YYYY")}</PersonalValue>
        </li>
        <li>
          <PersonalLabel>{t("profile-settings.personal.country")}</PersonalLabel>
          <PersonalValue>{address.country}</PersonalValue>
        </li>
      </Container>
    </>
  );
};
