import { cva } from "class-variance-authority";
import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";

import { PlaceOrderDirection, usePlaceOrderContext } from "../context";

const buttonStyles = cva(
  "group flex h-14 flex-1 items-center rounded-e-[16px] border border-control-border bg-control-bg px-4 outline-none transition-colors first:rounded-e-none first:rounded-s-[16px] hover:bg-bg-selected focus-visible:bg-bg-selected",
  {
    variants: {
      direction: {
        [PlaceOrderDirection.UP]: "",
        [PlaceOrderDirection.DOWN]: "",
      },
      active: {
        true: "",
      },
    },
    compoundVariants: [
      {
        direction: PlaceOrderDirection.UP,
        active: true,
        className: "border-positive-bg bg-positive-bg hover:bg-positive-bg focus-visible:bg-positive-bg",
      },
      {
        direction: PlaceOrderDirection.DOWN,
        active: true,
        className: "border-negative-bg bg-negative-bg hover:bg-negative-bg focus-visible:bg-negative-bg",
      },
    ],
  },
);

const textStyles = cva("text-contrast-secondary transition-colors", {
  variants: {
    size: {
      sm: "font-roboto text-[12px] leading-normal",
      md: "font-gilroy text-[14px] font-bold leading-[1.2]",
    },
    direction: {
      [PlaceOrderDirection.UP]: "",
      [PlaceOrderDirection.DOWN]: "",
    },
    active: {
      true: "",
    },
  },
  compoundVariants: [
    {
      direction: PlaceOrderDirection.UP,
      active: true,
      className: "text-positive-over",
    },
    {
      direction: PlaceOrderDirection.DOWN,
      active: true,
      className: "text-negative-over",
    },
  ],
});

type Props = {
  ask: ReturnType<typeof usePlaceOrderContext>["ask"];
  bid: ReturnType<typeof usePlaceOrderContext>["bid"];
  direction: ReturnType<typeof usePlaceOrderContext>["direction"];
  changeDirection: ReturnType<typeof usePlaceOrderContext>["changeDirection"];
  priceDecimalScale: number;
  active: boolean;
};

const _Button: FC<Props> = ({ direction, ask, bid, priceDecimalScale, active, changeDirection }) => {
  const { t } = useTranslation();

  const isUp = direction === PlaceOrderDirection.UP;

  return (
    <button type="button" onClick={() => changeDirection(direction)} className={buttonStyles({ direction, active })}>
      <div className="flex grow flex-col text-end group-first:text-start">
        <div className={textStyles({ active, direction, size: "md" })}>
          {isUp ? t("terminal.up") : t("terminal.down")}
        </div>
        <div className={textStyles({ active, direction, size: "sm" })}>
          <NumberFormat value={isUp ? ask : bid} decimalScale={priceDecimalScale} />
        </div>
      </div>
    </button>
  );
};

export { _Button };
