import { FC } from "react";
import { useNavigate } from "react-router-dom";

import image from "@/assets/images/DialogImage2.svg";
import { Button } from "@/components/button";
import { DialogClose } from "@/components/dialog";
import { Flex } from "@/components/flex";
import { TextInput } from "@/components/form";
import { InfoDialog } from "@/components/info-dialog";
import { Text } from "@/components/text";
import { useTranslation } from "@/hooks/translator.hook";
import { useResendConfirmationLinkMutation } from "@/state/server/auth";

type Props = {
  email: string;
  redirectPath?: string;
};

export const EmailConfirmDialogContent: FC<Props> = ({ email, redirectPath }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isSuccess, isLoading, mutate } = useResendConfirmationLinkMutation();

  const onResend = () => {
    mutate(undefined);
    if (redirectPath) navigate(redirectPath, { replace: true });
  };

  const onVerifyLater = () => {
    if (redirectPath) navigate(redirectPath, { replace: true });
  };

  return (
    <InfoDialog
      imageSrc={image}
      title={isSuccess ? t("onboarding.email-confirm.success-title") : t("onboarding.email-confirm.title")}
      description={
        isSuccess ? t("onboarding.email-confirm.success-description") : t("onboarding.email-confirm.description")
      }
    >
      {!isSuccess && (
        <>
          <Text as="p" family="roboto" color="dimmed" align="center" css={{ mb: "20px" }}>
            {t("onboarding.email-confirm.text")}
          </Text>
          <TextInput value={email} disabled />
          <Flex css={{ justifyContent: "center", mt: "20px" }}>
            <Button disabled={isLoading} onClick={onResend}>
              {t("button.resend-email")}
            </Button>
          </Flex>
          <Flex css={{ justifyContent: "center" }}>
            <DialogClose asChild>
              <Button variant="text" size="small" onClick={onVerifyLater}>
                {t("button.verify-later")}
              </Button>
            </DialogClose>
          </Flex>
        </>
      )}
    </InfoDialog>
  );
};
