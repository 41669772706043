import { type FC } from "react";

import { useCurrentSymbolContext } from "../../contexts/current-symbol-context";
import { PlaceOrderTabs } from "./tabs";

const DesktopPlaceOrderBlock: FC = () => {
  const { currentSymbol } = useCurrentSymbolContext();

  return <PlaceOrderTabs key={currentSymbol} />;
};

export { DesktopPlaceOrderBlock };
