import { DirectionProvider } from "@radix-ui/react-direction";
import { Provider as TooltipProvider } from "@radix-ui/react-tooltip";
import { FC, ReactNode, Suspense, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactQueryDevtools } from "react-query/devtools";

import { setLocaleSentry } from "@/app/libs/sentry";
import { AuthContextProvider } from "@/contexts/auth.context";
import { ThemeContextProvider } from "@/contexts/theme.context";
import { IconChevronDown, IconChevronUp, IconCross, IconTick } from "@/domains/icons";
import { IconProvider } from "@/domains/icons/icon.provider";
import { Toaster } from "@/shared/ui";
import { ReactQueryProvider } from "@/state/server";

import { InitialDataProvider } from "./initial-data.provider";

interface Props {
  children: ReactNode;
}

export const AppProvider: FC<Props> = ({ children }) => {
  const { i18n } = useTranslation();

  const dir = i18n.dir();

  useLayoutEffect(() => {
    document.documentElement.dir = dir;
    document.documentElement.lang = i18n.resolvedLanguage;
    setLocaleSentry(i18n.resolvedLanguage);
  }, [i18n.resolvedLanguage]);

  return (
    <DirectionProvider dir={dir}>
      <ThemeContextProvider>
        <IconProvider
          icons={{
            IconCross: () => <IconCross />,
            IconChevronDown: () => <IconChevronDown />,
            IconChevronUp: () => <IconChevronUp />,
            IconTick: () => <IconTick />,
          }}
        >
          {/* <Suspense fallback={<GlobalLoader />}> */}
          <Suspense fallback={<></>}>
            <TooltipProvider>
              <ReactQueryProvider>
                <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                <InitialDataProvider>
                  <AuthContextProvider>{children}</AuthContextProvider>
                </InitialDataProvider>
                <Toaster />
              </ReactQueryProvider>
            </TooltipProvider>
          </Suspense>
        </IconProvider>
      </ThemeContextProvider>
    </DirectionProvider>
  );
};
