import { cva } from "class-variance-authority";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat, PnlFormat, PositiveNumberFormat } from "@/app/components";
import { getNumberColorClassname } from "@/app/ui/colors";
import { DialogTrigger } from "@/components/dialog";
import { TooltipQuestionIcon } from "@/components/icons";
import { getBonusMainPercent } from "@/entities/bonuses/helpers";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { BonusUserPlatform } from "@/services/openapi";
import { cn } from "@/shared/styles";
import { Tooltip } from "@/shared/ui";

import { useTerminalAccountSummary } from "../account-summary/context";

const listItemStyles = cva(
  "flex items-center justify-between gap-2 border-b border-contrast-quaternary py-3 last:border-b-0",
)();

const textStyles = cva("font-roboto text-[14px] leading-normal text-contrast-primary lg:text-[16px]", {
  variants: {
    dimmed: {
      true: "!text-contrast-secondary",
    },
  },
});

type Props = {
  onClose: () => void;
  showBonus: boolean;
  bonus: BonusUserPlatform | null;
};

const AccountsSelectInfo: FC<Props> = ({ onClose, showBonus, bonus }) => {
  const { t } = useTranslation();

  const [opened, { onOpenChange, close: closeTooltip }] = useDisclosure();

  const { currency, equity, marginLevel, margin, pnl, currencyDecimalScale, marginFree, credit } =
    useTerminalAccountSummary();

  const handleClose = useCallback(() => {
    closeTooltip();
    onClose();
  }, [closeTooltip, onClose]);

  return (
    <ul role="list" className="bg-surface-canvas px-4 py-2">
      <li className={listItemStyles}>
        <div className={textStyles({ dimmed: true })}>{t("terminal.account-select.equity")}</div>
        <div className={textStyles()}>
          <PositiveNumberFormat value={equity} decimalScale={currencyDecimalScale} currency={currency} />
        </div>
      </li>
      <li className={listItemStyles}>
        <div className={textStyles({ dimmed: true })}>{t("terminal.account-select.free-margin")}</div>
        <div className={textStyles()}>
          <PositiveNumberFormat value={marginFree} decimalScale={currencyDecimalScale} currency={currency} />
        </div>
      </li>
      <li className={listItemStyles}>
        <div className={textStyles({ dimmed: true })}>{t("terminal.account-select.margin")}</div>
        <div className={textStyles()}>
          <NumberFormat value={margin} decimalScale={currencyDecimalScale} currency={currency} />
        </div>
      </li>
      {bonus && showBonus && (
        <li className={listItemStyles}>
          <div className="flex items-center gap-2">
            <div className={textStyles({ dimmed: true })}>{t("terminal.account-select.bonus.name")}</div>
            <Tooltip
              open={opened}
              onOpenChange={onOpenChange}
              action={
                <DialogTrigger asChild>
                  <Tooltip.Action onClick={handleClose}>{t("terminal.account-select.bonus.button")}</Tooltip.Action>
                </DialogTrigger>
              }
              content={t("terminal.account-select.bonus.description", { percent: getBonusMainPercent(bonus) })}
            >
              <TooltipQuestionIcon />
            </Tooltip>
          </div>
          <div className={textStyles()}>
            <NumberFormat value={credit} decimalScale={currencyDecimalScale} currency={currency} />
          </div>
        </li>
      )}
      <li className={listItemStyles}>
        <div className={textStyles({ dimmed: true })}>{t("terminal.account-select.pnl")}</div>
        <div className={cn(textStyles(), getNumberColorClassname(pnl))}>
          <PnlFormat value={pnl} decimalScale={currencyDecimalScale} currency={currency} />
        </div>
      </li>
      <li className={listItemStyles}>
        <div className={textStyles({ dimmed: true })}>{t("terminal.account-select.margin-level")}</div>
        <div className={textStyles()}>
          <NumberFormat value={marginLevel} decimalScale={2} suffix="%" />
        </div>
      </li>
    </ul>
  );
};

export { AccountsSelectInfo };
