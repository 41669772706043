import { css } from "@/styles";

export const headerStyles = css({
  display: "none",

  "@bp2": {
    py: "24px",
    px: "$containerXPadding",
    maxWidth: "$appContainer",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export const styledTradingHeader = css({
  margin: "0 auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  p: "8px 8px 8px 16px",

  "@bp2": {
    py: "24px",
    px: "$containerXPadding",
  },
});

export const headerWrapperStyles = css({
  background: "$white",
});

export const tradingHeaderWrapperStyles = css({
  backgroundColor: "$black",
});
